import React, { useContext, useEffect, useRef, useState } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Toast } from "primereact/toast";

import "./style.scss";
import CustomToolbar from "./CustomToolbar";
import NewsLetter from "./Newsletter";
import { getByUrl } from "../../../v3MethodsService";
import axios from "axios";
import { ENDPOINT } from "../../../env";
import { GlobalContext } from "../../../ctx";

moment.locale("ru");
const localizer = momentLocalizer(moment);

const NonActivity = () => {
    const toast = useRef(null);
    const [eventsData, setEventsData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(null);

    const [options, setOptions] = useState([]);
    const [global, setGlobal] = useContext(GlobalContext);
    const token = global && global.auth && global.auth.token ? global.auth.token : "";

    useEffect(() => {
        axios
            .get(`${ENDPOINT}v2/list/clubs`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((response) => {
                if (response && response.data) {
                    const teams = response.data.club?.teams;
                    const teamsWithAllPlayers = teams.map((team) => ({
                        ...team,
                        allPlayers: team.squads
                            .map((squad) => squad.playersData)
                            .flat()
                            .filter((player, index, self) => player && index === self?.findIndex((p) => p?._id === player?._id))
                    }));
                    setOptions({ teams: teamsWithAllPlayers });
                }
            });
    }, [token]);

    useEffect(() => {
        getClubActions();
    }, []);

    const getClubActions = () => {
        getByUrl(`clubactions`).then((resp) => {
            if (resp && resp.data) {
                const newData = resp.data.map((event) => {
                    const [year, month, day] = event.eventDate.split("-").map(Number);

                    const dateObject = new Date(2000 + year, month - 1, day);
                    const [y, m, d] = event.endDate.split("-").map(Number);

                    const endObject = new Date(2000 + y, m - 1, d);
                    return { ...event, start: dateObject, end: dateObject, endDate: endObject };
                });

                setEventsData(generateEventsWithRecurrence(newData));
            }
        });
    };

    const generateEventsWithRecurrence = (eventsData) => {
        const allEvents = [];

        eventsData.forEach((event) => {
            if (event.isRegular || !event.cloned) {
                let currentStart = new Date(event.start);
                let currentEnd = new Date(event.endDate);
                while (currentStart <= currentEnd) {
                    allEvents.push({
                        ...event,
                        start: new Date(currentStart),
                        end: new Date(currentStart),
                        cloned: true,
                    });

                    if (event.regularType === "month") {
                        currentStart = addMonths(currentStart, 1);
                    } else {
                        currentStart = addWeeks(currentStart, regularOptions[event.regularType]);
                    }
                }
            } else {
                allEvents.push(event);
            }
        });

        return allEvents;
    };

    const addWeeks = (date, weeks) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + weeks * 7);
        return newDate;
    };

    const addMonths = (date, months) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + months);
        return newDate;
    };

    const handleSelect = ({ start, end }) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        start.setHours(0, 0, 0, 0);
        if (today <= start) {
            setVisible(true);
            setData({ eventDate: moment(start).format("DD.MM.YYYY") });
        } else {
            toast.current.show({ severity: "error", summary: "Нельзя создать событие в прошлом" });
        }
    };

    const parseDate = (date) => {
        if (date instanceof Date) {
            return date;
        }
        const [year, month, day] = date.split("-").map(Number);
        return new Date(2000 + year, month - 1, day);
    };

    const handleEvent = (event) => {
        const eventDate = parseDate(event.eventDate);
        const endDate = parseDate(event.endDate);
    
        setVisible(true);
        setData({ ...event, eventDate, endDate });
    };

    return (
        <div className="non-activity">
            <Toast ref={toast} position="top-right" />
            <Calendar
                views={["month"]}
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={eventsData}
                style={{ height: "80vh" }}
                onSelectEvent={handleEvent}
                onSelectSlot={handleSelect}
                components={{
                    toolbar: CustomToolbar,
                }}
            />
            <NewsLetter teamsOptions={options} getClubActions={getClubActions} visible={visible} setVisible={setVisible} curdata={data} setcurData={setData} toast={toast} />
        </div>
    );
};

export default NonActivity;
const regularOptions = { week: 1, two_week: 2, month: 4 };
