import React, {useContext, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";

import {deleteByUrl, getByUrl, putByUrl} from "../../../../v3MethodsService";

import LocationProfile from "./Profile";
import LocationDocuments from "./Documents";
import LocationSideBar from "./SideBar";
import LocationMatches from "./Matches";

import {ProgressSpinner} from "primereact/progressspinner";
import {SelectButton} from "primereact/selectbutton";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";

import './style.scss'
import LocationHomeplace from "./Homeplace";

const LocationDetail = ({ deleteLocation, toast }) => {

    const params = useParams()
    const locationId = params && params.id

    const [data, setData] = useState(null);
    const [state, setState] = useState(null);
    const [loader, setLoader] = useState(false);

    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [option, setOption] = useState(0);

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [sideBarData, setSideBarData] = useState([]);
    const [sideBarState, setSideBarState] = useState([]);

    const [docs, setDocs] = useState([]);

    const Specified = selectedOption ? wrap[selectedOption.key] : wrap["profile"];

    useEffect(() => {
        if (locationId !== 'new') {
            getLocation()
        } else {
            setData({...defaultLocation})
        }
    }, [locationId]);

    const getLocation = () => {
        setLoader(true)
        getByUrl(`locations/${locationId}`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setData(resp.data);
                setState(resp.data);
                setDocs(resp.data.docs);
                setLoader(false)
            }
        });
    };

    const updateData = (val, key, subKey, dropdown) => {
        if (subKey) {
            setData({ ...data, [key]: { ...data[key], [subKey]: val } });
        } else if (key) {
            setData({ ...data, [key]: val });
        } else {
            setData(val)
        }
    };

    const handleItem = (e) => {
        if (e.data) {
            let sideData = data.attachments.find((item) => item._id === e.data._id);
            setSideBarData(sideData);
            setSideBarState(sideData);
        } else {
            setSideBarData({});
            setSideBarState({});
        }
        setSidebarVisible(true);
    };

    const updateSideBar = (val, key) => {
        setSideBarData({ ...sideBarData, [key]: val });
    };

    const handleDocs = (toastName) => {
        putByUrl(`locations/${state._id}`, { docs: docs }).then((resp) => {
            if (resp && resp.success) {
                toast.show({ severity: "success", summary: toastName ? toastName : "Данные сохранены" });
                getLocation();
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
        setSidebarVisible(false);
    };

    const deleteHandler = (id) => {
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация по этому документу",
            header: "Удалить документ?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: () => {
                removeAttachment(id);
            },
            reject: () => {},
        });
    };

    const removeAttachment = (id) => {
        deleteByUrl(`remove_attach/${id || sideBarData._id}`).then((resp) => {
            if (resp && resp.success) {
                const newDocs = state.docs.filter((d) => d !== sideBarData._id);
                setDocs(newDocs);
                handleDocs("Документ удален");
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    return loader ? (
        <ProgressSpinner style={{ width: "100px", height: "100px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
    ) : (
        <div className='location-detail leagues-detail'>
            <LocationSideBar
                sideBarState={sideBarState}
                getLocation={getLocation}
                visible={sidebarVisible}
                setVisible={setSidebarVisible}
                data={sideBarData}
                state={state}
                updateData={updateSideBar}
                setData={setSideBarData}
                docs={docs}
                setDocs={setDocs}
                deleteHandler={deleteHandler}
                handleDocs={handleDocs}
            />

            {locationId !== 'new' && (
                <div className="leagues-detail__header">
                    <SelectButton
                        options={options}
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.value)}
                        optionLabel="name"
                        unselectable={false}
                    />

                    {["matches", "documents"].includes(selectedOption?.key) && (
                        <div className="leagues-detail__buttons">
                            <SelectButton
                                options={selectedOption?.key === 'documents' ? archivedOptions : matchesOptions}
                                value={option}
                                onChange={(e) => setOption(e.value)}
                                unselectable={false}
                            />
                            {selectedOption?.key === 'documents' && <Button icon="pi pi-plus" label="" onClick={handleItem} />}
                        </div>
                    )}
                </div>
            )}

            <Specified
                data={data}
                updateData={updateData}
                deleteLocation={deleteLocation}
                option={option}
                handleDoc={handleItem}
                deleteDoc={deleteHandler}
            />
        </div>
    )
}

const options = [
    { name: "Профиль", key: "profile" },
    { name: "Документы", key: "documents" },
    { name: "Домашняя площадка для клубов", key: "homeplace" },
    { name: 'Матчи', key: 'matches' }
];

const archivedOptions = [
    { label: "Активные", value: 0 },
    { label: "Архивные", value: 1 },
];

const matchesOptions = [
    { label: 'Прошедшие', value: 0 },
    { label: 'Ближайшие', value: 1 }
]

const wrap = {
    profile: LocationProfile,
    documents: LocationDocuments,
    homeplace: LocationHomeplace,
    matches: LocationMatches
};

const defaultLocation = {
    name: "",
    format: "",
    address: "",
    capacity: "",
};

export default LocationDetail