import React, { useContext, useEffect, useRef, useState } from "react";

import { useClickOutside } from "../../../../utils";

import Player from "./Player";
import Employee from "./Employee";

import "./style.scss";

const PlayersAndStaffSidebar = ({ state = false, onClose, toast, tab, getUpdatedData }) => {
    const ref = useRef();
    useClickOutside(state, ref, onClose);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    const [openedTabs, setOpenedTabs] = useState(["info"]);

    const Specified = tab === "players" ? Player : Employee;

    useEffect(() => {
        setOpenedTabs(["info"]);
    }, [state]);

    const changeTabHandler = (tab) => {
        if (openedTabs.includes(tab)) {
            setOpenedTabs(openedTabs.filter((t) => t !== tab));
            return;
        }
        setOpenedTabs((prev) => [...prev, tab]);
    };

    const updateData = (val, key) => {
        if (key) {
            setData((prev) => ({ ...prev, [key]: val }));
            return;
        }
        setData(val);
    };

    return (
        !!state && (
            <div className="players-and-staff-sidebar verifyModal">
                <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                    <Specified
                        state={state}
                        data={data}
                        updateData={updateData}
                        loading={loading}
                        sending={sending}
                        updateLoading={setLoading}
                        updateSending={setSending}
                        openedTabs={openedTabs}
                        changeTabHandler={changeTabHandler}
                        toast={toast}
                        getUpdatedData={getUpdatedData}
                        convertBase64={convertBase64}
                        onClose={() => {
                            onClose();
                            setSending(false);
                        }}
                        outsideRef={ref}
                        tab={tab}
                    />
                </div>
            </div>
        )
    );
};

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export default PlayersAndStaffSidebar;
