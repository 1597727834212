import React, {useEffect, useState} from "react";

import service from "../../../../../../../Tournaments/service";
import {diffData} from "../../../../../../../../utils";

import {Button} from "primereact/button";
import Matchday from "../Matchday";

import "./style.scss";

const RoundCalendar = ({ state, current, toast, updateCurrent, isFinished }) => {
    const [data, setData] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null)
    const [saveClick, setSaveClick] = useState(false)

    const isDiff = diffData(data, state)

    useEffect(() => {
        if (current && !data) {
            const stage = {...current}
            const mds = stage.matchdays || [];
            if (mds.length > 0) {
                const teamsCount = stage.teams.length;

                const matchesCount = Math.floor((teamsCount * 10 / 2)/10);

                let mdsCount = teamsCount % 2 === 0 ? (teamsCount - 1) * stage.roundsQty : teamsCount * stage.roundsQty;

                const msDBcount = mds.reduce((acc, cur) => {
                    acc += cur.matches.length
                    return acc
                }, 0)

                let settedMds = [];
                if (mdsCount < mds.filter(md => md._id).length && ((stage.roundsQty >= 2 && mdsCount/(stage.roundsQty - 1) > mds.filter(md => md._id).length/stage.roundsQty) || (stage.roundsQty === 1 && mdsCount * 2 > mds.filter(md => md._id).length))) {
                    settedMds = mds
                } else if (mdsCount === mds.filter(md => md._id).length || (stage.roundsQty >= 2 && mdsCount/stage.roundsQty <= mds.filter(md => md._id).length)) {
                    //console.log('msDBcount === matchesCount * mdsCount', msDBcount, matchesCount * mdsCount);
                    //  (msDBcount === matchesCount * mdsCount || (stage.roundsQty === 2 && msDBcount >= matchesCount * mdsCount/stage.roundsQty))
                    if (msDBcount >= matchesCount * mdsCount * stage.roundsQty) {
                        setType('fromDB')
                        //updateStage({...st, fillType: 'fromDB'})
                        settedMds = mds.slice(0, mdsCount * stage.roundsQty)
                    } else {
                        setType('manual')
                        //updateStage({...st, fillType: 'manual'})
                        settedMds = manualMdsFilled(stage, stage.teams, mds);
                    }
                } else {
                    if (mds.find(md => md._id || md.matches.find(m => !m.home || !m.away))) {
                        setType('manual')
                        settedMds = manualMdsFilled(stage, stage.teams, mds);
                        //updateStage({...st, fillType: 'manual'})
                    } else {
                        //setType('auto')
                        //updateStage({...st, fillType: 'auto'})
                        settedMds= mds;
                    }
                }


                setData({...current, matchdays: settedMds.sort((a,b) => a.name && b.name ? getNumFromName(a.name) - getNumFromName(b.name) : 0)})
            } else {
                setData(current)
            }
        }

    }, [current]);

    useEffect(() => {
        if (data && isDiff) {
            updateCurrent(data)
        }
    }, [isDiff]);

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData(prev => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else {
            setData(prev => ({ ...prev, [key]: val }));
        }
    };

    const checkUnassigned = () => {
        const assigned = data && data.teams ? data.teams.map(t => t._id): []

        return state?.teams
            .filter(t => !assigned.includes(t._id))
            .map(t => t.name)
    }

    const unassigned = checkUnassigned()

    const handleSave = () => {
        const mapd = data?.matchdays?.map(md => {
            const ms = md.matches
                .map(m => ({homeId: m.homeId, awayId: m.awayId, matchId: m._id || null, matchdayId: md._id || null}));
            return {...md, matches: ms}
        })

        if (hasDuplicates(mapd)) {
            toast.show({severity: 'error', summary: '', detail: 'У некоторых туров совпадающие названия'})
            return
        }

        const settedMds = manualMdsFilled(data, data.teams, data?.matchdays);

        setLoading(true)
        setSaveClick(true)
        service.applyCalendarDraftV2(data._id, toast, mapd)
            .then(_ => {
                setLoading(false)
                setIsChanged(false)
                setType(null)
                setSaveClick(false)
                updateData(settedMds, 'matchdays')
            });
    }

    const updateMd = (md, id) => {
        const newMdays = [...data.matchdays]
        newMdays[id] = md
        updateData(newMdays, 'matchdays')
        setIsChanged(true)
    }

    const autoGenerate = async () => {
        setLoading(true)

        const draft = await service.calendarDraftV2({
            stageId: data._id,
            toast: toast,
            reverseCnt: data.roundsQty > 1 ? 1 : 0
        })

        if (draft && draft.matchDays) {
            updateData(draft.matchDays.map((m, k) => ({...m, _id: k})), 'matchdays')
            setType('auto')
            setIsChanged(true)
        }

        setLoading(false)
    }

    const manualGenerate = () => {
        const mdays = generateEmptyRoundMds(data._id, data.teams, '', false, false, data.roundsQty);
        if (mdays) {
            updateData(mdays, 'matchdays')
        }
        setType('manual')
    }

    return data && data.matchdays?.length > 0 ? (
        <div className="calendar-detail">
            {unassigned?.length > 0 && (
                <div className='calendar-item__unassigned'>
                    <i className='pi pi-info-circle'/>
                    Не распределены команды: {unassigned.join(', ')}
                </div>
            )}
            <div className='calendar-item__row'>
                {data.matchdays.map((m, key) => <Matchday id={key} key={key} mday={m} current={data} state={state} updateMd={updateMd} saveClick={saveClick} isFinished={isFinished}/>)}
            </div>
            <div className="clubs-detail__button">
                {isChanged && <Button label="Сохранить" onClick={handleSave} disabled={!isChanged || loading} loading={loading}/>}
                {type === 'auto' && <Button label="Другой вариант" onClick={autoGenerate} disabled={loading} loading={loading} className='p-button-warning'/>}
            </div>
        </div>
    ) : (
        <div className="calendar-item calendar-item_empty">
            <div className='calendar-item__container'>
                Календаря еще нет.<br/>Выберите режим создания
                <div className='calendar-item__container-btn-set'>
                    <Button label='Сгенерировать автоматически' className='calendar-item__container-btn' onClick={autoGenerate} disabled={loading || isFinished} loading={loading}/>
                    <Button label='Ручной режим' className='calendar-item__container-btn calendar-item__container-btn_outlined' onClick={manualGenerate} disabled={isFinished}/>
                </div>
            </div>
        </div>
    );
};

const generateEmptyRoundMds = (stageId, teams, dayName, isNeedReverse=false, isReverseMirror=false, roundsQty) => {
    const mds = [];
    const teamsCount = teams.length;
    const matchesCount = Math.floor((teamsCount * 10 / 2)/10);
    let numberOfRounds = (teamsCount % 2 === 0 ? teamsCount - 1 : teamsCount) * (roundsQty || 1);
    //console.log(matchesCount, group.teams.length);
    for (let i=0; i < numberOfRounds; i++) {
        const md = {
            name: dayName ? dayName.replace(/\d/g,'_').replace('_', `${i+1}`).replace(/_/g, '') : `${i+1} тур`,
            stageId: stageId,
            //groupId: groupData._id,
            type: 'round',
            matches: []
        }
        for (let j=0; j < matchesCount; j++) {
            const newmatch = {
                home: null,
                away: null,
                homeId: null,
                awayId: null,
            }
            md.matches.push(newmatch)
        }
        mds.push(md)
    }

    if (isNeedReverse) {
        const secondHalf = mds.map((item, ind) => {
            return {
                ...item,
                name: !isReverseMirror ? `${2*mds.length - ind} тур` : `${mds.length + ind +1} тур`
            }
        })

        if (isReverseMirror) {
            mds.push(...secondHalf.reverse());
        } else {
            mds.push(...secondHalf);
        }
    }
    return mds
}


const manualMdsFilled = (stage, teams, fromDBMds) => {
    const filledEmpty = generateEmptyRoundMds(stage._id, teams, fromDBMds[0].name, false, false, stage.roundsQty);
    const mapdFilled = [];
    const matchesCount = Math.floor((teams.length * 10 / 2)/10);

    for (let md of filledEmpty) {
        const curMdMs = [];
        const fromDbMd = fromDBMds.find(item => item.name === md.name);
        if (fromDbMd && fromDbMd.matches) {
            if (fromDbMd.matches.length < matchesCount) {
                curMdMs.push(...fromDbMd.matches, ...md.matches.slice(0, matchesCount - fromDbMd.matches.length));
            } else {
                curMdMs.push(...fromDbMd.matches);
            }

            mapdFilled.push({...fromDbMd, matches: curMdMs})
        } else {
            mapdFilled.push(md)
        }
    }
    //console.log('manualMdsFilled', mapdFilled);
    return mapdFilled;
}

const getNumFromName = name => {
    const replaced = name.replace(/\D/g,'');
    if (replaced) {
        return +replaced
    } else {
        return 1
    }
}

const hasDuplicates = (arr) => {
    const nameSet = new Set();
    return arr.some(item => {
        if (nameSet.has(item.name)) {
            return true; // если найдено повторение
        }
        nameSet.add(item.name);
        return false;
    });
};

export default RoundCalendar;
