import React, { useContext, useEffect, useRef, useState } from "react";

import moment from "moment";

import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../v3MethodsService";
import { pluralForm, useClickOutside } from "../../../utils";
import { WorkspaceContext } from "../../../ctx";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Tooltip } from "primereact/tooltip";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import Payment from "./Payment";

import "./style.scss";
import Salary from "./Salary";
import { Dropdown } from "primereact/dropdown";

const Payments = ({ state = false, onClose, onRemove, toast }) => {
    const wctx = useContext(WorkspaceContext);
    const role = wctx && wctx.appSubject && wctx.appSubject.type;
    const ref = useRef();
    const [dataToSend, setDataToSend] = useState([]);
    const [create, setCreate] = useState(false);
    const [select, setSelect] = useState(opt[0]);
    const [options, setOptions] = useState([]);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [bills, setBills] = useState(null);
    const [openedTabs, setOpenedTabs] = useState([]);
    const [salaries, setSalaries] = useState([]);
    const [locations, setLocations] = useState([]);
    useClickOutside(state, ref, () => handleClose());

    useEffect(() => {
        if (state && role !== "club") {
            const fetchSalaries = async () => {
                const res = await getByUrl(`salaries`);
                if (res && res.data) {
                    setSalaries(res.data);
                }
            };
            fetchSalaries();
        }
    }, [state]);

    useEffect(() => {
        const fetchBills = async () => {
            const res = await getByUrl(`bills?status=${select.key}`);
            if (res && res.data) {
                setBills(res.data);
            }
        };

        fetchBills();
    }, [select]);

    useEffect(() => {
        setOptions([]);
        if (create === "payment") {
            setDataToSend({ subjectType: "club" });
            getByUrl("clubs").then((resp) => {
                if (resp && resp.success && resp.data) {
                    setOptions(resp.data);
                }
            });
        }
        if (create === "salary") {
            setDataToSend({ ...dataToSend, type: "salary", typeRate: "matches" });
            getByUrl("employees").then((resp) => {
                if (resp && resp.success && resp.data) {
                    setOptions(resp.data);
                }
            });
            getByUrl("locations").then((resp) => {
                if (resp && resp.success && resp.data) {
                    setLocations(resp.data);
                }
            });
        }
    }, [create]);

    const handleClose = () => {
        setDataToSend([]);
        onClose();
        setCreate(false);
        setOptions([]);
        setOpenedTabs([]);
    };

    const changeTabHandler = (tab) => {
        if (openedTabs.includes(tab)) {
            setOpenedTabs(openedTabs.filter((t) => t !== tab));
            return;
        }
        setOpenedTabs((prev) => [...prev, tab]);
    };

    const updateData = (val, key, subKey) => {
        setDataToSend((prev) => ({
            ...prev,
            [key]: subKey ? { ...prev[key], [subKey]: val } : val,
        }));
    };

    const handleReject = async () => {
        const response = await deleteByUrl(`salaries/${dataToSend._id}`);
        response?.success ? onSuccessfulPost("Запись о выплатах удалена") : onError(response?.message);
    };

    const renderHeader = () => {
        return (
            <div className="side-profile__header-btn-group">
                {create && <Button onClick={handleBack} icon="pi pi-angle-left" className="payments__back" />}
                {create ? (
                    <Button
                        className="side-profile__button-save auth2__button"
                        label={dataToSend._id ? "Сохранить" : "Отправить"}
                        onClick={dataToSend._id ? handleUpdate : create === "salary" ? handlePostSalary : handlePost}
                        disabled={!disabled || disabledBtn}
                    />
                ) : null}
                {create === "salary" && dataToSend._id && <Button onClick={handleReject} className="side-profile__button-save auth2__button_outlined" label="Удалить" />}
            </div>
        );
    };

    const handlePost = async () => {
        setDisabledBtn(true);
        const response = await postByUrl(`bills`, dataToSend);
        setDisabledBtn(false);
        response?.success ? onSuccessfulPost("Заявки были отправлены") : onError(response?.message);
    };

    const handleUpdate = async () => {
        setDisabledBtn(true);
        const response = await putByUrl(`salaries/${dataToSend._id}`, { ...dataToSend, amount: dataToSend.subjectIds.length * dataToSend.rate * dataToSend.count });
        setDisabledBtn(false);
        response?.success ? onSuccessfulPost("Запись о выплатах обновлена") : onError(response?.message);
    };

    const handlePostSalary = async () => {
        setDisabledBtn(true);
        const response = await postByUrl(`salaries`, { ...dataToSend, amount: dataToSend.subjectIds.length * dataToSend.rate * dataToSend.count });
        setDisabledBtn(false);
        response?.success ? onSuccessfulPost("Запись о выплатах создана") : onError(response?.message);
    };

    const onSuccessfulPost = (text) => {
        toast.current.show({ severity: "success", summary: text });
        setCreate(false);
        handleClose();
    };

    const onError = (message) => {
        toast.current.show({
            severity: "error",
            summary: message || "Ошибка сервера. Повторите попытку позже.",
            life: 1000,
        });
    };

    const handleBack = () => {
        setDataToSend([]);
        setCreate(false);
        setOptions([]);
        setOpenedTabs([]);
    };

    const handleSalary = (d) => {
        setDataToSend(d);
        setCreate("salary");
    };

    // console.log(dataToSend)

    const disabled =
        create === "salary"
            ? dataToSend && dataToSend.count && dataToSend.subjectIds && dataToSend.rate
            : dataToSend && dataToSend.title && dataToSend.description && dataToSend.subjectIds && dataToSend.amount;

    const renderCreate = () => {
        switch (create) {
            case "payment":
                return (
                    <div className="payments__body">
                        <span className="payments__title">Для кого событие</span>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Заголовок*</label>
                            <InputText placeholder="Укажите заголовок" value={dataToSend.title} onChange={(e) => updateData(e.target.value, "title")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Описание*</label>
                            <InputTextarea placeholder="Опишите подробнее" value={dataToSend.description} onChange={(e) => updateData(e.target.value, "description")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Клубы*</label>
                            <MultiSelect
                                selectedItemsLabel={dataToSend?.subjectIds?.length + " клуб" + pluralForm(dataToSend?.subjectIds?.length, ["", "а", "ов"])}
                                maxSelectedLabels={0}
                                value={dataToSend.subjectIds}
                                filter
                                options={options}
                                optionLabel="name"
                                optionValue="_id"
                                appendTo={"self"}
                                onChange={(e) => updateData(e.value, "subjectIds")}
                                placeholder="Выберите клубы"
                                emptyFilterMessage="Ничего не найдено"
                            />
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Запрашиваемая сумма в рублях*</label>
                                <InputNumber placeholder="Укажите сумму" value={dataToSend.amount} onValueChange={(e) => updateData(e.target.value, "amount")} />
                            </div>
                        </div>
                    </div>
                );
            case "salary":
                return (
                    <div className="payments__body">
                        <span className="payments__title">Создать начисление зарплаты или оплату аренды</span>
                        <SelectButton
                            options={opt1}
                            value={dataToSend.type}
                            optionValue="key"
                            optionLabel="label"
                            onChange={(e) => {
                                if (e.value) {
                                    setDataToSend({ type: e.value, typeRate: "matches" });
                                }
                            }}
                        />
                        {dataToSend.type === "salary" && (
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Роль*</label>
                                <Dropdown
                                    emptyMessage="Ничего не найдено"
                                    placeholder="Выберите роль"
                                    value={dataToSend.role}
                                    onChange={(e) => {
                                        updateData(e.target.value, "role");
                                        updateData(null, "subjectIds");
                                    }}
                                    options={rolesOptions}
                                    appendTo="self"
                                />
                            </div>
                        )}
                        {dataToSend.type === "salary" ? (
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Сотрудники*</label>
                                <MultiSelect
                                    selectedItemsLabel={dataToSend?.subjectIds?.length + " сотрудник" + pluralForm(dataToSend?.subjectIds?.length, ["", "а", "ов"])}
                                    maxSelectedLabels={0}
                                    value={dataToSend.subjectIds}
                                    filter
                                    options={options[dataToSend.role]}
                                    optionLabel="name"
                                    optionValue="_id"
                                    appendTo={"self"}
                                    onChange={(e) => updateData(e.value, "subjectIds")}
                                    placeholder="Выберите сотрудников"
                                    emptyFilterMessage="Ничего не найдено"
                                    disabled={!dataToSend.role}
                                />
                            </div>
                        ) : (
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Площадки*</label>
                                <MultiSelect
                                    selectedItemsLabel={dataToSend?.subjectIds?.length + " площад" + pluralForm(dataToSend?.subjectIds?.length, ["ка", "ки", "ок"])}
                                    maxSelectedLabels={0}
                                    value={dataToSend.subjectIds}
                                    filter
                                    options={locations}
                                    optionLabel="name"
                                    optionValue="_id"
                                    appendTo={"self"}
                                    onChange={(e) => updateData(e.value, "subjectIds")}
                                    placeholder="Выберите площадки"
                                    emptyFilterMessage="Ничего не найдено"
                                />
                            </div>
                        )}
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Ставка в рублях*</label>
                            <InputNumber placeholder="Укажите сумму" value={dataToSend.rate} onValueChange={(e) => updateData(e.target.value, "rate")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Кол-во смен*</label>
                            <div className="p-inputgroup flex-1">
                                <InputNumber placeholder="Укажите кол-во" value={dataToSend.count} onValueChange={(e) => updateData(e.target.value, "count")} />
                                <Dropdown
                                    emptyMessage="Ничего не найдено"
                                    optionLabel="label"
                                    optionValue="key"
                                    value={dataToSend.typeRate}
                                    onChange={(e) => updateData(e.target.value, "typeRate")}
                                    options={opt2}
                                    appendTo="self"
                                />
                            </div>
                        </div>

                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Описание</label>
                            <InputTextarea placeholder="Опишите подробнее" value={dataToSend.description || ""} onChange={(e) => updateData(e.target.value, "description")} />
                        </div>
                        {disabled && (
                            <div className="payments__result">
                                <span>Итоговая сумма</span>
                                <span className="payments__result-rub">{new Intl.NumberFormat("ru-RU").format(dataToSend.subjectIds.length * dataToSend.rate * dataToSend.count)} ₽</span>
                            </div>
                        )}
                    </div>
                );
            default:
                break;
        }
    };

    const renderBody = () => {
        return create ? (
            renderCreate()
        ) : (
            <>
                <div className={`side-profile__accordion${openedTabs.includes("payments") ? " side-profile__accordion_active" : ""}`}>
                    <div className="side-profile__accordion-header">
                        <div className="side-profile__accordion-title">
                            Заявки на платеж
                            {role !== "club" && <i className="pi pi-plus-circle" onClick={() => setCreate("payment")} />}
                        </div>
                        <i className={`${openedTabs.includes("payments") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("payments")} />
                    </div>
                    <div className={`side-profile__accordion-payments ${state.length > 0 ? "" : "empty"}`}>
                        {state.length > 0 ? (
                            <div className="payments__items">
                                <SelectButton
                                    options={opt}
                                    optionLabel="label"
                                    value={select}
                                    onChange={(e) => {
                                        if (e.value) {
                                            setSelect(e.value);
                                        }
                                    }}
                                />
                                {bills.length > 0 ? (
                                    bills.map((item, idx) => <Payment d={item} idx={idx} role={role} onSuccessfulPost={onSuccessfulPost} onError={onError} />)
                                ) : (
                                    <div className="payments__empty">{select.none}</div>
                                )}
                            </div>
                        ) : (
                            <div className="payments__empty">Пока еще не было создано заявок</div>
                        )}
                    </div>
                </div>
                {role !== "club" && (
                    <div className={`side-profile__accordion${openedTabs.includes("salary") ? " side-profile__accordion_active" : ""}`}>
                        <div className="side-profile__accordion-header">
                            <div className="side-profile__accordion-title">
                                Начисления зарплат и аренда
                                <i className="pi pi-plus-circle" onClick={() => setCreate("salary")} />
                            </div>
                            <i className={`${openedTabs.includes("salary") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("salary")} />
                        </div>
                        <div className={`side-profile__accordion-payments ${salaries.length > 0 ? "" : "empty"}`}>
                            {salaries.length > 0 ? (
                                <div className="payments__items">
                                    {salaries.map((item, idx) => (
                                        <Salary d={item} idx={idx} role={role} onSuccessfulPost={onSuccessfulPost} onError={onError} handleItem={handleSalary} />
                                    ))}
                                </div>
                            ) : (
                                <div className="payments__empty">Пока еще не было создано начислений</div>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        state && (
            <div className="verifyModal payments">
                <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                    <div className="side-profile p-component p-sidebar-enter-done notices-modal__side" ref={ref}>
                        <div className="side-profile__header">
                            {renderHeader()}
                            <i className="side-profile__icon-close pi pi-times" onClick={handleClose} />
                        </div>
                        <div className="side-profile__body">{renderBody()}</div>
                    </div>
                </div>
            </div>
        )
    );
};

const opt = [
    { label: "Неподтвержденные", key: "active", none: "Все заявки обработаны" },
    { label: "Отклоненные", key: "declined", none: "Пока еще не никто не отклонил заявку" },
    { label: "Подтвержденные", key: "finished", none: "Пока еще не никто не подтвердил заявку" },
];

const opt1 = [
    { label: "Зарплата", key: "salary" },
    { label: "Аренда", key: "rent" },
];

const opt2 = [
    { label: "Матчей", key: "matches" },
    { label: "Часов", key: "hours" },
];

const rolesOptions = [
    { label: "Арбитр", value: "referee" },
    { label: "Делегат", value: "executive" },
    { label: "Медик", value: "medicine" },
    { label: "Фотограф", value: "photo" },
    { label: "Оператор", value: "video" },
];

export default Payments;
