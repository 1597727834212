import React, { useState } from "react";

import { Button } from "primereact/button";

import ImportVk from "./ImportVk";
import Form from "../Form";

import "./style.scss";

const modes = {
    clone: ImportVk,
    custom: Form,
};

const CreateForm = ({ profile, onSaved }) => {
    const [mode, setMode] = useState(null);
    const [option, setOption] = useState("clone");

    const Specified = mode ? modes[mode] || null : null;

    const initial =
        mode === "custom"
            ? {
                  title: "",
                  published: true,
                  attachments: [],
              }
            : null;

    return (
        <div className="create-flow">
            <Form profile={profile} data={initial} onSaved={onSaved} />
        </div>
    );
};

export default CreateForm;
