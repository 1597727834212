import React, {useContext, useEffect, useState} from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import {GlobalContext} from "../../../../ctx";
import {getByUrl} from "../../../../v3MethodsService";

import {ProgressSpinner} from "primereact/progressspinner";

import './style.scss'

const LocationsMap = ({ locations, handleClickLocation, format, confirmDelete, copyLink }) => {
    const [global, setGlobal] = useContext(GlobalContext)

    const [center, setCenter] = useState([0, 0])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        const getLeague = (id) => {
            getByUrl(`leagues/${id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        const lat = resp.data?.settlement?.geo_lat || 0
                        const lon = resp.data?.settlement?.geo_lon || 0
                        setCenter([lat, lon])
                        setLoader(false)
                    }
                })
        }

        if(global.auth && global.auth.data) {
            setLoader(true)
            const currentSubjectTkn = localStorage.getItem('_amateum_auth_tkn')
            let subj;

            const matched = global.auth.data.find(sub => sub.token === currentSubjectTkn)
            if (matched) {
                subj = {...matched}
                getLeague(subj._id)
            } else {
                if (global.auth.data[0]) {
                    subj = {...global.auth.data[0]}
                    getLeague(subj._id)
                } else {
                    alert('Access error')
                }
            }
        }
    }, [])

    const mapState = {
        center: center,
        zoom: 10,
        controls: ["zoomControl"]
    };

    const locWithGeo = locations.filter((location) => location.geo_lat && location.geo_lon); 

    return loader ? (
        <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
    ) : (
        <div className='locations-map'>
            <YMaps query={{ apikey: 'ac9fe790-18ee-47dc-91d1-d92bdf54af25', lang: 'ru_RU', load: 'package.full' }}>
                <Map defaultState={mapState} width="100%" height="700px" options={{ suppressMapOpenBlock: true, disableTraffic: true }}>
                    {locWithGeo.map((location, index) => (
                        <Placemark
                            key={index}
                            geometry={[location.geo_lat, location.geo_lon]}
                            properties={{
                                balloonContent: location.name,
                                iconCaption: location?.name,
                            }}
                            options={{
                                preset: 'islands#blueCircleIcon',
                            }}
                        />
                    ))}
                </Map>
            </YMaps>
        </div>
    );
};

export default LocationsMap;
