import React, {useContext, useEffect, useRef, useState} from 'react';

import moment from "moment/moment";

import DataTable from "../../../Common/DataTable";

import './style.scss'

const LocationDocuments = ({ data, option, handleDoc, deleteDoc }) => {

    const handleRemove = (e, item) => {
        e.stopPropagation();
        deleteDoc(item?._id);
    };

    const handleDownload = (e, item) => {
        e.stopPropagation();
        window.open(item?.fullpath);
    };

    return !!data && (
        <div className='location-documents clubs-application'>
            <DataTable
                noCreateBtn
                emptyMessage="Документы не найдены"
                search
                onClick={handleDoc}
                columns={[
                    { field: "name", header: "Название" },
                    { field: "file", header: "Оригинальный файл" },
                    { field: "author", header: "Автор" },
                    { field: "uploaded", header: "Загружен" },
                ]}
                rows={data.attachments?.length > 0 ? data.attachments
                    ?.filter((item) => {
                        if (option) {
                            return item.archived;
                        } else {
                            return !item.archived;
                        }
                    })
                    .sort((a, b) => {
                        return data.docs?.indexOf(a._id) - data.docs?.indexOf(b._id);
                    })
                    .map((row) => ({
                        ...row,
                        name: { value: row?.publicname },
                        file: { value: row?.filename },
                        author: {
                            value: `${row?.user?.surname ? row?.user?.surname : ""} ${row?.user?.name ? row?.user?.name.slice(0, 1) + "." : ""}${
                                row?.user?.middlename ? row?.user?.middlename.slice(0, 1) + "." : ""
                            }`,
                        },
                        uploaded: { value: moment(row?.date, "YY-MM-DD").format("DD.MM.YYYY") },
                    })) : []}
                paginator={data.length > 8}
                perPage={8}
                handleRemove={handleRemove}
                handleDownload={handleDownload}
            />
        </div>
    )
}

export default LocationDocuments;