import React, { useState, useEffect } from 'react'

import './style.scss'
import FileUploadUi from "../../../../../../UI/FileUploadUi/index.jsx";
import {ImageHandler} from "../../../../../Atoms";
import CustomUploader from "../../../CustomUploader";
import CustomUploads from "../../../CustomUploads";

const MultiFileUpload = ({min = 1, max = 10, images = [], onChange, label = ''}) => {
    const [countFiles, setCountFiles] = useState(images.length > 0 ? images.length : min)

    useEffect(() => {
        if (images.length === countFiles && images.length < max) {
            setCountFiles(prev => prev + 1)
        }
    }, [images]);

    const handleFileUpload = (file, key, index) => {
        let newImages = [...images]

        if (Array.isArray(file)) {
            newImages = newImages.concat(file).splice(0, max)
            if (newImages.length < max) {
                setCountFiles(prev => prev + file.length)
            } else {
                setCountFiles(max)
            }
        } else if (file) {
            newImages.push(file)
            if (newImages.length === countFiles && newImages.length < max) {
                setCountFiles(prev => prev + 1)
            }
        } else {
            newImages.splice(index, 1)
            if (newImages.length + 1 >= min) {
                setCountFiles(prev => prev - 1)
            }
        }
        onChange(newImages)
    }

    return (
        <div className='multifileupload__container'>
            <label htmlFor="comment" className="multifileupload__label">{label}</label>
            <div className='multifileupload'>
                {Array.from(Array(countFiles).keys()).map((f, index) => (
                    <div key={index} className='multifileupload__item'>
                        {/*{!!images[index] && <img src={images[index] ? images[index].url : ''} onClick={() => handleFileUpload(false, 'image', index)}/>}*/}
                        {/*<FileUploadUi*/}
                        {/*    key={index}*/}
                        {/*    index={index}*/}
                        {/*    image={images[index] ? images[index].url : ''}*/}
                        {/*    onChange={handleFileUpload}*/}
                        {/*    disabled={true}*/}
                        {/*/>*/}
                        <CustomUploader
                            image={images[index] ? {file: images[index].url} : null}
                            onChange={val => handleFileUpload(val, 'image', index)}
                            multiple={true}
                        />
                        {!images[index] && <div className='mediaForm-detail__main-divider'><span/>или<span/></div>}
                        {!images[index] && (
                            <CustomUploads
                                onUploaded={val => handleFileUpload(val, 'image', index)}
                                chooseOptions={chooseOptions}
                            />
                        )}
                        {/*{!images[index] && (*/}
                        {/*    <div className='custom-upload'>*/}
                        {/*        <div className='upload-section'>*/}
                        {/*            <div className='upload-action'>*/}
                        {/*                <ImageHandler*/}
                        {/*                    label='Загрузить'*/}
                        {/*                    ratio={16 / 9}*/}
                        {/*                    onUploaded={obj => handleFileUpload(obj, 'image', index)}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                ))}
            </div>
        </div>
    )
}

const chooseOptions = {label: 'Загрузить фото', icon: 'pi pi-upload'};

export default MultiFileUpload