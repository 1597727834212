import React, {useState, useEffect, useRef} from 'react'

import {getByUrl} from "../../../v3MethodsService";
import DataTable from "../Common/DataTable";
import ReferencesModal from "./Modal";

import {SelectButton} from "primereact/selectbutton";
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";

import './style.scss'

const References = ({}) => {
    const toast = useRef(null)

    const [selectedOption, setSelectedOption] = useState('rffs')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [modalState, setModalState] = useState(null)

    useEffect(() => {
        getData()
    }, [selectedOption])

    const getData = () => {
        setLoading(true)
        setData([])
        getByUrl(`${selectedOption}`)
            .then(resp => {
                if (resp && resp.success) {
                    setData(resp.data)
                }
                setLoading(false)
            })
    }

    const clickHandler = (obj) => {
        setModalState(obj.data)
    }

    const closeModal = () => {
        setModalState(false)
        getData()
    }

    return (
        <section id='references' className='references'>
            <Toast ref={toast} position='top-right'/>
            <ReferencesModal state={modalState} onClose={closeModal} toast={toast} type={selectedOption}/>
            <div className='notices__header'>
                <div className='notices__options'>
                    <SelectButton value={selectedOption} options={options} onChange={e => setSelectedOption(e.target.value)} className='notices__select'/>
                </div>
                {selectedOption !== 'rffs' && <Button icon='pi pi-plus' onClick={() => setModalState({...initial[selectedOption]})}/>}
            </div>
            {loading ? (
                <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
            ) : (
                <DataTable
                    onClick={clickHandler}
                    search
                    noCreateBtn
                    columns={[
                        { field: "first", header: tableLables[selectedOption]?.first },
                        { field: "second", header: tableLables[selectedOption]?.second, className: 'secondary' },
                    ]}
                    rows={data.map((row) => ({
                        ...row,
                        first:  { value: getNestedValue(row, tableKeys[selectedOption]?.first) },
                        second: { value: getNestedValue(row, tableKeys[selectedOption]?.second) },
                    }))}
                    paginator={data.length > 8}
                    perPage={8}
                />
            )}
        </section>
    )
}

const initial = {
    disciplines: {name: '', format: ''},
    positions: {name: '', shortName: ''},
    countries: {name: '', citizenship: ''},
    categories: {name: ''}
}

const tableKeys = {
    rffs: {first: 'federationData.name', second: 'name'},
    disciplines: {first: 'name', second: 'format'},
    positions: {first: 'name', second: 'shortName'},
    countries: {first: 'name', second: 'citizenship'},
    categories: {first: 'name', second: ''}
}

const tableLables = {
    rffs: {first: 'РФФ', second: 'Регион'},
    disciplines: {first: 'Дисциплины', second: 'Формат'},
    positions: {first: 'Позиции игроков', second: 'Сокращенное название'},
    countries: {first: 'Страна', second: 'Гражданство'},
    categories: {first: 'Категория', second: ''}
}

const getNestedValue = (obj, path) => path.split('.').reduce((acc, key) => acc?.[key], obj) || ''

const options = [
    {label: 'Привязка РФФ к регионам', value: 'rffs'},
    {label: 'Дисциплины', value: 'disciplines'},
    {label: 'Позиции игроков', value: 'positions'},
    {label: 'Гражданства', value: 'countries'},
    {label: 'Категории арбитра', value: 'categories'}
]

export default References