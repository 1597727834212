import React, { useState, useEffect, useContext } from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import { WorkspaceContext, GlobalContext } from '../../ctx'

import Switcher from './Switcher'
import Menu from './Menu'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { Button } from 'primereact/button'

import axios from 'axios'
import { ENDPOINT } from '../../env'

import menu from './leagueAdmin_current_menu_mockup.json'

import './style.scss'

// const getIndex = {
//     'federation': {
//         index: 0,
//         title: 'лигой'
//     },
//     'club': {
//         index: 1,
//         title: 'клубом'
//     },
//     'employee': {
//         index: 2,
//         title: 'сотрудником'
//     },
//     'superAdmin': {
//         index: 3,
//         title: ''
//     }
// }

// const menus = [
//     [
//         { label: 'События и запросы', icon: 'inbox', path: '/' },
//         { label: 'Расписание', icon: 'calendar-plus', path: '/schedule', hasDD: true },
//         { label: 'Управление турнирами', icon: 'star', path: '/tournaments', hasDD: true },
//         { label: 'Клубы и команды', icon: 'shield', path: '/clubs' },
//         { label: 'Игроки и тренеры', icon: 'id-card', path: '/players-and-coaches' }
//     ],
//     [
//         { label: 'Структура', icon: 'sitemap', path: '/structure' },
//         { label: 'Персонал', icon: 'users', path: '/staff' },
//         { label: 'Площадки', icon: 'map-marker', path: '/locations' }
//     ],
//     [
//         { label: 'Публикации', icon: 'image', path: '/publications' },
//         { label: 'Внешний вид', icon: 'palette', path: '/appearance' },
//         { label: 'Скачать графику', icon: 'instagram', path: '/renders' }
//     ],
//     [
//         { label: 'Поддержка', icon: 'comments', path: '/support' },
//         {
//             label: 'Выйти из аккаунта', icon: 'lock', command: () => {
//                 localStorage.removeItem('_amateum_uid');
//                 localStorage.removeItem('_amateum_auth_tkn');
//                 localStorage.removeItem('_amateum_auth_tkn');
//                 setTimeout(() => {
//                     window.location.reload()
//                 }, 300)
//             }
//         }
//     ]
// ]

// const compactMenus = [
//     [
//         { label: 'События и запросы', icon: 'inbox', path: '/' },
//         { label: 'Лента матчей (LIVE)', icon: 'bolt', path: '/live' },
//         //{label: 'Расписание', icon: 'calendar-plus', path: '/schedule', hasDD: true},
//         { label: 'Управление турнирами', icon: 'star', path: '/tournaments', hasDD: true },
//         { label: 'Клубы и команды', icon: 'shield', path: '/clubs' },
//         { label: 'Игроки и тренеры', icon: 'id-card', path: '/players-and-coaches' }
//     ],
//     [
//         { label: 'Структура', icon: 'sitemap', path: '/structure' },
//         { label: 'Персонал', icon: 'users', path: '/staff' },
//         { label: 'Площадки', icon: 'map-marker', path: '/locations' }
//     ],
//     // [
//     //     {label: 'Медиа и публикации', icon: 'image', path: '/publications'},
//     //     {label: 'Внешний вид', icon: 'palette'}
//     // ],
//     [
//         { label: 'Скачать графику', icon: 'instagram', path: '/renders' }
//     ],
//     [
//         { label: 'Поддержка', icon: 'comments', path: '/support' },
//         {
//             label: 'Выйти из аккаунта', icon: 'lock', command: () => {
//                 localStorage.removeItem('_amateum_uid');
//                 localStorage.removeItem('_amateum_auth_tkn');
//                 localStorage.removeItem('_amateum_auth_tkn');
//                 setTimeout(() => {
//                     window.location.reload()
//                 }, 300)
//             }
//         }
//     ]
// ]

const handleLogOut = () => {
    localStorage.removeItem('_amateum_uid');
    localStorage.removeItem('_amateum_auth_tkn');
    localStorage.removeItem('_amateum_auth_tkn');
    setTimeout(() => {
        window.location.reload()
    }, 300)
}

const confirm = () => {
    confirmDialog({
        message: 'Вы уверены, что хотите выйти?',
        header: 'Выйти из аккаунта',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Да',
        rejectLabel: 'Нет',
        accept: handleLogOut,
    });
};

const clubMenus = [
    
        { label: 'Поддержка', icon: 'comments', path: 'support' },
        { label: 'Выйти из аккаунта', icon: 'lock', command: confirm }
    
]
// const employeeMenus = []
// const adminMenus = [
//     [
//         { label: 'Управление федерациями', icon: 'star', path: '/federations' },
//         { label: 'Админы', icon: 'id-card', path: '/users' }
//     ],
//     []
// ]

// const getMenuType = (type, compact = false) => {
//     let m = [];

//     switch (true) {
//         case type === 'club':
//             m = clubMenus
//             break;
//         case type === 'federation':
//             if (compact) {
//                 m = compactMenus
//             } else {
//                 m = menus
//             }
//             break;
//         case type === 'employee':
//             m = employeeMenus
//             break;
//         case type === 'superAdmin':
//             m = adminMenus
//             break;
//         default:
//             break
//     }
//     return m
// }

const Sideblock = ({ subject, theme, compact, collapsed, toggleCollapsedSide, toggleShownMenu, userTkn, onSubjectChanged, onProfileChanged, onTitleChanged }) => {
    const [global, setGlobal] = useContext(GlobalContext)
    const wctx = useContext(WorkspaceContext)
    const tkn = global && global.auth && global.auth.token ? global.auth.token : ''
    // const [menu, setMenu] = useState([])
    const location = useLocation()
    const param = location.pathname.split('/')

    // const [active, setActive] = useState([0, 0])
    // const [activeSubjectType, setActiveSubjectType] = useState(subject ? subject.type : null)
    // const [menuSource, setMenuSource] = useState(subject ? getMenuType(subject.type) : [])

    // const ctx = useContext(WorkspaceContext)
    const history = useHistory()

    useEffect(() => {
        if (tkn && global.auth && global.auth.data) {
            axios.get(`${ENDPOINT}methodsV3/getMenuFromRole`, {
                headers: {
                    scopetoken: tkn
                }
            }).then(resp => {
                    if (resp && resp.data && resp.data.success) {
                        const arr = resp.data.data
                            ?.sort((a,b) => (menuDataItems?.[a.layoutId]?.sort ?? 100) > (menuDataItems?.[b.layoutId]?.sort ?? 100) ? 1 : -1)
                            ?.reduce((acc, row) => {
                                const section = menuDataItems?.[row.layoutId]?.section ?? 100
                                if (!acc[section]) {
                                    acc[section] = [];
                                }
                                const obj = {
                                    label: row.menuData && row.menuData.length === 1 && row.layoutId !== 'disqualifications' ? row.menuData[0].title : row.title || menuDataItems?.[row.layoutId]?.title || '',
                                    icon: menuDataItems?.[row.layoutId]?.icon || row.icon || '',
                                    path: row.layoutId,
                                    isOld: menuDataItems?.[row.layoutId]?.isOld || false,
                                    menuData: row.menuData?.filter(m => m.scope !== "league_tournamentDocs")
                                }
                                acc[section].push(obj)
                                if (obj.path === 'matches') {
                                    acc[section].push({
                                        label: 'Лента матчей (LIVE)',
                                        icon: 'bolt',
                                        path: 'live',
                                        isOld: true,
                                        menuData: [],
                                        onlyCompact: true
                                    })
                                }
                                return acc
                            }, [])

                        if (global && global.auth && global.auth.data && !param[1]) {
                            let item = global.auth.data.find((item) => item.token === tkn)
                            if (item?.type === 'system') {
                                history.replace('/roles')
                            }
                            else {
                                let role = arr.find(item => !!item);
                                history.replace(role && role[0] && role[0].path ? '/' + role[0].path : '/')
                            }
                        }

                        if (wctx && wctx.workspace && wctx.workspace.subject && wctx.workspace.subject.token && wctx.workspace.subject.type) {
                            if (wctx?.workspace?.subject?.type === 'system') {
                                history.replace('/roles')
                            }
                            // else {
                            //     let role = arr.find(item => !!item);
                            //     history.replace(role && role[0] && role[0].path ? '/' + role[0].path : '/')
                            // }

                        }


                        setGlobal(prev => ({ ...prev, menu: arr }))
                    } else setGlobal(prev => ({ ...prev, menu: [] }))
                })
        }
    }, [tkn])

    useEffect(() => {
        if (wctx && wctx.workspace && wctx.workspace.subject && tkn !== wctx.workspace.subject.token) {
            setGlobal(prev => ({ ...prev, auth: {...prev.auth, token: wctx.workspace.subject.token}}))
        }
    }, [wctx]);

    useEffect(() => {
        if (global && global.menu && !global.activeMenu && param[1] !== 'live') {
          const newActiveMenu = global.menu
            ?.filter(m => m)
            ?.flat()
            ?.find(m => m?.path === param[1]);
      
          if (newActiveMenu && newActiveMenu !== global.activeMenu) {
            setGlobal(prev => ({ ...prev, activeMenu: newActiveMenu }));
          }
        }
      }, [global, param]);

    const handleMenu = (item) => {
        setGlobal(prev => ({ ...prev, activeMenu: item }))
        if (toggleShownMenu) {
            setTimeout(() => {
                toggleShownMenu()
            }, 400)
        } else if (collapsed === false && compact) {
            toggleCollapsedSide()
        }
        if (!item.isOld) {
            // axios.get(`${ENDPOINT}methodsV3/getMenuData?section=${id}`, {
            //     headers: {
            //         scopetoken: tkn
            //     }
            // }).then(resp => {
            //     if (resp.data && resp.data.success) {
            //         console.log('resp', resp);
            //     }
            // })
        }
    }

    // useEffect(() => {
    //     if (subject) {
    //         if (activeSubjectType && (subject.type !== activeSubjectType)) {
    //             if (subject.type === 'superAdmin') {
    //                 history.push('/federations')
    //             } else {
    //                 history.push('/')
    //             }
    //             setActive([0, 0])
    //         }

    //         setActiveSubjectType(subject.type)
    //         setMenuSource(subject ? getMenuType(subject.type, compact) : [])
    //         ctx.setWorkspace({ ...ctx.workspace, subject: subject })
    //     }
    // }, [subject])

    // useEffect(() => {
    //     if (menuSource[active[0]] && menuSource[active[0]][active[1]]) {
    //         onTitleChanged(menuSource[active[0]][active[1]].label)
    //         if (!menuSource[active[0]][active[1]].hasDD) {
    //             ctx.setWorkspace({ ...ctx.workspace, topDropdown: null })
    //         }
    //     }
    // }, [active])

    // useEffect(() => {
    //     const { pathname } = window.location
    //     let sectionIdx, itemIdx
    //     menuSource.map((sect, i) => {
    //         const _itemIdx = sect.findIndex(item => item.path === pathname)
    //         if (_itemIdx > -1) {
    //             sectionIdx = i
    //             itemIdx = _itemIdx
    //         }
    //     })

    //     if (sectionIdx > -1) {
    //         setActive([sectionIdx, itemIdx])
    //     }
    // }, [menuSource])

    return <div className={'sideblock' + (compact ? ' compact' : '') + (collapsed ? ' collapsed' : '')} style={{ backgroundColor: `var(--${theme}-900)` }}>
        <ConfirmDialog/>
        <Switcher
            onSubjectChanged={onSubjectChanged}
            onProfileChanged={onProfileChanged}
            collapsed={collapsed}
            toggleCollapsedSide={toggleCollapsedSide}
            userTkn={tkn}
        />
        {/* {menuSource.map((m, idx) => (
            <Menu
                loading={!subject}
                collapsed={collapsed}
                key={idx}
                data={m}
                menuIdx={idx}
                isLast={idx === (menuSource.length - 1)}
                active={active}
                setActive={arr => {
                    setActive(arr)
                    if (toggleShownMenu) {
                        setTimeout(() => {
                            toggleShownMenu()
                        }, 400)
                    } else if (collapsed === false && compact) {
                        toggleCollapsedSide()
                    }
                }}
            />
        ))} */}

        {global && global.menu ? global.menu.map((m, idx) => (
            <Menu
                // loading={!menu.length}
                collapsed={collapsed}
                key={idx}
                data={m?.filter(f => compact ? f : !f?.onlyCompact)}
                // menuIdx={idx}
                // isLast={idx === (global.menu.length - 1)}
                // active={active}
                setActive={handleMenu}
                // setActive={arr => {
                //     console.log(arr);
                //     // setActive(arr)
                //     // if (toggleShownMenu) {
                //     //     setTimeout(() => {
                //     //         toggleShownMenu()
                //     //     }, 400)
                //     // } else if (collapsed === false && compact) {
                //     //     toggleCollapsedSide()
                //     // }
                // }}
            />
        )) : null}

        <Menu
                // loading={!menu.length}
                collapsed={collapsed}
                // key={idx}
                data={clubMenus}
                // menuIdx={idx}
                isLast={true}
                // active={active}
                setActive={handleMenu}
            // setActive={arr => {
            //     console.log(arr);
            //     // setActive(arr)
            //     // if (toggleShownMenu) {
            //     //     setTimeout(() => {
            //     //         toggleShownMenu()
            //     //     }, 400)
            //     // } else if (collapsed === false && compact) {
            //     //     toggleCollapsedSide()
            //     // }
            // }}
            />


        {compact && (collapsed === false) ? (
            <Button
                className='p-button p-button-text p-button-rounded collapse-trigger'
                icon='pi pi-times'
                onClick={() => toggleShownMenu()}
            />
        ) : null}
    </div>
}

const menuDataItems = {
    matches: { title: 'Лента матчей', icon: 'inbox', isOld: true, sort: 0, section: 0 },
    // eventsAndRequests: { title: 'События и запросы', icon: 'inbox', isOld: true, sort: 0, section: 0 },
    schedule: { title: 'Расписание', icon: 'calendar-plus', isOld: true, sort: 1, section: 0 },
    regions: { title: 'РФФ', icon: 'globe', sort: 2, section: 0 },
    leagueData: { title: 'Лиги', icon: 'th-large', isOld: true, sort: 3, section: 0 },
    tournaments: { title: 'Управление турнирами', icon: 'star', isOld: true, sort: 4, section: 0 },
    clubs: { title: 'Клубы и команды', icon: 'shield', isOld: true, sort: 5, section: 0 },
    queries: { title: 'Заявки на турнир', icon: 'inbox', sort: 6, section: 0 },
    // players: { title: 'Игроки', icon: 'id-card', isOld: true, sort: 7, section: 0 },
    // 'players-and-coaches': { title: 'Игроки и тренеры', icon: 'id-card', isOld: true, sort: 7, section: 0 },
    structure: { title: 'Структура', icon: 'sitemap', isOld: true, sort: 7, section: 1 },
    'playersAndEmpls': { title: 'Игроки и сотрудники', icon: 'users', sort: 8, section: 1 },
    // employees: { title: 'Персонал', icon: 'users', isOld: true, sort: 9, section: 1 },
    locations: { title: 'Площадки', icon: 'map-marker', isOld: true, sort: 10, section: 1 },
    roles: { title: 'Роли', icon: 'tag', isOld: false, sort: 11, section: 1 },
    createQueries: { title: 'Верификация заявок', icon: 'thumbs-up', sort: 12, section: 1 },
    invite: { title: 'Приглашение пользователей', icon: 'link', sort: 13, section: 1 },
    notices: { title: 'Уведомления', icon: 'bell', sort: 14, section: 1 },
    references: { title: 'Справочники', icon: 'book', sort: 15, section: 1 },
    publications: { title: 'Публикации', icon: 'image', isOld: true, sort: 16, section: 2 },
    leagueReports: { title: 'Аналитический отчет', icon: 'chart-bar', sort: 17, section: 2 },
    regionReports: { title: 'Аналитический отчет', icon: 'chart-bar', sort: 17, section: 2 },
    appearance: { title: 'Внешний вид', icon: 'palette', isOld: true, sort: 18, section: 2 },
    infographics: { title: 'Скачать графику', icon: 'instagram', isOld: true, sort: 19, section: 2 },
    // support: { title: 'Поддержка', icon: 'comments', isOld: true, sort: 16, section: 4 },
    matchesRedactor: { icon: 'bolt', isOld: true },
    live: { icon: 'bolt', isOld: true },
    manageclub: { icon: 'shield', isOld: true },
    leagueAdminsAccounts: { title: 'Админы', icon: 'id-card', isOld: true },
}

export default Sideblock
