import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { getByUrl, postByUrl, putByUrl } from "../../../../../v3MethodsService";
import { transliterate } from "transliteration";

import FileUploadUi from "../../../Leagues/Detail/FileUploadUi";

import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { confirmDialog } from "primereact/confirmdialog";
import "./style.scss";
import { diffData } from "../../../../../utils";

const LocationProfile = ({ data, updateData, deleteLocation }) => {
    const toastRef = useRef(null);
    const history = useHistory();
    const [isChanged, setIsChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeableData, setChangeableData] = useState(data);
    const dataToSend = diffData(data, changeableData);

    const [suggestions, setSuggestions] = useState();

    const handleUpdate = (val, key, subKey, dropdown) => {
        if (subKey) {
            setChangeableData({ ...changeableData, [key]: { ...changeableData[key], [subKey]: val } });
        } else if (key) {
            setChangeableData({ ...changeableData, [key]: val });
        } else {
            setChangeableData(val);
        }
    };

    const onSelect = async (event, key, subKey = false, arr = false) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("s3only", true);
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.path;
                let array = [];
                if (arr) {
                    if (subKey) {
                        array = data[key]?.[subKey] || [];
                        array.push(file);
                    } else {
                        array = data[key] || [];
                        array.push(file);
                    }
                }
                handleUpdate(arr ? array : file, key, subKey);
                setIsChanged(true);
            }
        });
    };

    const handleSave = () => {
        setLoading(true);
        console.log(dataToSend);
        if (data._id) {
            putByUrl(`locations/${data._id}`, dataToSend).then((resp) => {
                if (resp && resp.success) {
                    toastRef.current.show({ severity: "success", detail: "Площадка сохранена" });
                } else {
                    toastRef.current.show({ severity: "error", summary: resp.message || "Ошибка сервера. Повторите попытку позже." });
                }
                setLoading(false);
                setIsChanged(false);
            });
        } else {
            postByUrl("locations", dataToSend).then((resp) => {
                if (resp && resp.success) {
                    toastRef.current.show({ severity: "success", detail: "Площадка добавлена" });
                    history.replace(`/locations/${resp.data._id}`);
                } else {
                    toastRef.current.show({ severity: "error", summary: resp.message || "Ошибка сервера. Повторите попытку позже." });
                }
                setLoading(false);
                setIsChanged(false);
            });
        }
    };

    const handleDelete = () => {
        deleteLocation(data).then((resp) => {
            if (resp) {
                history.replace("/locations");
            }
        });
    };

    const customUpdate = (param1, param2, param3, param4) => {
        setIsChanged(true);
        handleUpdate(param1, param2, param3, param4);
    };

    const completeMethod = (search) => {
        getByUrl(`searchInDadata?query=${search}&sample=address`).then((resp) => {
            if (resp && resp.success) {
                setSuggestions(resp.data);
            }
        });
    };

    const autoCompleteUpdate = (value) => {
        if (typeof value === "string") {
            customUpdate(value, "address");
            return;
        }
        if (value) {
            customUpdate({ ...data, address: value.name, geo_lat: value.geo_lat, geo_lon: value.geo_lon });
        }
    };

    const copyLink = () => {
        navigator.clipboard.writeText(`https://live.amateum.com/s/${data._id}`);
        toastRef.current.show({ severity: "success", detail: "Ссылка на оверлей скопирована" });
    };

    const deleteHandler = () => {
        confirmDialog({
            message: "Проверьте, пожалуйста, участвует ли эта площадка в запланированных матчах. После её удаления матчи потеряют указанное место проведения",
            header: "Удалить площадку?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: () => {
                handleDelete();
            },
            reject: () => {},
        });
    };

    const disabledBtn = changeableData && changeableData.photo && changeableData.name && changeableData.format && dataToSend && isChanged;

    return (
        !!data && (
            <div className="location-profile clubs-profile">
                <Toast ref={toastRef} position="top-right" />

                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Основная информация</span>
                    </div>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Изображение* <span className="clubs-profile__label muted">(jpg, jpeg, png, до 5 Мб)</span>
                            </label>
                            <FileUploadUi image={changeableData?.photo || null} onChange={(e) => onSelect(e, "photo")} updateData={customUpdate} remove={() => customUpdate(null, "photo")} />
                        </div>
                        <div className="clubs-profile__main">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Название*</label>
                                <InputText placeholder="Укажите название" value={changeableData?.name || ""} onChange={(e) => customUpdate(e.target.value, "name")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Формат*</label>
                                <Dropdown
                                    placeholder="Выберите формат"
                                    emptyFilterMessage={"Ничего не найдено"}
                                    emptyMessage={"Ничего не найдено"}
                                    value={changeableData?.format || ""}
                                    onChange={(e) => customUpdate(e.value, "format", false, true)}
                                    options={formatOptions}
                                    showFilterClear
                                    appendTo={"self"}
                                />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Вместимость</label>
                                <InputNumber placeholder="Укажите кол-во человек" value={changeableData?.capacity || ""} onChange={(e) => customUpdate(e.value, "capacity")} min={0} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Адрес</label>
                                <AutoComplete
                                    placeholder="Укажите адрес"
                                    value={changeableData?.address || ""}
                                    onChange={(e) => autoCompleteUpdate(e.target.value)}
                                    suggestions={suggestions}
                                    completeMethod={(e) => completeMethod(e?.query?.toLowerCase())}
                                    field="name"
                                    className="location-profile__autocomplete"
                                    delay={500}
                                    tooltip="г. Город, ул. Улица, д. Дом"
                                    tooltipOptions={{ position: "top" }}
                                />
                            </div>
                            {/*<div className="clubs-profile__container">
                            <label className="clubs-profile__label">Адрес</label>
                            <InputTextarea placeholder="Укажите адрес" value={data.address || ""} onChange={(e) => customUpdate(e.target.value, "address")}/>
                        </div>*/}
                        </div>
                    </div>
                </div>

                {!!data._id && (
                    <div className="clubs-profile__card">
                        <div className="clubs-profile__header">
                            <span className="clubs-profile__header-title">Статистика</span>
                        </div>
                        <div className="clubs-profile__content location-profile__stats">
                            {stats.map((s) => (
                                <div key={s.key} className={`location-profile__stat${data.stats?.[s.key] ? "" : " location-profile__stat_empty"}`}>
                                    {s.label}
                                    <span>{new Intl.NumberFormat("ru-RU").format(data.stats?.[s.key]) || 0}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="clubs-detail__button">
                    {!!data._id && <Button icon="pi pi-trash" label="Удалить" className="p-button-danger" onClick={deleteHandler} disabled={loading} />}
                    {!!data._id && <Button icon="pi pi-copy" label="Скопировать ссылку" className="p-button-outlined" onClick={copyLink} disabled={loading} />}

                    {!!disabledBtn && <Button icon="pi pi-check" label="Сохранить" onClick={handleSave} disabled={loading} />}
                </div>
            </div>
        )
    );
};

const formatOptions = [
    { label: "Все форматы", value: "all" },
    { label: "11x11", value: "11x11" },
    { label: "9x9", value: "9x9" },
    { label: "8x8", value: "8x8" },
    { label: "7x7", value: "7x7" },
    { label: "6x6", value: "6x6" },
    { label: "5x5", value: "5x5" },
];

const stats = [
    { label: "Турниров", key: "tournaments" },
    { label: "Матчей", key: "matches" },
    { label: "Игроков", key: "players" },
    { label: "Голов", key: "goals" },
    { label: "Зрителей", key: "guests" },
];

export default LocationProfile;
