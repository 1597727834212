import { useEffect, useState } from "react";

import { DataTable as DataTablePR } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import BodyItem from "./BodyItem/index.jsx";

import copy from "./img/copy.png";

import "./style.scss";
import { AutoComplete } from "primereact/autocomplete";

const DataTable = ({
    rows = [],
    columns = [],
    reorderableRows = false,
    onRowReorder = (value) => {},
    onAdd = () => {},
    onPinned = (value) => {},
    onClick = () => {},
    linkHandler = () => {},
    copyHandler = (any) => {},
    height = "max-content",
    width = "100%",
    emptyMessage = "По вашему запросу ничего не найдено",
    searchPlaceholder = "Название...",
    actions = false,
    perPage = 5,
    paginator = false,
    limitPin = 3,
    search = false,
    customSearch = false,
    customSearchValue = "",
    onCustomSearch = () => {},
    handleRemove = false,
    noCreateBtn = false,
    pagination = false,
    setPagination = () => {},
    handleDownload = false,
    customDataColumn = false,
    autoComplete = false,
    selection = false,
    onSelectionChange = () => {},
    filters = false,
    setFilter = () => {},
    clearFilter,
    filterValue,
    onPageChange = (any) => {}
}) => {
    const [data, setData] = useState(rows);
    const [searchString, setSearchString] = useState("");
    const [first, setFirst] = useState(pagination ? pagination : 0);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    useEffect(() => {
        setData(rows);
        setSearchString("");
        setSortField(null);
        setSortOrder(null);
    }, [rows]);

    useEffect(() => {
        let filteredData = [...rows];

        if (searchString) {
            filteredData = filteredData.filter((item) => {
                return Object.keys(item).some((key) => {
                    return (
                        item[key] &&
                        (item[key].toString().toLowerCase().includes(searchString.toLowerCase()) || (item[key].value && item[key].value.toString().toLowerCase().includes(searchString.toLowerCase())))
                    );
                });
            });
        }

        if (sortField && sortOrder) {
            filteredData.sort((a, b) => {
                const valueA = a[sortField]?.value;
                const valueB = b[sortField]?.value;

                let result = 0;
                if (valueA < valueB) {
                    result = -1;
                } else if (valueA > valueB) {
                    result = 1;
                }

                return sortOrder * result;
            });
        }

        const pinnedData = filteredData.filter((item) => item.isPinned);
        const unpinnedData = filteredData.filter((item) => !item.isPinned);

        setData([...pinnedData, ...unpinnedData]);
    }, [searchString, sortField, sortOrder, rows]);

    const sortHandler = (e) => {
        const { sortField, sortOrder } = e;
        setSortField(sortField);
        setSortOrder(sortOrder === 0 ? null : sortOrder);
    };

    const emptyItem = (
        <div className="data-table__empty">
            Еще не было добавлено элементов
            <Button label="Добавить" icon="pi pi-plus" type="button_outlined-blue" onClick={onAdd} disabled={true} />
        </div>
    );

    const searchHeader = (
        <>
            <InputText value={searchString || ""} onChange={(e) => setSearchString(e.target.value)} placeholder={searchPlaceholder} />
            <i className="pi pi-search" />
        </>
    );

    const customSearchHeader = (
        <>
            <InputText value={customSearchValue || ""} onChange={(e) => onCustomSearch(e.target.value)} placeholder={searchPlaceholder} />
            <i className="pi pi-search" />
        </>
    );

    const onPage = (event) => {
        setFirst(event.first);
        setPagination(event.first);
        onPageChange(event)
    };

    const filterApplyTemplate = (options, key) => {
        return (
            <Button
                type="button"
                onClick={() => {
                    setFilter({ val: filterValue, key: key });
                    options.filterApplyCallback();
                }}
                severity="success"
                label="Фильтровать"
            ></Button>
        );
    };

    const dynamicColumns = columns.map((col) =>
        col ? (
            <Column
                key={col.field}
                columnKey={col.field}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                headerClassName={col.className}
                bodyClassName={col.className}
                body={(rowData, i) => <BodyItem key={i} item={rowData[col.field]} />}
                filter={col.filter}
                filterElement={col.filterElement}
                showFilterMatchModes={false}
                filterApply={(options) => filterApplyTemplate(options, col.filterKey)}
                onFilterClear={clearFilter}
            />
        ) : null
    );


    return columns && columns.length ? (
        <DataTablePR
            value={data}
            // value={data.filter(f => f.isVisible)}
            reorderableRows={reorderableRows}
            onRowReorder={onRowReorder}
            className={`data-table ${search || customSearch ? "search" : ""} ${autoComplete ? "autoComplete" : ""}`}
            style={{ height: height, width: width }}
            emptyMessage={rows.length ? emptyMessage : noCreateBtn ? emptyMessage : emptyItem}
            removableSort
            onSort={sortHandler}
            sortField={sortField}
            sortOrder={sortOrder}
            onRowClick={onClick}
            rows={perPage}
            paginator={paginator}
            onPage={onPage}
            first={first}
            dataKey="_id"
            selection={selection}
            onSelectionChange={(e) => {
                onSelectionChange(e.value);
            }}
            selectionMode={selection ? "checkbox" : null}
            filters={filters}
        >
            {!!selection && <Column selectionMode="multiple" headerStyle={{ width: "7%" }} style={{ width: "7%" }} />}
            {!!reorderableRows && <Column rowReorder headerClassName="data-table__reorder" bodyClassName="data-table__reorder" />}
            {!!search && <Column header={searchHeader} headerClassName="data-table__search" bodyStyle={{ display: "none" }} />}
            {!!customSearch && <Column header={customSearchHeader} headerClassName="data-table__search" bodyStyle={{ display: "none" }} />}
            {!!autoComplete && autoComplete()}
            {dynamicColumns}
            {!!actions?.copy && (
                <Column
                    headerClassName="data-table__action"
                    bodyClassName="data-table__action"
                    body={(rowData) =>
                        rowData.type.value === "system" ? (
                            <>
                                <Tooltip target={`.tooltip_link_${rowData._id}`} position="left" />
                                <span
                                    className={`tooltip_link_${rowData._id}`}
                                    data-pr-tooltip="Сделать копию роли"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        copyHandler(rowData._id);
                                    }}
                                >
                                    <img src={copy} />
                                </span>
                            </>
                        ) : null
                    }
                />
            )}
            {!!customDataColumn && customDataColumn()}
            {!!handleDownload && (
                <Column
                    headerClassName="data-table__action"
                    bodyClassName="data-table__action"
                    body={(rowData, idx) => (
                        <div className="data-table__action-download" onClick={(e) => handleDownload(e, rowData, idx)}>
                            <i className="pi pi-download"></i>
                        </div>
                    )}
                />
            )}
            {!!handleRemove && (
                <Column
                    headerClassName="data-table__action"
                    bodyClassName="data-table__action"
                    body={(rowData, idx) => (
                        <div className="data-table__action-remove" onClick={(e) => handleRemove(e, rowData, idx)}>
                            <i className="pi pi-trash"></i>
                        </div>
                    )}
                />
            )}
            {!!actions?.angle && <Column headerClassName="data-table__angle tag" bodyClassName="data-table__angle tag" body={() => <i className="pi pi-angle-right" />} />}
        </DataTablePR>
    ) : null;
};

export default DataTable;
