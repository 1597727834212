import React, {useEffect, useState} from "react";

import service from "../../../../../../../Tournaments/service";
import {clearToast} from "../../../../../../../../utils";

import {Button} from "primereact/button";
import Matchday from "../Matchday";

import "./style.scss";

const PlayoffCalendar = ({ state, current, toast, grid }) => {
    const [timeoutID, setTimeoutID] = useState(null);
    const [data, setData] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null)
    const [saveClick, setSaveClick] = useState(false)

    useEffect(() => {
        if (current && grid) {
            const md = grid && grid.matchdays && grid.matchdays.length ? {...grid.matchdays[0], name: grid.name} : generateEmptyRoundMds({
                stageId: data._id,
                playoffgridId: grid._id,
                teams: state.teams,
                dayName: grid.name || '',
            });

            const merged = mergemdWithEmpty({...md})
            const mdFromBDCount = merged.matches.filter(m => m.homeId && m.awayId)

            if (current.roundsQty > 1 && mdFromBDCount.length === Math.floor(grid.teams.length / 2)) {
                merged.matches = merged.matches.slice(0, Math.floor(grid.teams.length / 2))
                merged.matches = merged.matches.concat(reverseRoundMds(merged.matches))
                setIsChanged(true)
                // const reverse = {
                //     name: merged.name,
                //     matches: reverseRoundMds(merged.matches),
                //     playoffgridId: merged.playoffgridId,
                //     stageId: merged.stageId,
                //     type: merged.type
                // }
                setData({...grid, matchdays: [merged]})
                // updMatchDay(merged)
            } else if (current.roundsQty === 1 && mdFromBDCount.length > Math.floor(grid.teams.length / 2)) {
                merged.matches = merged.matches.slice(0, Math.floor(grid.teams.length / 2))
                setData({...data, matchdays: [merged]})
            } else {
                merged.matches = merged.matches.slice(0, Math.floor(grid.teams.length / 2) * current.roundsQty)
                setData({...grid, matchdays: [merged]})
            }
        }

    }, [current, grid]);

    const mergemdWithEmpty = md => {
        const merged = {...md}
        const msteams = md.matches && md.matches.length ? md.matches.reduce((acc, m) => {
            if (m.homeId && !acc[m.homeId]) {
                acc[m.homeId] = m.home
            }
            if (m.awayId && !acc[m.awayId]) {
                acc[m.awayId] = m.away
            }
            return acc
        }, {}) : {}
        const uns = state.teams.filter(t => !msteams[t._id]);
        if (uns.length) {
            const matchesCount = Math.floor((uns.length * 10 / 2)/10);
            const emptymatches = md.matches.filter(m => !m.home || !m.awayId).length;

            if (emptymatches < matchesCount) {
                const mdd = generateEmptyRoundMds({
                    stageId: data._id,
                    playoffgridId: grid._id,
                    teams: uns,
                    dayName: grid.name || '',
                })

                merged.matches = [...md.matches, ...mdd.matches.slice(0, matchesCount-emptymatches)]
            }
            setType('manual')
        } else {
            setType(md._id ? 'fromDB' : 'manual')
        }

        return merged
    }

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData(prev => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else {
            setData(prev => ({ ...prev, [key]: val }));
        }
    };

    const checkUnassigned = () => {
        const assigned = data && data.teams ? data.teams.map(t => t._id): []

        return state?.teams
            .filter(t => !assigned.includes(t._id))
            .map(t => t.name)
    }

    const unassigned = checkUnassigned()

    const handleSave = async () => {
        setLoading(true)
        setSaveClick(true)
        service.applyCalendarGridDraft(data, toast)
            .then(() => {
                setLoading(false)
                setSaveClick(false)
            })
        clearToast(toast, timeoutID, setTimeoutID)
    }

    const updateMd = (md, id) => {
        const newMdays = [...data.matchdays]
        newMdays[id] = md
        updateData(newMdays, 'matchdays')
        setIsChanged(true)
    }

    const manualGenerate = () => {
        const mdays = generateEmptyRoundMds({
            stageId: data._id,
            playoffgridId: grid._id,
            teams: data.teams,
            dayName: grid.name || '',
        });
        if (mdays) {
            updateData([mdays], 'matchdays')
        }
        setType('manual')
    }

    return data && data.matchdays?.length > 0 ? (
        <div className="calendar-detail">
            {unassigned?.length > 0 && (
                <div className='calendar-item__unassigned'>
                    <i className='pi pi-info-circle'/>
                    Не распределены команды: {unassigned.join(', ')}
                </div>
            )}
            <div className='calendar-item__row'>
                {data.matchdays.map((m, key) => <Matchday id={key} key={key} mday={m} current={data} state={state} updateMd={updateMd} saveClick={saveClick}/>)}
            </div>
            <div className="clubs-detail__button">
                {isChanged && <Button label="Сохранить" onClick={handleSave} disabled={!isChanged || loading} loading={loading}/>}
            </div>
        </div>
    ) : (
        <div className="calendar-item calendar-item_empty">
            <div className='calendar-item__container'>
                Календаря еще нет.<br/>Выберите режим создания
                <div className='calendar-item__container-btn-set'>
                    <Button label='Ручной режим' className='calendar-item__container-btn calendar-item__container-btn_outlined' onClick={manualGenerate}/>
                </div>
            </div>
        </div>
    );
};

const generateEmptyRoundMds = ({ stageId, playoffgridId, teams, dayName }) => {
    const teamsCount = teams.length;
    const matchesCount = Math.floor((teamsCount * 10 / 2)/10);

    const md = {
        name: dayName,
        stageId: stageId,
        playoffgridId: playoffgridId,
        type: 'round',
        matches: []
    }
    for (let j=0; j < matchesCount; j++) {
        const newmatch = {
            home: null,
            away: null,
            homeId: null,
            awayId: null,
        }
        md.matches.push(newmatch)
    }

    return md
}

const reverseRoundMds = (md) => {
    return md.map(m => {
        return {
            away: m.home,
            awayId: m.homeId,
            home: m.away,
            homeId: m.awayId,
        }
    })
}

export default PlayoffCalendar;
