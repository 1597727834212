import React, { useState } from "react";

import moment from "moment";

import { pluralForm } from "../../../../utils";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { deleteByUrl, putByUrl } from "../../../../v3MethodsService";
import { confirmDialog } from "primereact/confirmdialog";
import icon1 from "../img/player.png";
import icon2 from "../img/icon1.png";

import "./style.scss";

const Salary = ({ d, idx, role, onSuccessfulPost, onError, handleItem }) => {
    const [showAll, setShowAll] = useState(false);
    console.log(d)
    const [year, month, day] = d.createDate.split("-").map(Number);

    const dateObject = new Date(2000 + year, month - 1, day);

    const handleReject = async () => {
        const response = await deleteByUrl(`salaries/${d._id}`);
        response?.success ? onSuccessfulPost("Запись о выплатах удалена") : onError(response?.message);
    };

    const deleteModal = (e) => {
        e.stopPropagation();
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация по этому начислению",
            header: "Удалить запись о выплатах?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: handleReject,
        });
    };

    const icon = d.type === "rent" ? icon2 : icon1;

    return (
        <div className="salary" key={idx} onClick={() => handleItem(d)}>
            <Button icon="pi pi-trash" className="salary__delete" onClick={deleteModal} />
            <div className="salary__header">
                <div className="salary__bill">{d.amount.toLocaleString("ru-RU") + " ₽" || ""}</div>
                <div className="salary__date">{moment(dateObject).format("DD.MM")}</div>
            </div>
            <div className="salary__title">
                Ставка {d.rate} ₽, {d.count} {type[d.typeRate].label + pluralForm(d.count, type[d.typeRate].opt)}
            </div>
            <div className="notices-modal__notice-text">
                {showAll || d.description.length < 200 ? decodeURIComponent(d.description) || "" : decodeURIComponent(d.description).slice(0, 200) + "..."}
            </div>
            {!showAll && d.description.length >= 200 && (
                <div className="notices-modal__notice-showall" onClick={() => setShowAll(true)}>
                    Показать еще
                </div>
            )}
            <div className="salary__container">
                {d.subjects && d.subjects.length > 1 ? (
                    <div className="salary__teams">
                        <Tooltip className="salary__tooltip" target={`.salary__imgs-${idx}`} position="top" autoHide={false}>
                            {d.subjects.map((player) => {
                                return (
                                    <div key={player._id} className="salary__team">
                                        <img src={player.avatarUrl || player.photo || icon} />
                                        <span>{player.name}</span>
                                    </div>
                                );
                            })}
                        </Tooltip>
                        <div className={`salary__imgs salary__imgs-${idx}`}>
                            <img src={d?.subjects[0].avatarUrl || d?.subjects[0].photo || icon} />
                            <img src={d?.subjects[1].avatarUrl || d?.subjects[1].photo || icon} />
                            {d.subjects[2] && <img src={d.subjects[2].avatarUrl || d?.subjects[2].photo || icon} />}
                        </div>
                        {d.type === "rent" ? (
                            <span>{d.subjects.length + " аренд" + pluralForm(d.subjects.length, ["а", "ы", ""]) + " площад" + pluralForm(d.subjects.length, ["ка", "ки", "ок"])}</span>
                        ) : (
                            <span>{d.subjects.length + " " + roles[d?.role] + pluralForm(d.subjects.length, ["", "а", "ов"])}</span>
                        )}
                    </div>
                ) : (
                    <div className="salary__team">
                        <img src={d.subjects[0].avatarUrl || d?.subjects[0].photo || icon} />
                        <span className="salary__team-only">{d.type === "rent" ? d.subjects[0].name : roles[d?.role] + ", " + d.subjects[0].name}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Salary;

const roles = {
    referee: "арбитр",
    executive: "делегат",
    medicine: "медик",
    photo: "фотограф",
    video: "оператор",
};

const type = {
    matches: { label: "матч", opt: ["", "а", "ей"] },
    hours: { label: "час", opt: ["", "а", "ов"] },
};
