import React, { useContext, useEffect, useRef, useState } from "react";

import moment from "moment";
import { useClickOutside, diffData } from "../../../../../../utils";
import {getByUrl, postByUrl, putByUrl} from "../../../../../../v3MethodsService";

import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {ProgressSpinner} from "primereact/progressspinner";

import "./style.scss";

const Sidebar = ({ state, onClose, toast, onDownload }) => {
    const ref = useRef();

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})
    const dataToSend = diffData(state, data);

    useEffect(() => {
        setData(state)
    }, [state]);

    useClickOutside(state, ref, onClose);

    const handleSave = () => {
        if (!data._id) {
            const body = {
                ...data,
                dateStart: moment(data.dateStart).format('YY-MM-DD'),
                dateEnd: moment(data.dateEnd).format('YY-MM-DD'),
            }
            postByUrl('betseasons', body)
                .then(resp => {
                    setLoader(true)
                    setTimeout(() => {
                        if (resp && resp.success) {
                            onClose()
                        } else {
                            toast.show({ severity: 'error', detail: resp?.message || resp?.msg || 'Произошла ошибка' })
                        }
                        setLoader(false)
                    }, 3000)
                })
                .catch(err => {
                    console.log(err)
                    toast.show({ severity: 'error', detail: err?.message || err?.msg || 'Произошла ошибка' });
                });
        } else {
            const body = {
                ...dataToSend,
                dateStart: dataToSend.dateStart ? moment(dataToSend.dateStart).format('YY-MM-DD') : data.dateStart,
                dateEnd: dataToSend.dateEnd ? moment(dataToSend.dateEnd).format('YY-MM-DD') : data.dateEnd,
            }
            putByUrl(`betseasons/${data._id}`, body)
                .then(resp => {
                    setLoader(true)
                    setTimeout(() => {
                        if (resp && resp.success) {
                            onClose()
                        } else {
                            toast.show({ severity: 'error', detail: resp?.message || resp?.msg || 'Произошла ошибка' })
                        }
                        setLoader(false)
                    }, 3000)
                })
                .catch(err => {
                    console.log(err)
                    toast.show({ severity: 'error', detail: err?.message || err?.msg || 'Произошла ошибка' });
                });
        }
    }

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData({ ...data, [key]: { ...data[key], [subKey]: val } });
        } else if (key) {
            setData({ ...data, [key]: val });
        } else {
            setData(val)
        }
    };

    const finishClick = () => {
        putByUrl(`betseasons/${data._id}`, {dateEnd: moment().subtract(1, 'days').format('YY-MM-DD')})
            .then(resp => {
                if (resp && resp.success) {
                    onClose()
                } else {
                    toast.show({ severity: 'error', detail: resp?.message || resp?.msg || 'Произошла ошибка' })
                }
            })
            .catch(err => {
                console.log(err)
                toast.show({ severity: 'error', detail: err?.message || err?.msg || 'Произошла ошибка' });
            });
    }

    return !!data && !!state && (
        <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
            <div className="side-profile p-component p-sidebar-enter-done" ref={ref}>
                <div className="side-profile__header">
                    <div className="side-profile__btns">
                        <Button label="Сохранить" className="side-profile__button-save auth2__button" onClick={handleSave} disabled={!data.title || !data.dateStart || !data.dateEnd || !data.balance || !dataToSend || loader}/>
                        {!!data._id && !!data.active && <Button label="Завершить" className="side-profile__button-save side-profile__button-delete auth2__button auth2__button_outlined" onClick={finishClick}/>}
                        {!!data._id && <Button label="Выгрузка результатов" className="side-profile__button-save auth2__button auth2__button_outlined" onClick={e => onDownload(e, data)}/>}
                    </div>
                    <i className="side-profile__icon-close pi pi-times" onClick={onClose}/>
                </div>

                {loader ? (
                    <div className="side-profile__body">
                        <div className='sidebar-fantasy__loader'>
                            <ProgressSpinner style={{ width: '70px', height: '70px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className='loader' />
                            <span className="side-profile__title">Идет проверка данных. Не закрывайте страницу.</span>
                        </div>
                    </div>
                ) : (
                    <div className="side-profile__body">
                        <span className="side-profile__title">{data._id ? "Редактировать сезон" : "Создать сезон"}</span>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Название*
                            </label>
                            <InputText placeholder="Укажите название" value={data.title || ""} onChange={(e) => updateData(e.target.value, "title")}/>
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Количество стартовых баллов*
                            </label>
                            <InputNumber placeholder="Укажите количество стартовых баллов" value={data.balance || ""} onChange={(e) => updateData(e.value, "balance")} min={0}/>
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Дата начала*
                            </label>
                            <Calendar
                                id='dateStart'
                                placeholder="Укажите дату начала"
                                value={data.dateStart ? moment(data.dateStart, 'YY-MM-DD').toDate() : ""}
                                onChange={(e) => updateData(e.target.value, "dateStart")}
                                dateFormat="dd.mm.yy"
                                appendTo='self'
                                mask="99.99.9999"
                                locale="ru"
                            />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Дата окончания*
                            </label>
                            <Calendar
                                id='dateEnd'
                                placeholder="Укажите дату окончания"
                                value={data.dateEnd ? moment(data.dateEnd, 'YY-MM-DD').toDate() : ""}
                                onChange={(e) => updateData(e.target.value, "dateEnd")}
                                dateFormat="dd.mm.yy"
                                appendTo='self'
                                mask="99.99.9999"
                                locale="ru"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Sidebar;