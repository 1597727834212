import React, { useEffect, useRef, useState } from "react";

import { pluralForm, useDebounce } from "../../../utils";
import { getByUrl, postByUrl } from "../../../v3MethodsService";
import moment from "moment";
import { transliterate } from "transliteration";
import axios from "axios";
import {ENDPOINT} from "../../../env";

import Header from "../Common/Header";
import DataTable from "../Common/DataTable";
import PlayersAndStaffSidebar from "./Sidebar";

import { Dialog } from "primereact/dialog";
import { SelectButton } from "primereact/selectbutton";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { FileUpload } from "primereact/fileupload";

import noIcon from "./img/noIcon.png";
import table from "./img/table.png";
import times from "./img/times.svg"

import "./style.scss";

const PlayersAndStaff = () => {
    const toast = useRef(null);
    const opRef = useRef(null);
    const fileUploadRef = useRef(null);

    const [players, setPlayers] = useState([]);
    const [staff, setStaff] = useState(null);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchString, setSearchString] = useState("");
    const debouncedSearchTerm = useDebounce(searchString, 700);

    const [tab, setTab] = useState("players");
    const [sidebarState, setSidebarState] = useState(null);

    const [currentPage, setCurrentPage] = useState(0)
    const [duplicates, setDuplicates] = useState({});
    const cancelTokenRef = useRef(null);

    const playersOnPage = players && players.length > 0 ? players.slice(currentPage * 8, (currentPage + 1) * 8) : [];

    useEffect(() => {
        if (tab === "players") {
            if (players && players.length > 0) {
                setData(players);
            } else if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
                setData([]);
            } else {
                setLoading(true);
                getByUrl("players?mode=full").then((resp) => {
                    if (resp && resp.success) {
                        setPlayers(resp.data);
                    }
                    setLoading(false);
                });
            }
        } else if (staff) {
            setData(staff[tab] || []);
        } else {
            setLoading(true);
            getByUrl("employees").then((resp) => {
                if (resp && resp.success) {
                    setStaff(resp.data);
                }
                setLoading(false);
            });
        }
    }, [tab, players, staff]);

    useEffect(() => {
        if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
            getDataBySearchString(debouncedSearchTerm);
        } else {
            setPlayers([]);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        fetchDuplicates()
    }, [currentPage, players]);

    const fetchDuplicates = async () => {
        if (cancelTokenRef.current) {
            cancelTokenRef.current.cancel("Request canceled due to page change.");
        }

        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenRef.current = cancelTokenSource;

        try {
            const results = await Promise.all(
                playersOnPage.map((player) =>
                    axios
                        .get(
                            `${ENDPOINT}v2/getCandidatesForPlayer/${player._id}?byFederation=true`,
                            {
                                cancelToken: cancelTokenSource.token,
                                headers: {
                                    authorization: localStorage.getItem('_amateum_auth_tkn')
                                }
                            }
                        )
                        .then((response) => ({
                            playerId: player._id,
                            candidates: response.data.candidates,
                        }))
                        .catch((error) => {
                            if (axios.isCancel(error)) {
                                console.log(`Request for player ${player._id} canceled.`);
                            } else {
                                console.error(`Error fetching duplicates for ${player._id}`, error);
                            }
                            return { playerId: player._id, candidates: [] };
                        })
                )
            );

            const newDuplicates = results.reduce((acc, result) => {
                acc[result.playerId] = result.candidates;
                return acc;
            }, {});

            setDuplicates(newDuplicates);
        } catch (error) {
            console.error("Error fetching duplicates", error);
        }
    };

    const getUpdatedData = () => {
        setLoading(true);
        if (tab === "players") {
            getByUrl("players?mode=full").then((resp) => {
                if (resp && resp.success) {
                    setPlayers(resp.data);
                }
                setLoading(false);
            });
        } else {
            getByUrl("employees").then((resp) => {
                if (resp && resp.success) {
                    setStaff(resp.data);
                }
                setLoading(false);
            });
        }
    };

    const getDataBySearchString = (string) => {
        const regexp = /\D\D+/;
        if (string.match(regexp) !== null) {
            // setLoading(true)
            getByUrl(`players?query=${encodeURIComponent(string)}&mode=full`).then((resp) => {
                // setLoading(false)
                setPlayers(resp.data || []);
            });
        }
    };

    const newItemClick = (key, e) => {
        // if (tab === 'players') {
        //     setSidebarState({...defaultPlayer})
        //     return
        // }
        setTab(key);
        setSidebarState({ ...defaultEmployee, roles: [key] });
        opRef.current.toggle(e);
    };

    const renderHeader = () => {
        return (
            <div className="header__top">
                <span className="header__title">Игроки и сотрудники</span>
                <div className="players-and-staff__btns">
                    <Button label="Загрузить список игроков" className="p-button-outlined" icon="pi pi-upload" onClick={() => setVisible(true)} />
                    <Button label="Добавить сотрудника" icon="pi pi-plus" onClick={(e) => opRef.current.toggle(e)} />
                </div>

                <OverlayPanel ref={opRef} className="players-and-staff__overlay">
                    {opOptions.map((o, k) => (
                        <div key={k} onClick={(e) => newItemClick(o.value, e)} className="players-and-staff__ovitem">
                            {o.label}
                        </div>
                    ))}
                </OverlayPanel>
            </div>
        );
    };

    const tableColumns = () => {
        switch (tab) {
            case "players": {
                return [
                    { field: "_name", header: "ФИО" },
                    { field: "_birthday", header: "Дата рождения", className: "secondary" },
                    { field: "_active", header: "Активные заявки", className: "secondary" },
                    { field: "_disqualification", header: "Дисквалификации", className: "secondary" },
                    { field: "_doubles", header: "Возможные дубли", className: "secondary" },
                ];
            }
            case "referee": {
                return [
                    { field: "_name", header: "ФИО" },
                    { field: "_phone", header: "Телефон", className: "secondary" },
                    // { field: "_week", header: "Назначения на неделю", className: "secondary" },
                    { field: "_category", header: "Категория", className: "secondary" },
                ];
            }
            default: {
                return [
                    { field: "_name", header: "ФИО" },
                    { field: "_phone", header: "Телефон", className: "secondary" },
                    // { field: "", header: "Назначения на неделю", className: "secondary" },
                ];
            }
        }
    };

    const tableRows = (tab) => {
        switch (tab) {
            case "players": {
                return data
                    ? data.map((row) => ({
                        ...row,
                        _name: {
                            value: `${row?.surname || ""} ${row?.name || ""} ${row?.middlename || ""}`,
                            custom: customUser({
                                avatarUrl: row?.avatarUrl,
                                name: `${row?.surname || ""} ${row?.name || ""} ${row?.middlename || ""}`,
                                isVerified: `${row?.isVerified}`
                            }),
                        },
                        _birthday: { value: row?.birthday },
                        _active: {
                            value: row?.squads?.actived?.length,
                            custom: generateTag(row?.squads?.actived?.length, "заяв", ["ка", "ки", "ок"]),
                        },
                        _disqualification: {
                            value: row?.disqualifications?.length,
                            custom: row?.globalDisqTill
                                ? generateTag(false, "Глобальная дисквалификация", [], { background: "#FFF5F5", color: "#FF3D32" }, true)
                                : generateTag(row?.disqualifications?.length, "дисквалификаци", ["я", "и", "й"], { background: "#FFF8F3", color: "#F97316" }),
                        },
                        _doubles: {
                            value: duplicates[row._id]?.length || 0,
                            custom: customDoubles(duplicates[row._id])
                        }
                      }))
                    : [];
            }
            case "referee": {
                return data
                    ? data.map((row) => ({
                        ...row,
                        _name: {
                            value: row?.name,
                            custom: customUser(row),
                        },
                        _phone: {
                            value: row?.phone,
                            custom: customPhone(row?.phone)
                        },
                        _category: {
                            value: row?.category?.name,
                            custom: generateTag(false, row?.category?.name, false, {background: "#F5F9FF", color: "#6466E9"}, true)
                        }
                      }))
                    : [];
            }
            default: {
                return data
                    ? data.map((row) => ({
                        ...row,
                        _name: { value: row?.name, custom: customUser(row) },
                        _phone: { value: row?.phone}
                      }))
                    : [];
            }
        }
    };

    const customDoubles = (double) => {
        if (double) {
            return generateTag(double.length, 'профил', ['ь', "я", "ей"], { background: "#FFF8F3", color: "#F97316" })
        }
        return <i className="pi pi-spin pi-spinner"/>
    }

    const customPhone = (phone) => {
        return (
            <div className="players-and-staff__phone">
                {phone}
            </div>
        )
    }

    const generateTag = (count, word, wordForms, styles = {}, global = false, icon = null) => {
        if (count > 0 || global) {
            return (
                <div className="players-and-staff__tag" style={styles}>
                    {icon}
                    {!global && count} {word}
                    {pluralForm(count, wordForms)}
                </div>
            );
        } else {
            return <span className="players-and-staff__no">Нет</span>;
        }
    };

    const renderVerifiedTag = (user, tab) => {
        // если не верифицирован или таб отличен от игроков
        if (user?.isVerified === "true" || tab !== 'players') return null;

        return generateTag(
            false,
            "Не верифицирован",
            false,
            { background: "#FFF5F5", color: "#FF3D32", width: "max-content" },
            true,
            <img className="players-and-staff__user-icon" src={times} alt="Not verified" />
        )
    }

    const customUser = (user) => {
        return (
            <div className="players-and-staff__user">
                <img
                        src={user?.avatarUrl || noIcon}
                        alt="User avatar"
                        className="players-and-staff__user-avatar"
                />
                <div className="players-and-staff__user-info">
                    <div className="players-and-staff__user-name">{user?.name}</div>
                    {renderVerifiedTag(user, tab)}
                </div>
            </div>
        );
    };

    const onClickTable = (row) => {
        setSidebarState(row?.data);
    };

    const renderDialogContent = () => {
        return (
            <div className="players-dialog__content">
                <div className="players-dialog__req">
                    <span className="players-dialog__req-title">Требования</span>
                    <span>Формат: XLSM, XLSX, XLTM, XLTX</span>
                    <div>
                        <span>Два стобца: </span>
                        <ul>
                            <li>A - ФИО, </li>
                            <li>В - дата рождения, в формате dd.mm.yyyy</li>
                        </ul>
                    </div>
                </div>
                <div className="players-dialog__req">
                    <span className="players-dialog__req-title">Пример</span>
                    <img src={table} />
                </div>
            </div>
        );
    };

    const uploadAttachment = (event) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("file", renamedFile);

        postByUrl("upload_players", formData, true).then((resp) => {
            if (resp && resp.success) {
                toast.current.show({ severity: "success", life: 3000, summary: `Данные успешно отправлены` });
                setVisible(false);
                getUpdatedData();
            } else {
                toast.current.show({ severity: "warn", life: 4000, summary: `Ошибка загрузки файла` });
            }
        });

        fileUploadRef.current.clear();
    };

    return (
        <div className="players-and-staff">
            <Toast ref={toast} position="top-right" />
            <Dialog
                className="players-dialog"
                header="Загрузите список игроков"
                footer={() => (
                    <FileUpload
                        ref={fileUploadRef}
                        mode={"basic"}
                        accept=".xlsm, .xlsx, .xltm, .xltx"
                        onSelect={uploadAttachment}
                        customUpload
                        chooseOptions={{ label: "Выбрать файл", icon: "pi pi-upload" }}
                        className="players-and-staff__input"
                    />
                )}
                visible={visible}
                modal
                onHide={() => setVisible(false)}
                draggable={false}
            >
                {renderDialogContent()}
            </Dialog>
            <PlayersAndStaffSidebar state={sidebarState} onClose={() => setSidebarState(null)} tab={tab} toast={toast.current} getUpdatedData={getUpdatedData} />
            <Tooltip target=".pi-info-circle" content="Игрока можно создать при выборе игроков в заявку, через раздел «Заявки на турнир»" position="top" className="players-tooltip" />
            <Header items={tabs} state={data} setTab={setTab} headerTop={renderHeader} tabMenu activeIdx={tabs.findIndex((t) => t.type === tab)} />
            {loading ? (
                <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
            ) : (
                <DataTable
                    perPage={8}
                    columns={tableColumns(tab)}
                    rows={tableRows(tab)}
                    paginator={data.length > 8}
                    noCreateBtn
                    search={tab !== "players"}
                    customSearch={tab === "players"}
                    customSearchValue={searchString}
                    onCustomSearch={setSearchString}
                    onClick={onClickTable}
                    onPageChange={e => setCurrentPage(e.page)}
                />
            )}
        </div>
    );
};

const defaultEmployee = {
    name: "",
    phone: "",
    avatarUrl: "",
};

const defaultPlayer = {
    surname: "",
    name: "",
    middlename: "",
};

const tabs = [
    { label: "Игроки", type: "players", icon: "pi pi-info-circle" },
    { label: "Арбитры", type: "referee" },
    { label: "Делегаты", type: "executive" },
    { label: "Медики", type: "medicine" },
    { label: "Фотографы", type: "photo" },
    { label: "Операторы", type: "video" },
];

const opOptions = [
    { label: "Арбитра", value: "referee" },
    { label: "Делегата", value: "executive" },
    { label: "Медика", value: "medicine" },
    { label: "Фотографа", value: "photo" },
    { label: "Оператора", value: "video" },
];

export default PlayersAndStaff;
