import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Sidebar } from "primereact/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

import CustomScrollbars from "react-custom-scrollbars-2";

import SideNotes from "../SideNotes";
import CreateFlow from "./CreateFlow";
import Attachment from "./Attachment";
import Form from "./Form";

import "./style.scss";

import axios from "axios";
import { ENDPOINT } from "../../env";
import { GlobalContext } from "../../ctx";

import moment from "moment";
import { Menu } from "primereact/menu";

import striptags from "striptags";
import DataTable from "../v2/Common/DataTable";
import { SelectButton } from "primereact/selectbutton";

import DropdownUi from "../../UI/DropdownUi";
import InputUi from "../../UI/InputUi";
import { Dropdown } from "primereact/dropdown";
import ImportVk from "./CreateFlow/ImportVk";

const categories = {
    all: "Все категории",
    news: "Новости",
    broadcast: "Трансляции",
    interview: "Интервью",
    highlights: "Обзоры",
    previews: "Анонсы",
    photos: "Фото",
};

const categoriesOptions = [
    { name: "Все категории", value: "all" },
    { name: "Новости", value: "news" },
    { name: "Трансляции", value: "broadcast" },
    { name: "Интервью", value: "interview" },
    { name: "Обзоры", value: "highlights" },
    { name: "Анонсы", value: "previews" },
    { name: "Фото", value: "photos" },
];

const options = ["Опубликовано", "Не опубликовано"];

const Publications = ({ profile }) => {
    const [global, setGlobal] = useContext(GlobalContext);
    const tkn = global && global.auth && global.auth.token ? global.auth.token : "";
    const [select, setSelect] = useState(options[0]);
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [removeDialog, setRemoveDialog] = useState(null);
    const [item, setItem] = useState(null);

    const [authorDropdown, setAuthorDropdown] = useState(false);
    const [categoriesDropdown, setCategoriesDropdown] = useState(categoriesOptions[0].value);
    const [visible, setVisible] = useState(false);
    const toastRef = useRef(null);

    const [activePub, setActivePub] = useState("");
    const menu = useRef(null);

    const { id } = useParams();
    const history = useHistory();

    const items = [
        {
            label: "Удалить публикацию",
            command: (e) => {
                setRemoveDialog(activePub);
            },
        },
    ];

    useEffect(() => {
        if (tkn) loadList();
    }, [tkn]);

    const loadList = async () => {
        setLoading(true);
        if (tkn) {
            await axios
                .get(`${ENDPOINT}v2/list/publications?scopeType=federation`, {
                    headers: {
                        // Authorization: localStorage.getItem('_amateum_auth_tkn')
                        Authorization: tkn,
                    },
                })
                .then((resp) => {
                    setList(resp.data);
                    setLoading(false);
                })
                .catch((err) => console.log(err));
        }
    };

    const removeItem = (pub) => {
        setLoading(true);
        //console.log('removeItem', pub);
        axios
            .delete(`${ENDPOINT}v2/publications/${pub._id}`, {
                headers: {
                    // Authorization: localStorage.getItem('_amateum_auth_tkn')
                    Authorization: tkn,
                },
            })
            .then((resp) => {
                if (resp && resp.data && resp.data.success) {
                    if (toastRef) {
                        toastRef.current.show({ severity: "success", summary: "Успешно!", detail: "Публикация удалена" });
                    }
                    history.push(`/publications`);
                    loadList();
                    setRemoveDialog(null);
                    setLoading(false);
                } else {
                    if (toastRef) {
                        toastRef.current.show({ severity: "error", summary: "Невозможно удалить", detail: "Ошибка сервера. Пожалуйста, сообщите в поддержку" });
                    }
                }
            });
        setLoading(false);
    };

    const handleSave = () => {
        toastRef.current.show({ severity: "success", summary: "Успешно!", detail: "Публикация сохранена" });
        loadList();
        history.push(`/publications`);
    };

    const authorOptions = list?.length
        ? list.reduce(
              (acc, item) => {
                  if (item.author && !acc.some((option) => option.value === item.author._id)) {
                      acc.push({ value: item.author._id, name: item.author.name });
                  }
                  return acc;
              },
              [{ value: "all", name: "Все авторы" }]
          )
        : [{ value: "all", name: "Все авторы" }];

    const filteredRows = list
        ?.filter((item) => (select === options[0] ? item.published : !item.published))
        ?.filter((item) => categoriesDropdown === "all" || item.category === categoriesDropdown)
        ?.filter((item) => !authorDropdown || authorDropdown === "all" || item?.author?._id === authorDropdown);


    const handleVk = (data) => {
        setVisible(false);
        setItem(data);
        history.push("/publications/vk");
    };


    return (
        list && (
            <>
                <Toast ref={toastRef} />
                {id ? (
                    id === "create" ? (
                        <CreateFlow profile={profile} onSaved={() => loadList()} />
                    ) : (
                        <Form
                            external={item}
                            socialId={item?.socialEntryId}
                            data={list ? list.find((l) => l._id === id) || null : null}
                            profile={profile}
                            onSaved={handleSave}
                            setRemoveDialog={setRemoveDialog}
                            removeItem={removeItem}
                        />
                    )
                ) : (
                    <div className="publications">
                        <Dialog
                            visible={visible}
                            draggable={false}
                            className="media-bank__dialog"
                            modal
                            appendTo={"self"}
                            header={"Выберите публикацию"}
                            onHide={() => {
                                if (!visible) return;
                                setVisible(false);
                            }}
                        >
                            <ImportVk handleVk={handleVk} />
                        </Dialog>
                        <div className="publications__header">
                            <div className="publications__header-item">
                                <Button
                                    label="Создать публикацию"
                                    onClick={() => {
                                        history.push("/publications/create");
                                    }}
                                />
                                <Button
                                    label="Выбрать из ВК"
                                    className="p-button-outlined"
                                    onClick={() => {
                                        setVisible(true);
                                    }}
                                />
                            </div>
                            <div className="publications__header-item">
                                <SelectButton value={select} options={options} onChange={(e) => e.value && setSelect(e.value)} />
                                <Dropdown
                                    value={categoriesDropdown}
                                    onChange={(e) => setCategoriesDropdown(e.target.value)}
                                    placeholder="Выберите категорию"
                                    emptyFilterMessage="Ничего не найдено"
                                    emptyMessage="Ничего не найдено"
                                    appendTo="self"
                                    optionLabel="name"
                                    optionValue="value"
                                    options={categoriesOptions}
                                />
                                <Dropdown
                                    value={authorDropdown ? authorDropdown : authorOptions[0].value}
                                    onChange={(e) => setAuthorDropdown(e.target.value)}
                                    options={authorOptions}
                                    placeholder="Выберите автора"
                                    emptyFilterMessage="Ничего не найдено"
                                    emptyMessage="Ничего не найдено"
                                    appendTo="self"
                                    optionLabel="name"
                                    optionValue="value"
                                />
                            </div>
                        </div>

                        <DataTable
                            emptyMessage="Публикации не найдены"
                            search
                            noCreateBtn
                            onClick={(e) => history.push(`/publications/${e.data._id}`)}
                            columns={[
                                { field: "photo", header: "Фото", className: "secondary" },
                                { field: "date", header: "Дата публикации", sortable: true },
                                { field: "category", header: "Категория", sortable: true, className: "secondary" },
                                { field: "author", header: "Автор", sortable: true, className: "secondary" },
                            ]}
                            rows={filteredRows?.map((row) => ({
                                ...row,
                                photo: {
                                    value: row?.attachments[0] ? (
                                        <Attachment data={row?.attachments[0]} />
                                    ) : (
                                        <div className="publications__empty">
                                            <i className="pi pi-image" />
                                        </div>
                                    ),
                                },
                                date: {
                                    custom: (
                                        <div className="publications__date">
                                            {moment(row.date, "YY-MM-DD").format("D MMMM YYYY")}
                                            <span>{row.title}</span>
                                        </div>
                                    ),
                                    value: row.date,
                                },
                                category: {
                                    value: categories[row?.category],
                                },
                                author: { value: row?.author?.name },
                            }))}
                            paginator={list.length > 5}
                            perPage={5}
                        />
                        {/* <div className="publications__vk">
                            <span className="publications__vk-title">Публикации из группы ВК</span>
                            <InputUi label="Ссылка на группу Вконтакте*" placeholder="Вставьте ссылку" />
                        </div> */}
                    </div>
                )}
            </>
        )
    );
};

export default Publications;
