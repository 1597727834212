import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { transliterate } from "transliteration";
import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../../../v3MethodsService";
import {diffData, generateRandom36String, publicShareLink} from "../../../../../utils";

import FileUploadUi from "../FileUploadUi";

import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import {InputSwitch} from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

import "./style.scss";

const Profile = ({ state, updateState, toast, setState }) => {
    const [data, seteData] = useState(state);
    const [isChanged, setIsChanged] = useState(false);

    const [optionsTournaments, setOptionsTornaments] = useState([]);
    const dataToSend = isChanged ? { ...diffData(state, data), share: data.share } : diffData(state, data);
    const history = useHistory();

    useEffect(() => {
        getByUrl(`tournaments?leagueId=${data.leagueId}&active=false`).then((resp) => {
            if (resp && resp.success) {
                setOptionsTornaments(resp.data);
            }
        });
    }, []);

    const deleteHandler = () => {
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация по этому турниру",
            header: "Удалить турнир?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: () => {
                handleDelete();
            },
            reject: () => {},
        });
    };

    const updateData = (val, key, subKey, arr = false) => {
        if (subKey) {
            const newSubkey = arr ? (data[key]?.[subKey] ? [...data[key]?.[subKey], val] : [val]) : val;
            seteData({ ...data, [key]: { ...data[key], [subKey]: newSubkey } });
        } else {
            seteData({ ...data, [key]: arr ? [...data[key], val] : val });
        }
    };

    const removeFileHandler = (item) => {
        let newData = { ...data };
        if (item) {
            newData.share.promo = newData.share.promo.filter((f) => f !== item);
        }
        setIsChanged(true);

        seteData(newData);
    };

    const onSelect = async (event, key, subKey = false, arr = false) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("s3only", true);
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.path;
                updateData(file, key, subKey, arr);
            }
        });
    };

    const handleSave = async () => {
        await putByUrl(`tournaments/${state._id}`, dataToSend).then((resp) => {
            if (resp && resp.success) {
                setState(data);
                toast.show({ severity: "success", summary: "Данные сохранены" });
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
            setIsChanged(false);
        });
    };

    const handleDelete = async () => {
        await deleteByUrl(`tournaments/${state._id}`).then((resp) => {
            if (resp && resp.success) {
                history.replace("/tournaments");
                toast.show({ severity: "success", summary: "Турнир удален" });
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const showBtn = data && data.name && (dataToSend || isChanged);

    const switchShareLink = (value) => {
        updateData(value ? generateRandom36String() : null, 'shareLinkString')
    }

    return (
        data && (
            <div className="clubs-profile">
                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Основная информация</span>
                    </div>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Эмблема турнира <span className="clubs-profile__label muted">(jpg, jpeg, png, до 5 Мб)</span>
                            </label>
                            <FileUploadUi image={data.emblem} onChange={(e) => onSelect(e, "emblem")} updateData={updateData} remove={() => updateData(null, "emblem")} />
                        </div>
                        <div className="clubs-profile__main">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Название*</label>
                                <InputTextarea placeholder="Укажите название" value={data.name || ""} onChange={(e) => updateData(e.target.value, "name")} />
                            </div>
                            <div className="clubs-profile__inputs">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Сокращенное название</label>
                                    <InputText placeholder="Укажите сокращенное название" value={data.shortName || ""} onChange={(e) => updateData(e.target.value, "shortName")} />
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Формат (дисциплина)</label>
                                    <InputText placeholder="7х7" disabled value={data?.league?.discipline?.name || ""}/>
                                </div>
                            </div>

                            <div className="clubs-profile__inputs">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Сезон</label>
                                    <InputText placeholder="Выберите сезон" disabled value={data.season?.name || ""}/>
                                </div>
                                <div className="clubs-profile__container tournament">
                                    <label className="clubs-profile__label">Предшествующий турнир</label>
                                    <Dropdown
                                        placeholder="Выберите предшествующий турнир"
                                        emptyFilterMessage={"Ничего не найдено"}
                                        emptyMessage={"Ничего не найдено"}
                                        value={data.parentId || ""}
                                        onChange={(e) => updateData(e.value, "parentId")}
                                        options={optionsTournaments}
                                        showFilterClear
                                        optionLabel="name"
                                        optionValue="_id"
                                        appendTo={"self"}
                                    />
                                </div>
                            </div>
                            <div className="clubs-profile__inputs">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Дата начала</label>
                                    <Calendar
                                        className="auth2__calendar"
                                        showIcon
                                        locale="ru"
                                        dateFormat="dd.mm.yy"
                                        maxDate={new Date()}
                                        mask="99.99.9999"
                                        appendTo={"self"}
                                        placeholder="Выберите дату"
                                        value={data?.dateStart ? moment(data?.dateStart, "YY.MM.DD").toDate() : "" || ""}
                                        onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("YY.MM.DD") : e.target.value, "dateStart")}
                                    />
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Дата завершения</label>
                                    <Calendar
                                        className="auth2__calendar"
                                        showIcon
                                        locale="ru"
                                        dateFormat="dd.mm.yy"
                                        mask="99.99.9999"
                                        appendTo={"self"}
                                        placeholder="Выберите дату"
                                        value={data?.dateEnd ? moment(data?.dateEnd, "YY.MM.DD").toDate() : "" || ""}
                                        onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("YY.MM.DD") : e.target.value, "dateEnd")}
                                    />
                                </div>
                            </div>

                            <div className='clubs-profile__container'>
                                <label className="clubs-profile__label">Доступ к странице только по ссылке</label>
                                <div className="clubs-profile__shareLink">
                                    <InputSwitch checked={!!data.shareLinkString} onChange={e => switchShareLink(e.value)}/>
                                    {!!data.shareLinkString && <Button label='Копировать ссылку' icon='pi pi-copy' onClick={() => navigator.clipboard.writeText(publicShareLink('tournament', data._id, data.shareLinkString))}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Цветовая схема для инфографики</span>
                    </div>
                    <div className="clubs-profile__content themes">
                        {themes.map((item, index) => {
                            return (
                                <img
                                    onClick={() => updateData(item.key, "share", "leagueTheme", false)}
                                    className={`${data?.share?.leagueTheme == item.key ? "active" : ""}`}
                                    key={index}
                                    src={item.image}
                                    alt=""
                                />
                            );
                        })}
                    </div>
                </div>

                <div className="clubs-profile__card" style={{ marginBottom: 48 }}>
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Логотипы партнеров</span>
                    </div>
                    <div className="clubs-profile__content logos">
                        {data?.share?.promo?.map((item, index) => {
                            return <FileUploadUi key={index} image={item} onChange={(e) => onSelect(e, "share", "promo", true)} updateData={updateData} remove={() => removeFileHandler(item)} />;
                        })}
                        <FileUploadUi onChange={(e) => onSelect(e, "share", "promo", true)} updateData={updateData} />
                    </div>
                </div>

                <div className="clubs-detail__button">
                    <Button label="Удалить" className="p-button-danger" onClick={deleteHandler} />
                    <Button label="Сохранить" onClick={handleSave} disabled={!showBtn} />
                </div>
            </div>
        )
    );
};

const themes = [
    {
        key: "samara_green",
        image: require("./Themes/samara_green.png"),
    },
    {
        key: "deep_blue",
        image: require("./Themes/deep_blue.png"),
    },
    {
        key: "indigo",
        image: require("./Themes/indigo.png"),
    },
    {
        key: "red_tver",
        image: require("./Themes/red_tver.png"),
    },
    {
        key: "orange",
        image: require("./Themes/orange.png"),
    },
];

export default Profile;
