import React, { useEffect, useState } from "react";

import moment from "moment/moment";
import { deleteByUrl, getByUrl } from "../../../../../v3MethodsService";
import service from "../../../../Staff/service";

import FileUploadUi from "../../../ClubsManagment/Detail/FileUploadUi";

import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import noTeam from "../../img/noTeam.png";

const Employee = ({ state, data, updateData, openedTabs, changeTabHandler, loading, updateLoading, sending, updateSending, tab, toast, getUpdatedData, convertBase64, onClose, outsideRef }) => {
    const [categoriesOptions, setCategoriesOptions] = useState([]);

    useEffect(() => {
        if (state) {
            if (state._id) {
                getEmployeeData().then((resp) => {
                    if (resp) {
                        updateData(resp);
                    }
                });
            } else {
                updateData(state);
            }
        } else {
            updateData(null);
        }
    }, [state]);

    useEffect(() => {
        getByUrl("categories").then((resp) => {
            if (resp && resp.success) {
                setCategoriesOptions(resp.data);
            }
        });
    }, []);

    const deleteHandler = () => {
        confirmDialog({
            message: "Профиль будет безвозвратно удален",
            header: "Удалить профиль?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: () => {
                onRemove();
            },
            reject: () => {},
        });
    };

    const getEmployeeData = async () => {
        updateLoading(true);
        const refereeData = await getByUrl(`employees/${state._id}`);
        if (tab === "referee") {
            const statsData = await getByUrl(`getRefereeStats/${state._id}`);
            updateLoading(false);
            if (statsData && refereeData) {
                return { stats: { ...statsData.data }, ...refereeData.data };
            }
        }
        updateLoading(false);
        if (refereeData) {
            return { ...refereeData.data };
        }
        return null;
    };

    const handleSave = async () => {
        updateSending(true);

        const body = {
            avatarUrl: data.avatarUrl,
            name: data.name,
            phone: data.phone,
            roles: data.roles,
            categoryId: data.categoryId,
            _id: data._id,
        };

        if (data.avatarUrl && data.avatarUrl.objectURL) {
            const decoded = await convertBase64(data.avatarUrl);

            const resp = await service.upload({ decoded: decoded, target: "employees", trim: true, toast: toast });
            if (resp && resp.uploaded) {
                body.avatarUrl = resp.uploaded;
                updateData(resp.uploaded, "avatarUrl");
            } else {
                updateSending(false);
                console.log("not uploaded photo dif", body);
                toast.current.show({ severity: "error", summary: "Ошибка сервера", detail: "Не удалось загрузить фото" });
                return null;
            }
        }
        await service.saveData(body, "employees", toast).then((resp) => {
            if (resp && resp.success) {
                getUpdatedData();
                onClose();
            }
        });

        updateSending(false);
    };

    const onRemove = () => {
        deleteByUrl(`employees/${data._id}`).then((resp) => {
            if (resp && resp.success) {
                getUpdatedData();
                onClose();
                toast.show({ severity: "success", summary: "Сотрудник удалён" });
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const disabledSave = data ? !data.avatarUrl || !data.name || !data.phone || !data.roles?.[0] : true;

    return (
        <div className="side-profile p-component p-sidebar-enter-done notices-modal__side" ref={outsideRef}>
            {!!loading && <ProgressSpinner style={{ width: "40px", height: "40px", marginTop: "50%" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />}
            {!!data && !loading && (
                <div className="side-profile__header">
                    <div className="side-profile__header-btn-group">
                        <Button className="side-profile__button-save auth2__button" label="Сохранить" onClick={handleSave} disabled={disabledSave} loading={sending} />
                        {!!data._id && <Button className="side-profile__button-save auth2__button auth2__button_outlined" label="Удалить" onClick={deleteHandler} loading={sending} />}
                    </div>
                    <i className="side-profile__icon-close pi pi-times" onClick={onClose} />
                </div>
            )}

            {!!data && !loading && (
                <div className="side-profile__body">
                    <div className={`side-profile__accordion${openedTabs.includes("info") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                        <div className="side-profile__accordion-header">
                            <div className="side-profile__accordion-title">Основная информация</div>
                            <i className={`${openedTabs.includes("info") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("info")} />
                        </div>
                        <div className="players-and-staff-sidebar__accordion-matches">
                            <div className="clubs-profile__container">
                                <FileUploadUi
                                    label="Фото на однотонном фоне*"
                                    mutedLabel="(jpg, jpeg, png, до 5 Мб)"
                                    image={data.avatarUrl?.objectURL || data.avatarUrl}
                                    onChange={(e) => updateData(e.files[0], "avatarUrl")}
                                    updateData={() => updateData(null, "avatarUrl")}
                                    className="players-and-staff-sidebar__fileupload"
                                />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">ФИО*</label>
                                <InputText placeholder="Укажите ФИО" value={data.name || ""} onChange={(e) => updateData(e.target.value, "name")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Номер телефона*</label>
                                <InputMask placeholder="+ 7 (___) ___ - __ - __" mask="+7 (999) 999 - 99 - 99" value={data.phone || ""} onChange={(e) => updateData(e.target.value, "phone")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Тип сотрудника*</label>
                                <Dropdown
                                    emptyMessage="Ничего не найдено"
                                    placeholder="Выберите из списка"
                                    value={data.roles?.[0] || ""}
                                    onChange={(e) => updateData([e.target.value], "roles")}
                                    options={rolesOptions}
                                    appendTo="self"
                                />
                            </div>
                            {data.roles?.[0] === "referee" && (
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Категория арбитра</label>
                                    <Dropdown
                                        emptyMessage="Ничего не найдено"
                                        placeholder="Выберите из списка"
                                        value={data.categoryId || ""}
                                        optionLabel="name"
                                        optionValue="_id"
                                        onChange={(e) => updateData(e.target.value, "categoryId")}
                                        options={categoriesOptions}
                                        appendTo="self"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {!!data.stats && (
                        <div className={`side-profile__accordion${openedTabs.includes("stats") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Статистика</div>
                                <i className={`${openedTabs.includes("stats") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("stats")} />
                            </div>
                            <div className="players-and-staff-sidebar__accordion-stats">
                                {statCards.map((s) => (
                                    <div
                                        key={s.key}
                                        className={`
                                            players-and-staff-sidebar__card 
                                            ${s.key === "matches" ? "players-and-staff-sidebar__card_first-stat" : ""}
                                            ${data.stats[s.key] ? "" : "players-and-staff-sidebar__card_empty"}
                                        `}
                                    >
                                        <div className="players-and-staff-sidebar__card-title">{s.title}</div>
                                        <span>{data.stats[s.key] || 0}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {data.recent?.length > 0 && (
                        <div className={`side-profile__accordion${openedTabs.includes("recent") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Ближайшие матчи</div>
                                <i className={`${openedTabs.includes("recent") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("recent")} />
                            </div>
                            <div className="players-and-staff-sidebar__accordion-matches">
                                {data.recent.map((match) => (
                                    <div
                                        key={match._id}
                                        className={`
                                            players-and-staff-sidebar__card
                                            players-and-staff-sidebar__card_match
                                        `}
                                    >
                                        <div className="players-and-staff-sidebar__card-title">
                                            {moment(match.date, "YY-MM-DD").format("DD.MM.YYYY")}, {match.time}
                                        </div>
                                        <div className="players-and-staff-sidebar__card-teams">
                                            <div className="players-and-staff-sidebar__card-home">
                                                {match.home?.name}
                                                <img src={match.home?.club?.emblem || noTeam} />
                                            </div>
                                            <div className="players-and-staff-sidebar__card-score">
                                                -
                                            </div>
                                            <div className="players-and-staff-sidebar__card-away">
                                                <img src={match.away?.club?.emblem || noTeam} />
                                                {match.away?.name}
                                            </div>
                                        </div>
                                        <div className="players-and-staff-sidebar__card-title">
                                            {match.stage?.title}, {match.matchday?.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {data.finished?.length > 0 && (
                        <div className={`side-profile__accordion${openedTabs.includes("matches") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Последние матчи</div>
                                <i className={`${openedTabs.includes("matches") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("matches")} />
                            </div>
                            <div className="players-and-staff-sidebar__accordion-matches">
                                {data.finished.map((match) => (
                                    <div
                                        key={match._id}
                                        className={`
                                            players-and-staff-sidebar__card
                                            players-and-staff-sidebar__card_match
                                        `}
                                    >
                                        <div className="players-and-staff-sidebar__card-title">
                                            {moment(match.date, "YY-MM-DD").format("DD.MM.YYYY")}, {match.time}
                                        </div>
                                        <div className="players-and-staff-sidebar__card-teams">
                                            <div className="players-and-staff-sidebar__card-home">
                                                {match.home?.name}
                                                <img src={match.home?.club?.emblem || noTeam} />
                                            </div>
                                            <div className="players-and-staff-sidebar__card-score">
                                                {match.scores?.full?.home || 0}-{match.scores?.full?.away || 0}
                                            </div>
                                            <div className="players-and-staff-sidebar__card-away">
                                                <img src={match.away?.club?.emblem || noTeam} />
                                                {match.away?.name}
                                            </div>
                                        </div>
                                        <div className="players-and-staff-sidebar__card-title">
                                            {match.stage?.title}, {match.matchday?.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const statCards = [
    { title: "Всего матчей", key: "matches" },
    { title: "Всего ЖК", key: "yc" },
    { title: "Среднее ЖК на матч", key: "avgyc" },
    { title: "Всего КК", key: "rc" },
    { title: "Среднее КК на матч", key: "avgrc" },
];

const rolesOptions = [
    { label: "Арбитр", value: "referee" },
    { label: "Делегат", value: "executive" },
    { label: "Медик", value: "medicine" },
    { label: "Фотограф", value: "photo" },
    { label: "Оператор", value: "video" },
];

export default Employee;
