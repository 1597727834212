import React, { useState, useEffect, useContext } from 'react'
import { Desktop, Tablet, Mobile, Auth, Auth2, Live, ClubLayout } from './Layouts'
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from "react-router-dom";

import { WorkspaceContext, GlobalContext } from './ctx'
import { YMInitializer } from 'react-yandex-metrika'

import 'moment/locale/ru'
import PrimeReact from 'primereact/api'

import 'primereact/resources/themes/lara-light-indigo/theme.css'
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

import './app.scss'

import Sideblock from './Components/Sideblock'
import Topbar from './Components/Topbar'
import MatchModal from './Components/MatchEditModal'
import EventsAndRequests from './Components/EventsAndRequests'
import Tournaments from './Components/Tournaments'

// import Clubs from './Components/Clubs'
import Clubs from './Components/v2/ClubsManagment'
import Leagues from './Components/v2/Leagues'

import Publications from './Components/Publications'
import Staff from './Components/Staff'
// import Locations from './Components/Locations'
import Locations from './Components/v2/Locations'
import Structure from './Components/Structure'
import TipContent from './Components/TipContent'
import TipTrigger from './Components/TipContent/Trigger'
import Appearance from './Components/Appearance'
import Renders from './Components/v2/Renders'
// import Renders from './Components/RendersOld'
import AppConfig from './Components/AppConfig'
import Federations from './Components/Federations'
import Users from './Components/Users'

import CustomScrollbars from 'react-custom-scrollbars-2'

import qs from 'qs'

import { Dialog } from 'primereact/dialog'

import { initHints } from './hints'
import PlayersAndCoaches from "./Components/PlayersAndCoaches";
import Schedule from "./Components/Schedule";
import Support from "./Components/Support";
import Roles from "./Components/v2/Roles";
import { getByUrl } from "./v3MethodsService";
import QueriesVerify from "./Components/v2/QueriesVerify";
import Invitation from './Components/v2/Invitation';
import CookiesNotification from "./Components/v2/CookiesNotification";
import Rff from './Components/v2/Rff';
import TournamentsV2 from './Components/v2/TournamentsV2';
import Report from './Components/v2/Report';
import TournamentsRequests from "./Components/v2/TournamentsReqests";
import AdminDocs from './Components/v2/AdminDocs';
import Notices from "./Components/v2/Notices";
import References from "./Components/v2/References";
import PlayersAndStaff from "./Components/v2/PlayersAndStaff";
initHints()

PrimeReact.ripple = true

const token = localStorage.getItem('_amateum_auth_tkn')

const App = ({ device }) => {

    const [global, setGlobal] = useState({
        auth: false,
        menu: false,
    })

    const profileId = global && global.auth && global.auth.self

    useEffect(() => {
        if (token) {
            getByUrl('init')
                .then(r => {
                    if (r && r.success) {
                        setGlobal(prev => ({ ...prev, auth: r }))
                    } else {
                        setGlobal(prev => ({ ...prev, noVerify: r }))
                    }
                })
        }
    }, [token]);

    useEffect(() => {
        if (profileId) {
            getByUrl(`users/${profileId}`)
                .then((res) => {
                    if (res && res.success) {
                        setGlobal(prev => ({ ...prev, profile: res.data }))
                    }
                })
        }
    }, [profileId]);

    return (
        <GlobalContext.Provider value={[global, setGlobal]}>
            <Router>
                <ModalSwitch />
            </Router>
        </GlobalContext.Provider>
    )
}

function ModalSwitch() {
    const [subject, setSubject] = useState(null)
    const [profile, setProfile] = useState(null)
    const [title, setTitle] = useState(null)
    const [workspace, setWorkspace] = useState({})
    const [global, setGlobal] = useContext(GlobalContext)
    const isAuth = !!(global && global.auth)

    const patchContext = (key, value) => {
        setWorkspace({ ...workspace, [key]: value })
    }

    const history = useHistory()
    let location = useLocation();
    let background = location.state && location.state.background;

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const onResize = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener("resize", onResize)
        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    useEffect(() => {
        if (location.pathname === '/verify' && !isAuth) {
            const token = location.search.replace('?token=', '')
            if (token) {
                getByUrl(`apply_verify?verify=${token}`)
                    .then(resp => {
                        if (resp && resp.success) {
                            history.replace('/')

                            getByUrl('init')
                                .then(r => {
                                    if (r && r.success) {
                                        setGlobal(prev => ({ ...prev, auth: r, noVerify: null }))
                                    } else {
                                        setGlobal(prev => ({ ...prev, noVerify: r }))
                                    }
                                })
                        }
                    })
            }
        }
        if (['/reg', '/login', '/verify'].includes(location.pathname) && isAuth) {
            history.replace('/')
        }
    }, [location, isAuth]);

    // useEffect(() => {
    //     setWorkspace({ ...workspace, profile: profile })
    // }, [profile])

    // useEffect(() => {
    //     if (subject && subject.token && localStorage.getItem('_amateum_auth_tkn') !== subject.token) {
    //         localStorage.setItem('_amateum_auth_tkn', subject.token)
    //     }

    //     setWorkspace({ ...workspace, subject: subject })
    // }, [subject])

    const Layout = screenWidth > 980 ? Desktop : Mobile
    const layoutId = screenWidth > 980 ? 'desktop' : 'mobile'

    const searchString = qs.parse(window.location.search.replace('?', ''))
    return (
        token || (global && global.noVerify) ? <WorkspaceContext.Provider value={{ workspace, setWorkspace, patchContext, layoutId, appSubject: subject }}>
            {/* !token ? <WorkspaceContext.Provider value={{workspace, setWorkspace, patchContext, layoutId, appSubject: subject}}> */}
            {/*{errorInit && (
                <div className='error-init'>
                    <div className='side-profile__not-verify'>
                        <i className='pi pi-clock'/>
                        Ваш профиль проходит процедуру верификации, пожалуйста зайдите позднее
                    </div>
                </div>
            )}*/}
            <CookiesNotification/>
            <Layout
                onlyLiveMode={subject && subject.onlyLiveMode}
                sideblock={(
                    <Sideblock
                        theme='indigo'
                        subject={subject}
                        userTkn={token}
                        onSubjectChanged={setSubject}
                        onProfileChanged={setProfile}
                        onTitleChanged={t => setTitle(t)}
                    />
                )}
                top={(
                    <Topbar
                        layout={layoutId}
                        title={title}
                        profile={profile}
                        hasBackBtn={subject && subject.type === 'club'}
                    />
                )}
                subject={subject}
                body={subject && subject.type === 'club' ? ( /* enabled club layout (for disable set "_club") */
                    <Switch>
                        <Route path="/support">
                            <Support
                                subject={subject}
                            />
                        </Route>
                        <Route path='/live/:id?/:eventId?'> {/* live or matchesRedactor */}
                            <Live
                                _subject={subject}
                            />
                        </Route>
                        <Route exact path="/clubs/:id/teamsquad/:teamsquadId">
                            <Clubs
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/clubs/:id">
                            <Clubs
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/invite">
                            <Invitation
                                subject={subject}
                            />
                        </Route>
                        <Route path='/:area?/:item?'>
                            <ClubLayout
                                subject={subject}
                            />
                        </Route>
                    </Switch>
                ) : (
                    <Switch location={background || location}>
                        <Route path='/live/:id?/:eventId?'> {/* live or matchesRedactor */}
                            <Live
                                _subject={subject}
                            />
                        </Route>
                        <Route exact path="/matches"> {/* /eventsAndRequests */}
                            <EventsAndRequests
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/publications/:id?">
                            <Publications
                                subject={subject}
                                profile={profile}
                            />
                        </Route>
                        <Route exact path="/schedule">
                            <Schedule
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/regionReports">
                            <Report
                                subject={subject}
                                // layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/fullReports">
                            <Report
                                subject={subject}
                                // layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/leagueReports">
                            <Report
                                subject={subject}
                                // layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/tournaments">
                            <TournamentsV2
                                subject={subject}
                                // layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/tournaments/:id">
                            <TournamentsV2
                                subject={subject}
                                // layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/tournaments/:id/teamsquad/:teamsquadId">
                            <TournamentsV2
                                subject={subject}
                                // layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/appconfig">
                            <AppConfig
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/tournaments/teamsquad/:teamsquadId">
                            <Tournaments
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/players"> {/* players-and-coaches */}
                            <PlayersAndCoaches
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/playersAndEmpls">
                            <PlayersAndStaff/>
                        </Route>
                        <Route exact path="/manageclub">
                            <Clubs
                                manage={true}
                                subject={subject}
                                profile={profile}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/clubs/:id/teamsquad/:teamsquadId">
                            <Clubs
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/clubs/:id">
                            <Clubs
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/clubs">
                            <Clubs
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/infographics"> {/* renders */}
                            <Renders
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/employees"> {/* staff */}
                            <Staff
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/locations/:id?">
                            <Locations
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/structure">
                            <Structure
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/appearance">
                            <Appearance
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/clubs/teamsquad/:teamsquadId">
                            <Clubs
                                subject={subject}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/manageclub/teamsquad/:teamsquadId">
                            <Clubs
                                subject={subject}
                                manage={true}
                                layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/leagueData/:id?"> {/* federations */}
                            <Leagues
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/leagueAdminsAccounts"> {/* users */}
                            <Users
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/support">
                            <Support
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/roles/:id?">
                            <Roles
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/createQueries">
                            <QueriesVerify
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/invite">
                            <Invitation
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/regions/:id?">
                            <Rff
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/queries/:id?">
                            <TournamentsRequests
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/platformDocs">
                            <AdminDocs
                                subject={subject}
                                // layout={layoutId}
                            />
                        </Route>
                        <Route exact path="/notices">
                            <Notices
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="/references">
                            <References
                                subject={subject}
                            />
                        </Route>
                        <Route exact path="*"> {/*если нет выбранного пути*/}
                            <span className='dev-page'>Раздел находится в разработке</span>
                        </Route>
                    </Switch>
                )}
            />

            {/* Show the modal when a background page is set */}
            <Dialog className='match-dialog' visible={searchString && searchString.editmatch && searchString.editmatch.length === 24} showHeader={false}>
                {searchString && searchString.editmatch && searchString.editmatch.length === 24 ? (
                    <MatchModal matchId={searchString.editmatch} />
                ) : null}
            </Dialog>

            <Dialog
                className='tip-dialog'
                visible={workspace.tip}
                onHide={() => setWorkspace({ ...workspace, tip: null })}
            >
                <TipContent tip={workspace.tip} />
            </Dialog>

            <TipTrigger layout={layoutId} />

            <YMInitializer
                accounts={[]}
                version='2'
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    trackHash: true,
                }}
            />
            {/*</WorkspaceContext.Provider> : <Auth />*/}
        </WorkspaceContext.Provider> : <Auth2 />
    );
}

export default App
