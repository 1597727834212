import React, { useState, useRef, useEffect } from 'react'

import { FileUpload } from 'primereact/fileupload'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'

import 'react-image-crop/src/ReactCrop.scss'
import './style.scss'

import axios from 'axios'
import { ENDPOINT } from '../../../../env'
import ButtonUi from "../../../../UI/ButtonUi";

const CustomUploader = ({
    mainStore,
    index = 0,
    onChange = (any) => {},
    image = null,
    label = "",
    mutedLabel = "",
    minSize = 512000,
    maxSize = 5242880,
    invalid = false,
    accept,
    className = "",
    multiple = false,
    preview = "",
    previewId = "",
    previewKey = "",
    previewTitle = "",
    filesLimit = 0,
    queue = false,
    modalCrop = false,
    disabled = false,
}) => {

    const fileUploadRef = useRef(null);
    const [selectedImage, setImage] = useState(null)
    const [current, setCurrent] = useState(null)
    const [loading, setLoading] = useState(false)


    const handleUpload = async (e) => {
        if (e.files && e.files.length > 1) {
            // Создаем массив промисов
            const uploadPromises = Array.from(e.files).map(async (file) => {
                const reader = new FileReader();
                const blob = await fetch(file.objectURL).then((r) => r.blob());
                reader.readAsDataURL(blob);

                return new Promise((resolve) => {
                    reader.onloadend = async () => {
                        const base64data = reader.result;

                        const response = await axios.post(`${ENDPOINT}v1/common/upload`, {
                            target: 'attachments',
                            base64Data: base64data,
                            asRaw: true
                        });

                        resolve({
                            url: response.data.uploaded,
                        });
                    };
                });
            });

            // Ждем завершения всех загрузок
            const files = await Promise.all(uploadPromises);

            // Вызываем onChange только после обработки всех файлов
            onChange(files);
        } else {
            const file = e.files[0]
                const reader = new FileReader();
                let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    setImage(base64data)
                }
        }
    };

    // const handleUpload = async e => {
    //     const file = e.files[0]
    //     const reader = new FileReader();
    //     let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
    //     reader.readAsDataURL(blob);
    //     reader.onloadend = () => {
    //         const base64data = reader.result;
    //         setImage(base64data)
    //     }
    // }

    const makeCrop = () => {
        setLoading(true)
        const { crop, origins } = current
        const elem = document.createElement('canvas')
        document.getElementById('root').appendChild(elem)
        elem.width = origins[0]*(crop.width/100)
        elem.height = origins[1]*(crop.height/100)
        const ctx = elem.getContext('2d')
        const img = new Image()
        img.onload = () => {
            console.log(origins[0]*(crop.x/100), origins[1]*(crop.y/100))
            ctx.drawImage(
                img,
                origins[0]*(crop.x/100),
                origins[1]*(crop.y/100),
                elem.width,
                elem.height,
                0,
                0,
                elem.width,
                elem.height
            )

            const decoded = elem.toDataURL('image/jpeg')
            axios.post(`${ENDPOINT}v1/common/upload`, {
                target: 'attachments',
                base64Data: decoded,
                asRaw: true
            }).then(resp => {
                setLoading(false)
                if(fileUploadRef && fileUploadRef.current) {
                    fileUploadRef.current.clear()
                }
                setImage(null)
                setCurrent(null)
                onChange({
                    width: elem.width,
                    height: elem.height,
                    url: resp.data.uploaded
                })
            })
        }
        elem.remove();
        img.src = selectedImage
    }

    return (
        image ? (
                <div className="fileupload__field">
                    {label ? (
                        <label htmlFor="comment" className="fileupload__label">
                            {label} <span>{mutedLabel}</span>
                        </label>
                    ) : null}
                    <div className="fileupload__input fileupload__input_item">
                        <img src={image.file} alt="Загруженное изображение"/>
                        <div className="fileupload__input_btns">
                            {!disabled && <ButtonUi icon="pi pi-trash" className="fileupload__remove-btn" onClick={() => onChange(null, index)}/>}
                            {/* {preview ? <ButtonUi icon="pi pi-arrow-up-right" className="fileupload__preview-btn" onClick={previewHandler} /> : null} */}
                        </div>
                    </div>
                </div>
            ) :
            <>
                <div className="fileupload__field">
                    {label ? (
                        <label htmlFor="comment" className="fileupload__label">
                            {label} <span>{mutedLabel}</span>
                        </label>
                    ) : null}
                    <FileUpload
                        ref={fileUploadRef}
                        mode="basic"
                        accept={accept ? accept : ".jpeg, .jpg, .png"}
                        onSelect={handleUpload}
                        customUpload
                        uploadHandler={uploadHandler}
                        chooseOptions={chooseOptions}
                        className={`fileupload__input ${invalid ? "fileupload__input_invalid" : ""}`}
                        multiple={multiple}
                        disabled={disabled}
                    />
                </div>
                <Dialog
                    visible={selectedImage !== null}
                    resizable={false}
                    maskClassName='crop-dialog'
                    onHide={() => {
                        setImage(null)
                        fileUploadRef.current.clear()
                    }}
                    footer={(
                        <div className='actions'>
                            <Button className='p-button-sm btn-create' icon='pi pi-check' loading={loading}
                                    label='Готово'
                                    onClick={() => makeCrop()}/>
                            <Button className='p-button-sm btn-delete' label='Закрыть' icon='pi pi-times'
                                    onClick={() => {
                                        fileUploadRef.current.clear()
                                        setLoading(false)
                                        setCurrent(null)
                                        setImage(null)
                                    }}/>
                        </div>
                    )}
                >
                    <div className='crop-preview'>
                        <ImageCropper image={selectedImage} setCurrent={setCurrent}/>
                    </div>
                </Dialog>
            </>
    )
}

const ImageCropper = ({ image, setCurrent }) => {
    const [crop, setCrop] = useState()
    const [origins, setOrigins] = useState(null)

    useEffect(() => {
        setCurrent({crop, origins})
    }, [crop, origins])

    const onImageLoad = e => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        setOrigins([width, height])

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                16 / 9,
                width,
                height
            ),
            width,
            height
        )

        setCrop(crop)
    }

    return  <ReactCrop
        crop={crop}
        onChange={(c, pc) => setCrop(pc)}
        aspect={16 / 9}
    >
        <img src={image} onLoad={onImageLoad} />
    </ReactCrop>
}

const uploadHandler = (event) => {
    event.options.clear();
};

const chooseOptions = { label: " ", icon: "pi pi-fw pi-plus" };

export default CustomUploader