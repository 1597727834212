import React, {useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";

import {pluralForm} from "../../../../../utils";

import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

import logo from "../../../TournamentsV2/Detail/img/defaultLogo.png";

import './style.scss'

const LocationHomeplace = ({ data }) => {
    const history = useHistory();

    const [filteredData, setFilteredData] = useState([])
    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        if (data && data.clubs) {
            setFilteredData([...data.clubs])
        } else {
            setFilteredData([])
        }
    }, [data]);

    useEffect(() => {
        let _filteredData = [...data?.clubs];
        if (searchString) {
            _filteredData = _filteredData.filter((item) => {
                return Object.keys(item).some((key) => {
                    return (
                        item[key] &&
                        (item[key].toString().toLowerCase().includes(searchString.toLowerCase()) || (item[key].value && item[key].value.toString().toLowerCase().includes(searchString.toLowerCase())))
                    );
                });
            });
        }

        setFilteredData(_filteredData);
    }, [searchString]);

    const handleOpen = (club) => {
        history.push(`/clubs/${club._id}`)
    }

    return (
        <div className='location-homeplace tournamets2-teams'>
            <div className="tournamets2-teams__search">
                <InputText placeholder="Название..." value={searchString || ""} onChange={(e) => setSearchString(e.target.value)}/>
                <i className="pi pi-search"/>
            </div>
            {filteredData && filteredData.length > 0 ? (
                <div className="tournamets2-teams__content">
                    {filteredData.map(item => (
                        <div key={item._id} onClick={() => handleOpen(item)} className="tournamets2-teams__card">
                            <img src={item.emblem || logo} className="tournamets2-teams__card-logo" />
                            <span className="tournamets2-teams__card-title">{item.name}</span>
                            <span className="tournamets2-teams__card-player">{item.squad?.players?.length || 0} игрок{pluralForm(item.squad?.players?.length || 0, ["", "а", "ов"])}</span>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="tournamets2-disqualification__notfound">{searchString ? 'Поиск не дал результатов' : 'Еще не были добавлены команды'}</div>
            )}
        </div>
    )
}

export default LocationHomeplace