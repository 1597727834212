import React, { useEffect, useState, useContext } from 'react'

import { ImageHandler } from '../../Atoms'
import Attachment from '../Attachment'
import {FileUpload} from "primereact/fileupload"
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { getByUrl } from '../../../v3MethodsService'
import { GlobalContext } from '../../../ctx'
import MediaBank from '../../v2/ClubsManagment/Detail/MediaBank'

const CustomUploads = ({ onUploaded, customUploader, chooseOptions, isVideo = false }) => {
    const [visible, setVisible] = useState(false)
    const [medias, setMedias] = useState([])
    const [global, setGlobal] = useContext(GlobalContext)
    const token = localStorage.getItem('_amateum_auth_tkn')
    const role = global?.profile?.scopes?.find(f => f.token == token) || null

    useEffect(() => {
        getByUrl(`medias?sampleId=${role._id}&sampleType=leagues&isPublished=true`).then((resp) => {
            if (resp && resp.success && resp.data) {
                const newList = resp.data.list?.filter(d => isVideo ? videoFormats.some(v => d.fullpath.endsWith(v)) : !videoFormats.some(v => d.fullpath.endsWith(v)))

                setMedias({...resp.data, list: newList});
            }
        });
    }, [])

    const [selectedMedia, setSelectedMedia] = useState()

    const handleUpload = async (image) => {
        if(image) {
            onUploaded({
                // width: elem.width,
                // height: elem.height,
                url: image.fullpath
            })
        }
    }

    return  <div className='custom-upload'>
                <div className='upload-section'>
                    <div className='upload-action'>
                        {/* <FileUpload
                            mode='basic'
                            name="demo[]"
                            url="https://primefaces.org/primereact/showcase/upload.php"
                            multiple={true}
                            accept=".doc,.docx,.odt,.xls,.xlsx,.ppt,.pptx,.pdf"
                            maxFileSize={8e+6}
                            chooseLabel="Загрузить"
                            chooseOptions={chooseOptions}
                            customUpload
                            onSelect={customUploader}
                        /> */}
                        <Button label="Выбрать из медиабанка" onClick={() => {setVisible(true)}} />
                        <Dialog
                            visible={visible}
                            draggable={false}
                            className='media-bank__dialog'
                            modal
                            appendTo={'self'}
                            header={'Выберите одно медиа из фотобанка'}
                            onHide={() => {if (!visible) return; setVisible(false); }}
                            footer={(
                                <div >
                                    <Button label={isVideo ? "Добавить ссылку" : "Добавить обложку"} onClick={() => {handleUpload(selectedMedia); setVisible(false)}} />
                                </div>
                            )}
                        >
                            <MediaBank medias={medias} selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia} />
                        </Dialog>
                    </div>
                </div>
            </div>
}

const videoFormats = [".mp4", ".avi", ".mov", "webm"]

export default CustomUploads
