import React, {useEffect, useState} from "react";

import {schemas} from "../../../../../references";
import {diffData} from "../../../../../utils";
import service from "../../../../Tournaments/service";

import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";

import "./style.scss";
import moment from "moment/moment";
import {Calendar} from "primereact/calendar";

const Settings = ({ state, updateState, toast }) => {
    const [data, setData] = useState({});
    const [formationOptions, setFormationOptions] = useState([])

    const dataToSend = diffData(state, data)

    useEffect(() => {
        if (state) {
            setData(state)
            if(state.league && state.league.discipline) {
                const formationKey = state.league.discipline.name.split('x')[0]
                const available = schemas[formationKey]
                if(available) {
                    setFormationOptions(available)
                }
            }
        }
    }, [state]);

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData(prev => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else {
            setData(prev => ({ ...prev, [key]: val }));
        }
    };

    const updateMatchData = (val, key, subKey) => {
        if (subKey) {
            setData(prev => ({ ...prev, config: {...prev.config, [key]: { ...prev.config[key], [subKey]: val } } }));
        } else {
            setData(prev => ({ ...prev, config: {...prev.config, [key]: val } }));
        }
    }

    const updateDisqData = (val, key, subKey) => {
        if (subKey) {
            setData(prev => ({ ...prev, config: {...prev.config, disqual: {...prev.config.disqual, [key]: { ...prev.config.disqual[key], [subKey]: val } } } }));
        } else {
            setData(prev => ({ ...prev, config: {...prev.config, disqual: {...prev.config.disqual, [key]: val } } }));
        }
    }

    const handleSave = () => {
        service.simpleUpdate(state._id, dataToSend, toast)
            .then(resp => {
                if (resp) {
                    updateState(data)
                }
            })
    }

    return (
        <div className="tournamets2-settings">
            <div className="tournamets2-settings__card">
                <span className="tournamets2-settings__title">Статус</span>
                <div className="tournamets2-settings__container">
                    {status.map((item, idx) => item.type === 'date' ? (
                        <div className="tournamets2-settings__dates" key={idx}>
                            <Calendar
                                value={data?.squadStart ? moment(data?.squadStart, "YY-MM-DD").toDate() : "" || ""}
                                onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("YY-MM-DD") : e.target.value, 'squadStart')}
                                className="auth2__calendar"
                                locale="ru"
                                dateFormat="dd.mm.yy"
                                mask="99.99.9999"
                                placeholder="Начало"
                                appendTo="self"
                                maxDate={moment(data?.squadEnd, "YY.MM.DD").toDate()}
                                showButtonBar
                                showIcon
                                disabled={!data?.addonsAllowed}
                            />
                            -
                            <Calendar
                                value={data?.squadEnd ? moment(data?.squadEnd, "YY-MM-DD").toDate() : "" || ""}
                                onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("YY-MM-DD") : e.target.value, 'squadEnd')}
                                className="auth2__calendar"
                                locale="ru"
                                dateFormat="dd.mm.yy"
                                mask="99.99.9999"
                                placeholder="Окончание"
                                appendTo="self"
                                minDate={moment(data?.squadStart, "YY.MM.DD").toDate()}
                                showButtonBar
                                showIcon
                                disabled={!data?.addonsAllowed}
                            />
                        </div>
                    ) : (
                        <div className="tournamets2-settings__item" key={idx}>
                            <div className="tournamets2-settings__switch">
                                <InputSwitch checked={item.subkey ? !!data?.[item?.key]?.[item.subkey] : !!data?.[item?.key]} onChange={(e) => updateData(e.value, item.key, item.subkey)} />
                                <lable className="tournamets2-settings__label">{item.label}</lable>
                            </div>
                            {item.subkey === "squadLimit" && <InputNumber value={data?.[item?.key]?.[item.subkey]} onChange={e => updateData(e.value, item.key, item.subkey)} disabled={!data?.[item?.key]?.[item.subkey]} placeholder="число" className="tournamets2-settings__inputnum" />}
                        </div>
                    ))}
                </div>
            </div>
            <div className="tournamets2-settings__card">
                <span className="tournamets2-settings__title">Матчи</span>
                <div className="tournamets2-settings__content">
                    {match.map((item, idx) => (
                        item.key === "shootoutPoints" && !item.subkey ? (
                            <div className="tournamets2-settings__switch" key={idx}>
                                <InputSwitch checked={!!data?.config?.[item?.key]} onChange={(e) => updateMatchData(e.value ? {win: 2, lose: 1} : false, item.key)} />
                                <lable className="tournamets2-settings__label">{item.label}</lable>
                            </div>
                        ) : (
                            <div className="tournamets2-settings__item" key={idx}>
                                <span className="tournamets2-settings__label">{item.label}</span>
                                <InputNumber value={item.subkey ? data?.config?.[item?.key]?.[item?.subkey] : data?.config?.[item?.key]} onChange={e => updateMatchData(e.value, item.key, item.subkey)} placeholder="число" className="tournamets2-settings__inputnum" disabled={!data?.config?.[item.key]} />
                            </div>
                        )
                    ))}
                </div>
            </div>
            <div className="tournamets2-settings__card">
                <span className="tournamets2-settings__title">Дисквалификации</span>
                <div className="tournamets2-settings__content">
                    {disq.map((item, idx) => (
                        item.key === "redAsTwoYellow" ? (
                            <div className="tournamets2-settings__switch" key={idx}>
                                <InputSwitch checked={data?.config?.disqual?.[item?.key]} onChange={(e) => updateDisqData(e.value, item.key)} />
                                <lable className="tournamets2-settings__label">{item.label}</lable>
                            </div>
                        ) : (
                            <div className="tournamets2-settings__item" key={idx}>
                                <span className="tournamets2-settings__label">{item.label}</span>
                                <InputNumber value={data?.config?.disqual?.[item?.key]?.[item?.subkey]} onChange={(e) => updateDisqData(e.value, item.key, item.subkey)} placeholder="число" className="tournamets2-settings__inputnum" />
                            </div>
                        )
                    ))}
                </div>
            </div>
            <div className="tournamets2-settings__card">
                <span className="tournamets2-settings__title">Схема сборной тура / турнира</span>
                <div className="tournamets2-settings__scheme">
                    {formationOptions.map((item, idx) => (
                        <div className="tournamets2-settings__radio" key={idx}>
                            <RadioButton value={item} onChange={(e) => updateData(e.value, "config","schema")} checked={data.config?.schema === item} />
                            <label className="tournamets2-settings__label">{item}</label>
                        </div>
                    ))}
                </div>
            </div>

            <Button label="Сохранить" className="clubs-detail__button" disabled={!dataToSend} onClick={handleSave} />
        </div>
    );
};

export default Settings;

const status = [
    { label: "Заявки / дозаявки разрешены", key: "addonsAllowed" },
    { label: "Дата начала", key: "squadStart", type: "date" },
    // { label: "Дата окончания", key: "squadEnd", type: "date" },
    { label: "Турнир завершен", key: "finished" },
    { label: "Сплитованная статистика стадий ", key: "config", subkey: "splitStagesStats" },
    { label: "Сквозные заявки в турнире", key: "config", subkey: "extraSquad" },
    { label: "Лимит игроков в заявке", key: "config", subkey: "squadLimit" },
];

const match = [
    { label: "Таймов в матче", key: "periodCount" },
    { label: "Продолжительность тайма", key: "periodDuration" },
    { label: "Голов победителю при тех.победе", key: "valkoverScore" },
    { label: "Учитывать серию пенальти в матче", key: "shootoutPoints" },
    { label: "Очков за победу", key: "shootoutPoints", subkey: "win" },
    { label: "Очков за поражение", key: "shootoutPoints", subkey: "lose" },
];

const disq = [
    { label: "ЖК для дисквалификации", key: "yellow", subkey: "qty"  },
    { label: "Пропускаемых матчей за перебор ЖК", key: "yellow", subkey: "count" },
    { label: "КК для дисквалификации ", key: "red", subkey: "qty" },
    { label: "Пропускаемых матчей за КК", key: "red", subkey: "count" },
    { label: "КК за две ЖК в матче", key: "redAsTwoYellow" },
];
