import React from "react";

import DataTable from "../../Common/DataTable";

import {Column} from "primereact/column";

import './style.scss'

const LocationsList = ({ locations, handleClickLocation, format, confirmDelete, copyLink }) => {
    return (
        <div className='locations-list'>
            <DataTable
                onClick={handleClickLocation}
                search
                noCreateBtn
                columns={[
                    { field: "name", header: "Название" },
                    { field: "capacity", header: "Вместимость" },
                    { field: "address", header: "Адрес" },
                    { field: "format", header: "Формат" },
                ]}
                rows={locations
                    ?.filter((f) => (format !== "list" ? f?.format == format : f))
                    ?.map((row) => ({
                        ...row,
                        name: { value: row?.name },
                        capacity: { value: row?.capacity, custom: row?.capacity ? row?.capacity : <span className="">Не указана</span> },
                        address: { value: row?.address, custom: row?.address ? row?.address : <span className="">Не указан</span> },
                        format: { value: row?.format === "all" ? "Все форматы" : row?.format },
                    }))}
                paginator={locations?.length > 8}
                perPage={8}
                handleRemove={confirmDelete}
                customDataColumn={() => (
                    <Column
                        headerClassName="data-table__action"
                        bodyClassName="data-table__action"
                        body={(rowData, idx) => (
                            <div className="data-table__action-download" onClick={(e) => copyLink(e, rowData)}>
                                <i className="pi pi-link"></i>
                            </div>
                        )}
                    />
                )}
            />
        </div>
    )
}

export default LocationsList