import React, { useContext, useEffect, useRef, useState } from "react";

import moment from "moment";

import { MultiSelect } from "primereact/multiselect";

import { Calendar } from "primereact/calendar";

import { InputSwitch } from "primereact/inputswitch";

import { diffData, pluralForm, useClickOutside } from "../../../../utils";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { InputTextarea } from "primereact/inputtextarea";
import "./style.scss";

import { confirmDialog } from "primereact/confirmdialog";
import { deleteByUrl, postByUrl, putByUrl } from "../../../../v3MethodsService";
import { Dropdown } from "primereact/dropdown";

const NewsLetter = ({ visible, setVisible, toast, addPlayer, curdata, setcurData, getClubActions, teamsOptions }) => {
    const [data, setData] = useState({});
    const [state, setState] = useState({});
    const [playersOpts, setPlayersOpts] = useState([]);
    const ref = useRef();
    const dataToSend = diffData(state, data);

    useClickOutside(visible, ref, () => setVisible(false));

    useEffect(() => {
        resetPlayersOptions();
        initializeData();
        return cleanup;
    }, [visible, teamsOptions]);

    const resetPlayersOptions = () => setPlayersOpts([]);

    const initializeData = () => {
        if (curdata?._id) {
            const players = teamsOptions?.teams.flatMap((team) => team.allPlayers) || [];
            setPlayersOpts(players);
        }
        setData({ ...curdata });
        setState({ ...curdata });
    };

    const cleanup = () => {
        setcurData(null);
        setState(null);
    };

    const updateData = (val, key, subKey) => {
        setData((prev) => ({
            ...prev,
            [key]: subKey ? { ...prev[key], [subKey]: val } : val,
        }));
    };

    const handleTeam = (val, key) => {
        const selectedTeams = teamsOptions.teams.filter((team) => val.includes(team._id));
        const players = selectedTeams.flatMap((team) => team.allPlayers).filter((player, index, self) => index === self.findIndex((p) => p._id === player._id)) || [];
        setPlayersOpts(players);
        setData((prev) => ({
            ...prev,
            players: players.map((player) => player._id),
            [key]: val,
        }));
    };

    const handleUpdate = async () => {
        const newData = formatDates(dataToSend);
        const response = await putByUrl(`clubactions/${data._id}`, newData);

        response?.success ? onSuccessfulUpdate() : onError(response?.message);
    };

    const handlePost = async () => {
        const newData = formatDates(data);
        const response = await postByUrl(`clubactions`, newData);

        response?.success ? onSuccessfulPost() : onError(response?.message);
    };

    const deleteHandler = () => {
        confirmDialog({
            message: "Событие будет безвозвратно удалено",
            header: "Удалить событие?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: deleteItem,
        });
    };

    const deleteItem = async () => {
        const response = await deleteByUrl(`clubactions/${data._id}`);

        response?.success ? onSuccessfulDelete() : onError(response?.message);
    };

    const formatDates = (data) => {
        const format = (date) =>
            date
                ?.split(".")
                .reverse()
                .map((v, i) => (i === 0 ? v.slice(2) : v))
                .join("-");
        return {
            ...data,
            eventDate: format(data.eventDate),
            ...(data.endDate && { endDate: format(data.endDate) }),
        };
    };

    const onSuccessfulUpdate = () => {
        getClubActions();
        toast.current.show({ severity: "success", summary: "Событие изменено" });
        setVisible(false);
        setcurData(null);
    };

    const onSuccessfulPost = () => {
        getClubActions();
        toast.current.show({ severity: "success", summary: "Рассылка о событии отправлена" });
        setVisible(false);
        setcurData(null);
    };

    const onSuccessfulDelete = () => {
        getClubActions();
        toast.current.show({ severity: "success", summary: "Событие удалено" });
        setVisible(false);
        setcurData(null);
    };

    const onError = (message) => {
        toast.current.show({
            severity: "error",
            summary: message || "Ошибка сервера. Повторите попытку позже.",
            life: 1000,
        });
    };

    const disabledCreate = data && data.title && data.description && data.teams?.length > 0 && data.players?.length > 0 && data.eventDate && (data.isRegular ? data.regularType && data.endDate : true);

    return (
        !!visible &&
        data && (
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right newsletter">
                <div className="side-profile p-component p-sidebar-enter-done" ref={ref}>
                    <div className="side-profile__header">
                        <div className="side-profile__btns">
                            {data._id ? (
                                <>
                                    <Button label="Сохранить" className="side-profile__button-save auth2__button" disabled={!(dataToSend && disabledCreate)} onClick={handleUpdate} />
                                    <Button label="Удалить" className="side-profile__button-save auth2__button side-profile__button-remove" onClick={deleteHandler} />
                                </>
                            ) : (
                                <Button label="Отправить рассылку игрокам" className="side-profile__button-save auth2__button" onClick={handlePost} disabled={!disabledCreate} />
                            )}
                        </div>
                    </div>
                    <div className="side-profile__body">
                        <span className="newsletter__title">Для кого событие</span>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Заголовок*</label>
                            <InputText placeholder="Укажите заголовок" value={data.title} onChange={(e) => updateData(e.target.value, "title")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Описание*</label>
                            <InputTextarea placeholder="Опишите подробнее" value={data.description} onChange={(e) => updateData(e.target.value, "description")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Команды*</label>
                            <MultiSelect
                                selectedItemsLabel={data?.teams?.length + " команд" + pluralForm(data?.teams?.length, ["а", "ы", ""])}
                                maxSelectedLabels={0}
                                value={data.teams}
                                filter
                                options={teamsOptions.teams}
                                optionLabel="name"
                                optionValue="_id"
                                appendTo={"self"}
                                onChange={(e) => handleTeam(e.value, "teams")}
                                placeholder="Выберите команды"
                                emptyFilterMessage="Ничего не найдено"
                            />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Игроки из команды*</label>
                            <MultiSelect
                                selectedItemsLabel={data?.players?.length === playersOpts.length ? "Все" : data?.players?.length + " игрок" + pluralForm(data?.players?.length, ["", "а", "ов"])}
                                maxSelectedLabels={0}
                                filter
                                appendTo={"self"}
                                optionValue="_id"
                                value={data.players}
                                itemTemplate={(option) => `${option.name} ${option.surname} ${option.middlename}`}
                                options={playersOpts}
                                onChange={(e) => updateData(e.value, "players")}
                                disabled={playersOpts.length === 0}
                                placeholder="Выберите игроков"
                                emptyFilterMessage="Ничего не найдено"
                            />
                        </div>
                        <span className="newsletter__title">Когда проходит событие</span>
                        <div className="clubs-profile__switch">
                            <InputSwitch checked={data.isRegular} onChange={(e) => updateData(e.value, "isRegular")} />
                            <lablel className="clubs-profile__label">Регулярное событие</lablel>
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Время начала</label>
                            <Calendar  mask="99:99" placeholder="00:00" timeOnly value={data?.eventTime} onChange={(e) => updateData(e.value, "eventTime")} appendTo="self" />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">{data.isRegular ? "Дата окончания*" : "Дата события*"}</label>
                            <Calendar
                                placeholder="Выберите дату"
                                value={moment(data.isRegular ? (data?.endDate ? data?.endDate : data?.eventDate) : data?.eventDate, "DD.MM.YYYY").toDate()}
                                onChange={(e) => updateData(moment(e.value).format("DD.MM.YYYY"), data.isRegular ? "endDate" : "eventDate")}
                                dateFormat="dd.mm.yy"
                                className={`auth2__calendar`}
                                locale="ru"
                                mask="99.99.9999"
                                appendTo="self"
                            />
                        </div>
                        {data.isRegular && (
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Повторение*</label>
                                <Dropdown
                                    appendTo="self"
                                    options={regularOptions}
                                    onChange={(e) => updateData(e.value, "regularType")}
                                    value={data.regularType}
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Выберите период"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

const regularOptions = [
    { label: "Каждую неделю", value: "week" },
    { label: "Каждые две недели", value: "two_week" },
    { label: "Каждый месяц ", value: "month" },
];

export default NewsLetter;
