import React, { useState, useEffect } from "react";
import "./style.scss";

import { ProgressSpinner } from "primereact/progressspinner";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";

import Form from "../Form";
import Attachment from "../Attachment";

import axios from "axios";
import { ENDPOINT } from "../../../env";

import moment from "moment";
import DataTable from "../../v2/Common/DataTable";

const exclude = ["link", "poll"];

const ImportVk = ({ handleVk, profile, onSaved }) => {
    const [externals, setExternals] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("_amateum_auth_tkn");

        axios
            .get(`${ENDPOINT}v2/getVkSources`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((resp) => {
                if (!resp.data.error && resp.data[0]) {
                    axios
                        .get(`${ENDPOINT}v2/getVKPosts/${resp.data.join("_")}`, {
                            headers: {
                                Authorization: token,
                            },
                        })
                        .then((resp) => {
                            if (resp.data.data) {
                                setExternals(resp.data.data);
                            }
                        });
                }
            });
    }, []);


    return !externals ? (
        <ProgressSpinner />
    ) : (
        <DataTable
            emptyMessage="Публикации не найдены"
            search
            noCreateBtn
            onClick={(e) => handleVk({ ...e.data })}
            columns={[
                { field: "photo", header: "Фото", className: "secondary" },
                { field: "date", header: "Дата публикации", sortable: true },
            ]}
            rows={externals?.map((row) => ({
                ...row,
                photo: {
                    value: row?.attachments[0] ? (
                        <Attachment data={row?.attachments[0]} />
                    ) : (
                        <div className="publications__empty">
                            <i className="pi pi-image" />
                        </div>
                    ),
                },
                date: {
                    custom: (
                        <div className="publications__date">
                            {moment(row.date, "YY-MM-DD").format("D MMMM YYYY")}
                            <span>{row.title}</span>
                        </div>
                    ),
                    value: row.date,
                },
            }))}
        />
    );
};

export default ImportVk;
