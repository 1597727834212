import React, { useContext, useEffect, useRef, useState } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";

import moment from "moment";

import DataTable from "../../../Common/DataTable";

import noIcon from "./img/noIcon.png";

import "./style.scss";
import { pluralForm } from "../../../../../utils";

const LocationMatches = ({ data, option }) => {
    let location = useLocation();
    let history = useHistory();

    const [filter, setFilter] = useState(null);
    const [filterValue, setFilterValue] = useState(null);

    const handleClickLocation = (e) => {
        // ${location.state.locationId}/matches/${data._id}
        history.push(`/locations/${data._id}/?editmatch=${e.data._id}`);
    };

    const matches = data?.matchesList?.[!option ? "finished" : "recent"];

    const representativeFilterTemplate = () => {
        return (
            <MultiSelect
                value={filterValue}
                options={opt}
                onChange={(e) => {
                    setFilterValue(e.value);
                }}
                placeholder="Турниры"
                className="p-column-filter"
                selectedItemsLabel={filterValue?.length + " турнир" + pluralForm(filterValue?.length, ["", "а", "ов"])}
                maxSelectedLabels={0}
                filter
                emptyFilterMessage="Ничего не найдено"
            />
        );
    };

    const clearFilter = () => {
        setFilter(null);
        setFilterValue([])
    }

    const opt = matches ? Array.from(new Set(matches.map((match) => match.tournament))) : [];

    return (
        <div className="location-matches">
            <DataTable
                onClick={handleClickLocation}
                noCreateBtn
                columns={[
                    { field: "tournamentName", header: "Турнир", filter: opt.length > 1, filterElement: representativeFilterTemplate, filterKey: "tournament" },
                    { field: "homeTeamName", header: "Команда хозяев", className: "homeTeam" },
                    { field: "score", header: "", className: "score" },
                    { field: "awayTeamName", header: "Команда гостей" },
                    { field: "dateStart", header: "Дата матча", className: "homeTeam" },
                ]}
                rows={
                    matches
                        ?.filter((item) => (filter ? item[filter.key] === filter?.val || filter?.val?.includes(item[filter.key]) : item))
                        .map((row) => ({
                            ...row,
                            tournamentName: { value: row?.tournament, custom: customTournament(row) },
                            homeTeamName: { value: row?.home?.name, custom: customTeam(row?.home, "home") },
                            score: {
                                value: !option ? `${row?.scores?.full?.home || 0}-${row?.scores?.full?.away || 0}` : "-",
                                custom: customScore(!option ? `${row?.scores?.full?.home || 0}-${row?.scores?.full?.away || 0}` : "-"),
                            },
                            awayTeamName: { value: row?.away?.name, custom: customTeam(row?.away, "away") },
                            dateStart: { value: row?.date, custom: customDate(row) },
                        })) || []
                }
                paginator={matches?.length > 8}
                perPage={8}
                filters={{ tournamentName: { value: null, matchMode: FilterMatchMode.BETWEEN } }}
                clearFilter={clearFilter}
                setFilter={setFilter}
                filterValue={filterValue}
            />
        </div>
    );
};

const customTournament = (item) => {
    return (
        <div className="location-matches__tournament">
            <div className="location-matches__tournament-text">{item.tournament}</div>
            <span>{item.matchDay}</span>
        </div>
    );
};

const customTeam = (team, side) => {
    return (
        <div className={`location-matches__team location-matches__team_${side}`}>
            {team?.name}
            <img src={team?.emblem || noIcon} />
        </div>
    );
};

const customDate = (item) => {
    return (
        <div className="location-matches__tournament location-matches__date">
            {moment(item?.date, "YY-MM-DD").format("DD.MM.YYYY")}
            <span>{item.time}</span>
        </div>
    );
};

const customScore = (score) => {
    return <div className="location-matches__score">{score}</div>;
};

export default LocationMatches;
