import React, { useState, useContext, useRef, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { ItemContext } from '../../../../ctx'
import axios from "axios";
import {ENDPOINT} from "../../../../../../env";
import {GlobalContext} from "../../../../../../ctx";
import {transliterate} from "transliteration";
import {postByUrl} from "../../../../../../v3MethodsService";

import { Badge } from 'primereact/badge'
import { RadioButton } from 'primereact/radiobutton'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {FileUpload} from "primereact/fileupload";

import PlayerItem from '../../PlayerItem'

import { v4 as uuidv4 } from 'uuid'

import { extractEvent } from '../../helpers'

import './style.scss'

const types = {
    goal: 'Гол',
    missedPenalty: 'Незабитый пенальти',
    yellowcard: 'Предупреждение',
    secondyellow: 'Удаление за 2ЖК',
    redcard: 'Прямое удаление'
}

const subtypes = {
    goal: [
        {value: 'default', label: 'С игры'},
        {value: 'penalty', label: 'С пенальти'},
        {value: 'standart', label: 'Со штрафного'},
        {value: 'owngoal', label: 'Автогол соперника'}
    ]
}

const EventPanel = ({ _side, preset }) => {
    const ctx = useContext(ItemContext)
    const [global, setGlobal] = useContext(GlobalContext)
    const tkn = global && global.auth && global.auth.token ? global.auth.token : ''

    const fileUploadRef = useRef();

    const [minute, setMinute] = useState(null)
    const [addon, setAddon] = useState(null)
    const [side, setSide] = useState(_side || null)
    const [type, setType] = useState(preset || null)
    const [subtype, setSubtype] = useState(preset && subtypes[preset] ? subtypes[preset][0].value : null)
    const [players, setPlayers] = useState([])
    const [markedToDelete, setMarkedToDelete] = useState(false)

    const [reportage, setReportage] = useState({...initialState})

    const { eventId } = useParams()
    const history = useHistory()

    useEffect(() => {
        const hasAddon = ctx.entity.match ? (ctx.entity.match.periodDuration*ctx.time.period) < ctx.time.displayMinute : false
        setMinute(hasAddon ? (ctx.entity.match.periodDuration*ctx.time.period) : ctx.time.displayMinute)
        setAddon(hasAddon ? ctx.time.displayMinute - ctx.entity.match.periodDuration : null)
    }, [])

    useEffect(() => {
        if(eventId) {
            const event = extractEvent(ctx.entity.events, eventId)
            setSide(event.side)
            setMinute(event.minute)
            setAddon(event.addon)
            setType(event.type)
            setSubtype(event.subtype)
            setPlayers([event.player ? event.player._id : null, event.assistant ? event.assistant._id : null].filter(e => e))
        }
    }, [eventId])

    const team = side ? ctx.entity && ctx.entity.match ? {...ctx.entity.match[side]} : null : null

    const rosterPath = subtype === 'owngoal' ? side === 'home' ? 'away' : 'home' : side
    const roster = ctx.entity.rosters && ctx.entity.rosters[rosterPath] ? ctx.entity.rosters[rosterPath].list : []

    const storeEvent = (del=false) => {
        const body = {
            type: type,
            subtype: subtype,
            player: players[0] ? roster.find(p => p._id === players[0]) : null,
            assistant: players[1] ? roster.find(p => p._id === players[1]) : null,
            minute: minute,
            addon: addon,
            id: uuidv4()
        }

        const newtimeline = ctx.entity.timeLine || []
        newtimeline.unshift({...reportage, event: {...body, side: side}, time: minute || 0})

        ctx.setEntity({
            ...ctx.entity,
            events: {
                ...ctx.entity.events,
                [side]: !ctx.entity.events[side] ? [body] : eventId ? del ? ctx.entity.events[side].filter(e => e.id !== eventId) : ctx.entity.events[side].map(e => e.id === eventId ? body : e) : [...ctx.entity.events[side]].concat([body])
            },
            score: (!eventId && type === 'goal') ? {
                ...ctx.entity.score,
                [side]: ctx.entity.score[side] + 1
            } : (eventId && type === 'goal' && del) ? {
                ...ctx.entity.score,
                [side]: ctx.entity.score[side] - 1
            } : ctx.entity.score,
            timeLine: newtimeline
        })

        axios.put(`${ENDPOINT}v2/states/${ctx.entity._id}`, {
            timeLine: newtimeline
        }, {
            headers: {
                Authorization: tkn,
                SignedBy: tkn
            }
        }).then(resp => {
            if (resp && resp.data && resp.data.success) {

            }
        })

        ctx.setPanel(null)

        if(!del && body && body.player && body.player._id) {
            ctx.setEventCache(body)
        }

        if(eventId) {
            history.push(window.location.pathname.replace('/'+eventId, ''))
        }
    }

    const renderBadge = _id => {
        if(players.includes(_id)) {
            if(type === 'goal') {
                if(subtype === 'owngoal') {
                    return 'Автогол'
                } else {
                    return players.findIndex(i => i === _id) === 0 ? 'Гол' : 'Ассист'
                }
            } else {
                return types[type]
            }
        } else {
            return null
        }
    }

    const renderPlayerBadge = () => {
        if(type === 'goal') {
            return subtype === 'owngoal' ? 'выберите автора автогола' : 'выберите автора гола и ассистента'
        } else {
            return 'выберите игрока'
        }
    }

    const updateBody = (val, key) => {
        setReportage(prev => ({...prev, [key]: val}))
    }

    const onSelect = async (event) => {
        const formData = new FormData();

        const originalFile = event.files[0];

        if (originalFile.size >= 5242880) {
            fileUploadRef.current.clear()
            return
        }

        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("sampleId", ctx.entity._id);
        formData.append("sampleType", "states");
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data;
                updateBody(file, 'photo')
            }
        });
    };

    return  team ? (
        <div className='reportage-panel event-panel'>
            <div className='panel-icon'>
                <img src={team.club.emblem || ''}/>
            </div>

            {type ? (
                <div className='reportage-panel__header'>
                    <span>{team.name}</span>
                    {types[type]}
                </div>
            ) : (
                <div className='reportage-panel__header'>
                    {team.name}
                </div>
            )}

            <div className='event-panel__minutes'>
                <InputText value={minute || null} onChange={e => setMinute(e.target.value)} inputMode='numeric'
                           type='number' placeholder='Укажите минуты' className='event-panel__input'/>
                <InputText value={addon || null} onChange={e => setAddon(e.target.value)} type='number'
                           inputMode='numeric' placeholder='Доп. время' className='event-panel__input'/>
            </div>

            {!type && (
                <div className='event-panel__action'>
                    Тип события
                    <div className='event-panel__types'>
                        {Object.entries(types).map((s, i) => (
                            <Button key={i} label={s[1]} onClick={() => setType(s[0])} className='event-panel__type'/>
                        ))}
                    </div>
                </div>
            )}

            {subtypes[type] && (
                <div className='event-panel__action'>
                    Как был забит гол
                    <div className='event-panel__types'>
                        {subtypes[type].map((st, i) => (
                            <Button
                                key={i}
                                label={st.label}
                                onClick={() => {
                                    setSubtype(st.value)
                                    if ([st.value, subtype].includes('owngoal')) {
                                        setPlayers([])
                                    }
                                }}
                                className={`event-panel__type ${subtype === st.value ? 'event-panel__type_active' : ''}`}
                            />
                        ))}
                    </div>
                </div>
            )}

            {!!(roster && type) && (
                <div className='event-panel__action'>
                    Игрок
                    <div className='event-panel__subtypes'>
                        {roster.map(p => (
                            <PlayerItem
                                key={p._id}
                                data={p}
                                onToggle={obj => {
                                    if (players.includes(obj._id)) {
                                        setPlayers(players.filter(i => i !== obj._id))
                                    } else {
                                        const limit = type === 'goal' && subtype !== 'owngoal' ? 2 : 1
                                        if (players.length < limit) {
                                            setPlayers(players.concat([obj._id]))
                                        }
                                    }
                                }}
                                badge={renderBadge(p._id)}
                            />
                        ))}
                    </div>
                </div>
            )}

            <div className='event-panel__action'>
                <div className='reportage-panel__container'>
                    <label>Описание</label>
                    <InputTextarea onChange={e => updateBody(e.target.value, 'description')} placeholder='Опишите подробнее'
                                   className='reportage-panel__inputtextarea'/>
                </div>
                <div className='reportage-panel__container'>
                    <label>Фотография</label>
                    {reportage.photo ? (
                        <img src={reportage.photo.path} className='reportage-panel__photo'/>
                    ) : (
                        <FileUpload
                            ref={fileUploadRef}
                            mode='basic'
                            className='reportage-panel__fileupload'
                            customUpload
                            onSelect={onSelect}
                            accept=".jpg,.png"
                        />
                    )}
                </div>
            </div>

            {!!type && (
                <div className='event-panel__footer reportage-panel__footer'>
                    <Button label='Изменить тип' icon='pi pi-chevron-left' className='event-panel__button' onClick={() => setType(null)}/>
                    <Button label={players.length ? 'Сохранить' : 'Без игрока'} icon='pi pi-check' className='event-panel__button reportage-panel__button' onClick={storeEvent}/>

                    {!!eventId && (
                        <Button
                            className={`p-button-sm p-button-${markedToDelete ? 'danger' : 'warning'}`}
                            label={markedToDelete ? 'Нажмите ещё раз для удаления' : 'Удалить событие'}
                            icon='pi pi-exclemation-circle'
                            onClick={() => markedToDelete ? storeEvent(true) : setMarkedToDelete(true)}
                            style={{gridColumn: '1/-1', width: '100%', justifyContent: 'center'}}
                        />
                    )}
                </div>
            )}

            {/*<div className='panel-title'>
                {!type ? 'Событие' : types[type]} - {team.name}

                <div className='p-inputgroup minute-control'>
                    <span className='p-inputgroup-addon'>мин</span>
                    <span className='p-inputnumber p-component p-inputwrapper'>
                                <input
                                    onChange={e => setMinute(e.target.value)}
                                    value={minute || null}
                                    inputMode='numeric'
                                    type='number'
                                    className='p-inputtext'
                                />
                            </span>
                    <span className='p-inputgroup-addon'>+</span>
                    <span className='p-inputnumber p-component p-inputwrapper'>
                                <input
                                    onChange={e => setAddon(e.target.value)}
                                    value={addon || null}
                                    inputMode='numeric'
                                    type='number'
                                    className='p-inputtext'
                                />
                            </span>
                </div>
            </div>*/}

            {/*{subtypes[type] ? (
                <div className='panel-section' style={{paddingTop: 20}}>
                    <div className='section-badge' style={{textAlign: 'center'}}>
                        <Badge severity='info' value='детали события'/>
                    </div>

                    <div className='event-options'>
                        {subtypes[type].map((st, i) => (
                            <div
                                className="field-radiobutton"
                                key={i}
                                onClick={() => {
                                    setSubtype(st.value)
                                    if ([st.value, subtype].includes('owngoal')) {
                                        setPlayers([])
                                    }
                                }}
                            >
                                <RadioButton id={st.value} value={st.value} name='subtype'
                                             checked={subtype === st.value}/>
                                <label htmlFor={st.id}>{st.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}*/}

            {/*{!type ? [
                <div className='section-badge' style={{textAlign: 'center', marginTop: 30}}>
                    <Badge severity='info' value='тип события'/>
                </div>,
                <div className='schemas-btns'>
                    {Object.entries(types).map((s, i) => (
                        <Button
                            className='p-button-sm p-button-info p-button-outlined'
                            key={i}
                            onClick={() => setType(s[0])}
                        >{s[1]}</Button>
                    ))}
                </div>
            ] : null}*/}

            {/*{roster && type ? [
                <div className='section-badge' style={{textAlign: 'center', marginTop: 18}}>
                    <Badge
                        severity='info'
                        value={renderPlayerBadge()}
                    />
                </div>,
                <div className='roster-squad' style={{marginBottom: 130}}>
                    {roster.map(p => (
                        <PlayerItem
                            key={p._id}
                            data={p}
                            onToggle={obj => {
                                if (players.includes(obj._id)) {
                                    setPlayers(players.filter(i => i !== obj._id))
                                } else {
                                    const limit = type === 'goal' && subtype !== 'owngoal' ? 2 : 1
                                    if (players.length < limit) {
                                        setPlayers(players.concat([obj._id]))
                                    }
                                }
                            }}
                            badge={renderBadge(p._id)}
                        />
                    ))}
                </div>
            ] : null}*/}

            {/* <div className='panel-action'>
                {players.length ? <Button
                    className='p-button-sm p-button-info'
                    icon='pi pi-check'
                    onClick={() => storeEvent()}
                >Записать событие</Button> : type ? <Button
                    className='p-button-sm p-button-info p-button-outlined'
                    icon='pi pi-check'
                    onClick={() => storeEvent()}
                >Записать без игрока</Button> : null}

                {players.length ? <Button
                    className='p-button-sm p-button-secondary'
                    icon='pi pi-times'
                    onClick={() => setPlayers([])}
                >Очистить выбор</Button> : type ? <Button
                    className='p-button-sm p-button-secondary'
                    icon='pi pi-chevron-circle-left'
                    onClick={() => setType(null)}
                >Изменить тип</Button> : <Button
                    className='p-button-sm p-button-secondary'
                    icon='pi pi-times'
                    onClick={() => ctx.setPanel(null)}
                >Отмена</Button>}

                {eventId ? (
                    <Button
                        className={`p-button-sm p-button-${markedToDelete ? 'danger' : 'warning'}`}
                        icon='pi pi-exclemation-circle'
                        onClick={() => markedToDelete ? storeEvent(true) : setMarkedToDelete(true)}
                    >{markedToDelete ? 'Нажмите ещё раз для удаления' : 'Удалить событие'}</Button>
                ) : null}
            </div>*/}
        </div>
    ) : null
}

const initialState = {
    description: '',
    photo: ''
}

export default EventPanel
