import React, {useEffect, useRef, useState, useContext } from "react"
import {useHistory} from 'react-router-dom'

import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import { GlobalContext } from "../../../../../ctx";

import './style.scss'
import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../../../v3MethodsService";

const MediaBank = ({ medias, setMedias, data, toast, selectedMedia, setSelectedMedia }) => {
    const op = useRef(null);
    const opRefs = useRef([]);
    const history = useHistory()

    const [global, setGlobal] = useContext(GlobalContext)
    const token = localStorage.getItem('_amateum_auth_tkn')

    const role = global?.profile?.scopes?.find(f => f.token == token) || null
    const [activeIndex, setActiveIndex] = useState(0);
    const [selected, setSelected] = useState([])
    const [mode, setMode] = useState('')
    const [visible, setVisible] = useState(false)
    const [state, setState] = useState({
        club: 'all',
        user: 'all'
    })

    const [clubs, setClubs] = useState([{
        _id: 'all',
        name: 'Все клубы',
    }]);
    const [users, setUsers] = useState([{
        _id: 'all',
        surname: 'Все представители',
    }]);

    useEffect(() => {
        if(medias && medias.filters) {
            if(medias.filters.users) {
                setUsers(prev => ([...prev, ...medias.filters.users]))
            }
            if(medias.filters.clubs) {
                setClubs(prev => ([...prev, ...medias.filters.clubs]))
            }
        }
        if(medias?.archived?.length) {
            setVisible(true)
        }
    }, [medias])

    const dataReduced = medias && medias?.list?.length && medias.list.reduce((acc, val) => {
        if(val.sampleType == 'leagues') {
            acc[1].push(val)
        }
        if(val.sampleType == 'clubs') {
            acc[2].push(val)
        }
        acc[0].push(val)
        return acc
    }, {0: [], 1: [], 2: []})
     
    const tabHeaderTemplate = (options, item) => {
        return dataReduced[options.index].length ? (
            <div className="flex align-items-center gap-2 p-3" style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <span className="font-bold white-space-nowrap">{`${item.label} (${dataReduced[options.index].length})`}</span>
            </div>
        ) : null;
    };
   
    const deleteHandler = (items) => {
        if(role) {
            const sampleType = role.type == 'league' ? 'leagues' : 'clubs'
            
            const mediaWscopes = items.reduce((acc, val) => {
                if(role.type == 'league' && val.sampleType == 'clubs') {
                    acc['update'].push(val._id)
                } else {
                    acc['delete'].push(val._id)
                }
                return acc
            }, {update: [], delete: []})
            
            if(mediaWscopes?.update?.length) {
                postByUrl(`medias`, {
                        "mode":"updateMany",
                        "key":"archive", //restore
                        "ids": mediaWscopes?.update
                }).then((resp) => {
                    if (resp && resp.success) {
                        getByUrl(`medias?sampleId=${data._id}&sampleType=${sampleType}&isPublished=true`).then((resp) => {
                            if (resp && resp.success && resp.data) {
                                setMedias(resp.data);
                                toast?.current?.show({ severity: "success", summary: `Удаление прошло успешно` });
                            }
                        });
                    }
                });
            }
            if(mediaWscopes?.delete?.length) {
                postByUrl(`medias`, {
                        "mode":"deleteMany",
                        "ids": mediaWscopes?.delete
                }).then((resp) => {
                    if (resp && resp.success) {
                        getByUrl(`medias?sampleId=${data._id}&sampleType=${sampleType}&isPublished=true`).then((resp) => {
                            if (resp && resp.success && resp.data) {
                                setMedias(resp.data);
                                toast?.current?.show({ severity: "success", summary: `Удаление прошло успешно` });
                            }
                        });
                    }
                });
            }
        }
        setMode(''); setSelected([])
    }

    const MediaImages = ({ filteredData }) => {
        // console.log('filteredData', filteredData);

        return (
            filteredData?.length ? (
                <>
                    {filteredData.map((item, index) => {
                        const checked = selected.find(f => f._id == item._id)
    
                        const checkboxHandler = (e) => {
                            let newSelected = [...selected]
                            if(newSelected.find(f => f._id == item._id)) {
                                newSelected = newSelected.filter(f => f._id !== item._id)
                            } else {
                                newSelected.push(item)
                            }
                            setSelected(newSelected)
                        }
                        
                        return (
                            <div key={index} onClick={setSelectedMedia ? () => {setSelectedMedia(item)} : ()=>{}} className={`media-bank__images-item ${selectedMedia?._id == item._id ? 'selected' : ''}`}>
                                    {mode == 'many' ?
                                        <Checkbox 
                                            className={`media-bank__images-checkbox`} 
                                            onChange={checkboxHandler} 
                                            checked={!!checked}
                                        />
                                    :
                                        !setSelectedMedia && <i
                                            onClick={(e) => {
                                                // menuIcon.style.opacity = 1
                                                // menuIcon.style.visibility = 'visible'
                                                if (opRefs && opRefs[index].show) {
                                                    opRefs[index].show(e);
                                                }
                                                // const overlayPanel = document.querySelector(`.media-bank__overlay-${index}`)
                                                // overlayPanel.classList.toggle('active')
                                            }}
                                            className={`pi pi-ellipsis-h`}
                                        />
                                    }
                                    <div className="media-bank__images-hover"></div>
                                    {videoFormats.some(v => item.fullpath.endsWith(v)) ? (
                                        <video controls>
                                            <source src={item.fullpath}/>
                                        </video>
                                    ) : (
                                        <img src={item.fullpath} className="media-bank__images-img" alt=""/>
                                    )}
                                    <OverlayPanel
                                    // ref={opRef}
                                        ref={(el) => (opRefs[index] = el)}
                                        onShow={() => {
                                            // setSelected([item])            
                                            console.log('item show', item);
                                        }}
                                        onHide={() => {
                                            // menuIcon.style.opacity = 0;
                                            // menuIcon.style.visibility = 'hidden'
                                            setSelected([])
                                        }}
                                    >
                                        <div className="media-bank__overlay">
                                            <div onClick={(e) => { setMode('many'); opRefs[index].hide(e); }} className="media-bank__overlay-item">Выбрать несколько</div>
                                            <div onClick={() => { deleteHandler([item]) }} className="media-bank__overlay-item">Удалить</div>
                                        </div>
                                    </OverlayPanel>
                                    {item?.sampleType == 'clubs' && role?.type == 'league' ? 
                                        <>
                                            <Tooltip className="media-bank__images-tooltip" target=".media-bank__images-label">
                                                <div className="media-bank__images-tooltip__row">
                                                    Клуб<span>{item?.sample?.name}</span>
                                                </div>
                                                <div className="media-bank__images-tooltip__row">
                                                    Кто загрузил 
                                                    <span>{item.creator.name} {item.creator.middlename}</span>
                                                </div>
                                            </Tooltip>
                                            {/* data-pr-tooltip={`${item.text}`}  */}
                                            <div data-pr-position="top" className="media-bank__images-label">загружено клубом</div>
                                        </>
                                    : null}
                            </div>
                        )
                    })}
                </>
            ) : <div>Ничего не найдено</div>
        )
    }
    
    // console.log(medias.list);
    // console.log('selected', selected);
    console.log('dataReduced', dataReduced);
    
    return (
        <div className='media-bank'>
            <Dialog
                visible={role?.type == 'club' && visible}
                draggable={false}
                className='media-bank__dialog'
                modal
                header={'Фото были удалены представителем лиги'}
                onHide={() => {if (!visible) return; setVisible(false); }}
                footer={(
                    <div className='media-bank__dialog-buttons'>
                        <Button label="Написать в поддержку" className='media-bank__dialog-support' onClick={() => {history.replace('/support')}} />
                        <Button label="Хорошо" className='media-bank__dialog-close' onClick={() => {setVisible(false)}} />
                    </div>
                )}
            >
                <div className="media-bank__images">
                    {medias?.archived?.map((item, index) => {
                        return (
                            <div key={index} className="media-bank__images-item">
                                <img src={item.fullpath} className="media-bank__images-img" alt=""/>
                            </div>
                        )
                    })}
                </div>
            </Dialog>
            {dataReduced && Object.values(dataReduced).length ? 
                <>
                    {role?.type == 'club' ?
                        <>
                            <div className="media-bank__clubs">
                                <div className="media-bank__images">
                                    <MediaImages filteredData={dataReduced[2]} />
                                </div>
                            </div>
                        </>
                    :
                        <TabView activeIndex={activeIndex} onTabChange={(e) => {setMode(''); setSelected([]); setActiveIndex(e.index)}}>
                            {tabOptions.map((item, index) => {
                                const filteredData = dataReduced[activeIndex].filter(f => {
                                    if(activeIndex == 2 && role.type == 'league' && (state.club !== 'all' || state.user !== 'all')) {
                                        return f.creator._id == state.user || f.sample._id == state.club
                                    } else {
                                        return f
                                    }
                                })
                                
                                const gridColumns = () => {
                                    switch (true) {
                                        case (filteredData?.length % 5 == 1):
                                            return 'one'
                                            break;
                                        case (filteredData?.length % 5 == 1):
                                            return 'one'
                                            break;
                                        case (filteredData?.length % 5 == 1):
                                            return 'one'
                                            break;
                                        case (filteredData?.length % 5 == 1):
                                            return 'one'
                                            break;
                                        case (filteredData?.length % 5 == 1):
                                            return 'one'
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                return (
                                <TabPanel key={index} header={item.label} headerTemplate={options => tabHeaderTemplate(options, item)}>
                                    {activeIndex == 2 && role.type == 'league' ? 
                                        <div className="media-bank__dropdowns">
                                            <Dropdown
                                                options={clubs}
                                                onChange={e => setState(prev => ({...prev, club: e.value}))}
                                                value={state.club}
                                                optionLabel="name"
                                                optionValue="_id"
                                                className="media-bank__dropdowns-item"
                                            />
                                            <Dropdown
                                                options={users}
                                                onChange={e => setState(prev => ({...prev, user: e.value}))}
                                                value={state.user}
                                                optionLabel="surname"
                                                optionValue="_id"
                                                className="media-bank__dropdowns-item"
                                            />
                                        </div>
                                    : null}
                                    <div className={`media-bank__images ${gridColumns()}`}>
                                        <MediaImages filteredData={filteredData} />
                                    </div>
                                </TabPanel>
                                )
                            })}
                        </TabView>
                    }
                    
                    {mode == 'many' ?
                        <div className='media-bank__buttons'>
                            <Button className='media-bank__buttons-cancel' label="Отмена" onClick={() => {setMode(''); setSelected([])}} />
                            {selected.length ? <Button className='media-bank__buttons-delete' icon="pi pi-trash" label={`Удалить ${selected.length} фото`} onClick={() => deleteHandler(selected)} /> : null}
                        </div>    
                    : null}
                </>
            :
                <div className="media-bank__empty">Еще не было загружено медиа</div>
            }
        </div>
    )
}

const tabOptions = [
    {
        key: 'all',
        label: 'Все',
    },
    {
        key: 'league',
        label: 'Загружено лигой',
    },
    {
        key: 'club',
        label: 'Загружено клубами',
    },
]

const videoFormats = [".mp4", ".avi", ".mov", "webm"]

export default MediaBank