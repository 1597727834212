import React, { useState, useEffect } from 'react'
import {Draggable} from "react-beautiful-dnd";

import EditorUi from "../../../../../UI/EditorUi/index.jsx";
import MultiFileUpload from './MultiFileUpload/index.jsx';
import InputUi from '../../../../../UI/InputUi/index.jsx';
// import InputTextareaUi from "../../../../UI/InputTextareaUi/index.jsx";
// import EditorUi from "../../../../UI/EditorUi/index.jsx";
// import MultiFileUpload from "../../../Common/MultiFileUpload/index.jsx";
import CustomUploads from "../../CustomUploads";

import dndIcon from './img/dnd.svg'
import Hotkeys from "react-hot-keys";
import './style.scss'

const FormBlock = ({index, data, onChange, onRemove}) => {

    const fileUploadHandler = (val) => {
        onChange({...data, images: val}, index)
    }

    const onKeyDown = async (e) => {
        if (e === 'Enter' || e.key === 'Enter') {
            console.log('abzac');
        }
    }


    const renderBlock = () => {
        switch (data.key) {
            case 'subtitle': return <EditorUi id={data.id} value={data.subtitle} onChange={(e) => onChange({...data, subtitle: e.htmlValue}, index)} placeholder='Укажите заголовок'/>
            case 'text': return (
                // <Hotkeys
                //     keyName="Enter"
                //     onKeyDown={(e) => onKeyDown(e)}
                // >
                //     <InputTextareaUi value={data.text} onChange={(e) => onChange({...data, text: e.target.value}, index)} placeholder='Опишите подробнее' onKeyDown={onKeyDown}/>
                // </Hotkeys>
                <EditorUi className='form-body__text' id={data.id} value={data.text} onChange={(e) => onChange({...data, text: e.htmlValue}, index)} placeholder='Опишите подробнее'/>
            )
            case 'image': return <>
                <MultiFileUpload images={data.images} onChange={fileUploadHandler} label='До 10 фото'/>
                <InputUi value={data.author} onChange={(e) => onChange({...data, author: e.target.value}, index)} label='Автор фото' placeholder='Укажите автора'/>
            </>
            case 'video': return (
                <div className='form-block__video'>
                    <InputUi value={data.url} onChange={(e) => onChange({...data, url: e.target.value}, index)} label='Ссылка на видео с внешнего хостинга*' placeholder='Вставьте ссылку'/>
                    <div className='mediaForm-detail__main-divider'><span/>или<span/></div>
                    <CustomUploads
                        onUploaded={val => onChange({...data, ...val}, index)}
                        isVideo
                    />
                </div>
            )
            case 'quote':
                return (
                    <>
                    <InputUi value={data.text} onChange={(e) => onChange({...data, text: e.target.value}, index)} label='Текст цитаты*' placeholder='Укажите цитату'/>
                        <InputUi value={data.author} onChange={(e) => onChange({...data, author: e.target.value}, index)} label='Автор*' placeholder='Укажите автора'/>
                        <InputUi value={data.post} onChange={(e) => onChange({...data, post: e.target.value}, index)} label='Должность автора' placeholder='Укажите должность'/>
                    </>
                )
        }
    }

    return (
        <Draggable key={data.id} draggableId={data.id} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                        ...provided.draggableProps.style,
                        backgroundColor: snapshot.isDragging ? 'var(--lara-blue-blue-50)' : '',
                    }}
                    className="form-block__container"
                >
                    <img src={dndIcon} className='form-block__dnd-icon' {...provided.dragHandleProps}/>
                    <div className='form-block'>
                        <div className='form-block__title'>
                            {titles[data.key]}
                            <i className='pi pi-trash form-block__trash' onClick={() => onRemove(index)}/>
                        </div>
                        {renderBlock()}
                    </div>
                </div>
            )}
        </Draggable>
    )
}

const titles = {
    subtitle: 'Заголовок*',
    text: 'Текст*',
    video: 'Видео',
    image: 'Фото',
    quote: 'Цитата'
}

export default FormBlock