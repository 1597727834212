import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { GlobalContext } from "../../../ctx";
import { deleteByUrl, postByUrl, putByUrl } from "../../../v3MethodsService";
import axios from "axios";
import { ENDPOINT } from "../../../env";
import { transliterate } from "transliteration";

import LocationsList from "./List";
import LocationsMap from "./Map";
import LocationDetail from "./Detail";
import FileUploadUi from "../ClubsManagment/Detail/FileUploadUi";
import DataTable from "../Common/DataTable";

import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import "./style.scss";

const Locations = ({ subject, layout }) => {
    const [global, setGlobal] = useContext(GlobalContext);
    const tkn = global && global.auth && global.auth.token ? global.auth.token : "";

    const history = useHistory();
    const params = useParams();
    const locationId = params && params.id;

    const [loader, setLoader] = useState(false);
    const [locations, setLocations] = useState([]);
    const [format, setFormat] = useState("list");
    const [item, setItem] = useState(null);
    const [visibleRight, setVisibleRight] = useState(false);
    const [view, setView] = useState("list");

    // const locationId = window.location.search.replace("?id=", "");
    const toastRef = useRef(null);

    useEffect(() => {
        if (locationId && locations && locations.length > 0) {
            const location = locations?.find((l) => l._id === locationId);
            if (location) {
                setItem(location);
            }
        }
    }, [locationId, locations]);

    useEffect(() => {
        if (tkn) {
            getLocations();
        }
    }, [tkn, locationId]);

    const getLocations = () => {
        setLoader(true);
        axios
            .get(`${ENDPOINT}methodsV3/locations`, {
                headers: {
                    Authorization: tkn,
                    scopetoken: localStorage.getItem("_amateum_auth_tkn"),
                },
            })
            .then((resp) => {
                setLocations(resp.data.data);
                setLoader(false);
            });
    };

    const deleteLocation = async (item) => {
        setLoader(true);
        const resp = await deleteByUrl(`locations/${item._id}`);
        setLoader(false);
        if (resp.success) {
            toastRef.current.show({ severity: "success", detail: "Площадка удалена" });
            getLocations();
            return true;
        } else {
            toastRef.current.show({ severity: "error", summary: resp.message || "Ошибка сервера. Повторите попытку позже." });
            return false;
        }
        // setItem(null);
        // setVisibleRight(false);
    };

    const confirmDelete = (e, item) => {
        e?.stopPropagation();
        confirmDialog({
            message: "Проверьте, пожалуйста, участвует ли эта площадка в запланированных матчах. После её удаления матчи потеряют указанное место проведения",
            header: "Удалить площадку?",
            defaultFocus: "accept",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px" },
            accept: () => deleteLocation(item),
            reject: () => {},
        });
    };

    const copyLink = (e, item) => {
        e?.stopPropagation();
        setVisibleRight(false);
        navigator.clipboard.writeText(`https://live.amateum.com/s/${item._id}`);
        toastRef.current.show({ severity: "success", detail: "Ссылка на оверлей скопирована" });
    };

    const showSideBar = () => {
        history.push("/locations/new");
        // setVisibleRight(true);
        // setItem(defaultLocation);
    };

    const handleClickLocation = (location) => {
        history.push(`/locations/${location.data._id}`);
        // const data = location.data;
        // const locationObj = {
        //     name: data.name.value,
        //     format: data.format.value,
        //     address: data.address.value,
        //     capacity: data.capacity.value,
        //     photo: data.photo,
        //     _id: data._id,
        // };
        // setItem(locationObj);
        // setVisibleRight(true);
    };

    const onSelect = async (event, type) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("s3only", true);

        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.path;
                setItem((prev) => ({ ...prev, [type]: file }));
            } else {
                toastRef.current.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const renderSidebarInputs = (input, index) => {
        switch (input.type) {
            case "inputNumber":
                return (
                    <div className="locations-v2__sidebar-item">
                        <div className="locations-v2__sidebar-label">{input.label}</div>
                        <InputNumber key={index} value={item?.[input.key] || null} onValueChange={(e) => setItem((prev) => ({ ...prev, [input.key]: e.value }))} placeholder={input.placeholder} />
                    </div>
                );
                break;
            case "dropdown":
                return (
                    <div className="locations-v2__sidebar-item">
                        <div className="locations-v2__sidebar-label">{input.label}</div>
                        <Dropdown
                            key={index}
                            value={item?.[input.key]}
                            options={formats}
                            optionLabel="label"
                            optionValue="value"
                            placeholder={input.placeholder}
                            onChange={(e) => setItem((prev) => ({ ...prev, [input.key]: e.value }))}
                        />
                    </div>
                );
                break;
            case "input":
                return (
                    <div className="locations-v2__sidebar-item">
                        <div className="locations-v2__sidebar-label">{input.label}</div>
                        <InputText key={index} value={item?.[input.key] || null} onChange={(e) => setItem((prev) => ({ ...prev, [input.key]: e.target.value }))} placeholder={input.placeholder} />
                    </div>
                );
                break;
            default:
                break;
        }
    };

    const saveDisable = !(item?.name && item?.format);

    const Specified = locationId ? LocationDetail : view === "list" ? LocationsList : LocationsMap;

    return (
        <div className="locations-v2">
            <Toast position="top-right" ref={toastRef} />
            {/* <ConfirmDialog /> */}
            {!locationId && (
                <div className="locations-v2__header">
                    <div className="locations-v2__header-select-wrapper">
                        <SelectButton value={view} onChange={(e) => setView(e.value)} options={viewOptions} itemTemplate={viewTemplate} unselectable={false} />
                        <SelectButton
                            value={format}
                            onChange={(e) => {
                                if (e.value) setFormat(e.value);
                            }}
                            options={formats}
                        />
                    </div>
                    <Button icon="pi pi-plus" onClick={showSideBar} />
                </div>
            )}
            {/*<Sidebar
                className="locations-v2__sidebar"
                visible={visibleRight}
                position="right"
                showCloseIcon={true}
                onHide={() => setVisibleRight(false)}
                style={{ width: "516px !important", overflow: "hidden" }}
            >
                <div className="locations-v2__sidebar-header">
                    <Button disabled={saveDisable} className="locations-v2__sidebar-save" label="Сохранить" onClick={item?._id ? updateLocation : createLocation} />
                    {item?._id ? (
                        <>
                            <Button className="locations-v2__sidebar-delete" label="Удалить" onClick={(e) => confirmDelete(e, item)} />
                            <Button className="locations-v2__sidebar-copy" label="Скопировать ссылку" onClick={(e) => copyLink(e, item)} />
                        </>
                    ) : null}
                </div>
                <div className="locations-v2__sidebar-title">{!item?._id ? "Создать площадку" : "Редактировать площадку"}</div>
                {inputs.map((item, index) => renderSidebarInputs(item, index))}
                <FileUploadUi
                    label="Фото площадки"
                    mutedLabel="(jpg, jpeg, png, до 5 Мб)"
                    image={item?.photo}
                    onChange={(e) => onSelect(e, "photo")}
                    updateData={() => setItem((prev) => ({ ...prev, photo: null }))}
                />
            </Sidebar>*/}

            {loader ? (
                <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
            ) : (
                <Specified
                    locations={locations}
                    handleClickLocation={handleClickLocation}
                    format={format}
                    confirmDelete={confirmDelete}
                    copyLink={copyLink}
                    item={item}
                    deleteLocation={deleteLocation}
                    toast={toastRef.current}
                />
            )}
        </div>
    );
};

const viewTemplate = (option) => <i className={option.icon} />;

const viewOptions = [
    { icon: "pi pi-list", value: "list" },
    { icon: "pi pi-map", value: "map" },
];

const formats = [
    { label: "Весь список площадок", value: "list" },
    { label: "Универсальный формат", value: "all" },
    { label: "11x11", value: "11x11" },
    { label: "9x9", value: "9x9" },
    { label: "8x8", value: "8x8" },
    { label: "7x7", value: "7x7" },
    { label: "6x6", value: "6x6" },
    { label: "5x5", value: "5x5" },
];

const defaultLocation = {
    name: "",
    format: "",
    address: "",
    capacity: "",
};

const inputs = [
    {
        label: "Название*",
        placeholder: "Укажите название",
        key: "name",
        type: "input",
    },
    {
        label: "Формат*",
        placeholder: "Укажите формат",
        key: "format",
        type: "dropdown",
    },
    {
        label: "Адрес",
        placeholder: "Укажите адрес",
        key: "address",
        type: "input",
    },
    {
        label: "Вместимость",
        placeholder: "Укажите кол-во человек",
        key: "capacity",
        type: "inputNumber",
    },
];

export default Locations;
