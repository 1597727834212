import React, {useContext, useRef, useState} from 'react'

import axios from "axios";
import {ENDPOINT} from "../../../../../../env";
import {GlobalContext} from "../../../../../../ctx";
import {ItemContext} from "../../../../ctx";
import {transliterate} from "transliteration";
import {postByUrl} from "../../../../../../v3MethodsService";

import {InputTextarea} from "primereact/inputtextarea";
import {FileUpload} from "primereact/fileupload";
import {Button} from "primereact/button";

import './style.scss'

const ReportagePanel = () => {
    const ctx = useContext(ItemContext)
    const [global, setGlobal] = useContext(GlobalContext)
    const tkn = global && global.auth && global.auth.token ? global.auth.token : ''

    const fileUploadRef = useRef();

    const [body, setBody] = useState({...initialState})

    const handleSave = () => {
        const newtimeline = ctx.entity.timeLine || []
        newtimeline.unshift({...body, time: ctx.time?.displayMinute || 0})

        axios.put(`${ENDPOINT}v2/states/${ctx.entity._id}`, {
            timeLine: newtimeline
        }, {
            headers: {
                Authorization: tkn,
                SignedBy: tkn
            }
        }).then(resp => {
            if (resp && resp.data && resp.data.success) {
                ctx.setEntity({
                    ...ctx.entity,
                    timeLine: newtimeline
                })

                ctx.setPanel(null)
            }
        })
    }

    const updateBody = (val, key) => {
        setBody(prev => ({...prev, [key]: val}))
    }

    const onSelect = async (event) => {
        const formData = new FormData();

        const originalFile = event.files[0];

        if (originalFile.size >= 5242880) {
            fileUploadRef.current.clear()
            return
        }

        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("sampleId", ctx.entity._id);
        formData.append("sampleType", "states");
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data;
                updateBody(file, 'photo')
            }
        });
    };

    return (
        <div className='reportage-panel'>
            <div className='reportage-panel__header'>Репортаж</div>
            <div className='reportage-panel__container'>
                <label>Описание</label>
                <InputTextarea onChange={e => updateBody(e.target.value, 'description')} placeholder='Опишите подробнее' className='reportage-panel__inputtextarea'/>
            </div>
            <div className='reportage-panel__container'>
                <label>Фотография</label>
                {body.photo ? (
                    <img src={body.photo.path} className='reportage-panel__photo'/>
                ) : (
                    <FileUpload
                        ref={fileUploadRef}
                        mode='basic'
                        className='reportage-panel__fileupload'
                        customUpload
                        onSelect={onSelect}
                        accept=".jpg,.png"
                    />
                )}
            </div>

            <div className='reportage-panel__footer'>
                <Button label='Сохранить' icon='pi pi-check' className='reportage-panel__button' onClick={handleSave} disabled={!body.description}/>
            </div>
        </div>
    )
}

const initialState = {
    description: '',
    photo: ''
}

export default ReportagePanel