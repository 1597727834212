import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import moment from "moment";
import { transliterate } from "transliteration";
import {ENDPOINT} from "../../../../../env";
import service from "../../../../PlayersAndCoaches/service";
import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../../../v3MethodsService";
import {generateRandom36String, pluralForm, publicShareLink} from "../../../../../utils";

import FileUploadUi from "../../../ClubsManagment/Detail/FileUploadUi";

import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import {InputSwitch} from "primereact/inputswitch";
import {Timeline} from "primereact/timeline";
import {Checkbox} from "primereact/checkbox";

import noTeam from "../../img/noTeam.png";
import noPhoto from './img/noPhoto.png'

const Player = ({ state, data, updateData, openedTabs, changeTabHandler, loading, updateLoading, sending, updateSending, outsideRef, onClose, getUpdatedData, convertBase64, toast }) => {
    const [positionOptions, setPositionOptions] = useState([]);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const fileUploadRef = useRef(null);
    const [email, setEmail] = useState(false);

    const [duplicates, setDuplicates] = useState([]);
    const [duplicatesForm, setDuplicatesForm] = useState(false)
    const [choicedDuplicates, setChoicedDuplicates] = useState([])
    const [targetPlayer, setTargetPlayer] = useState(null)

    useEffect(() => {
        setDuplicatesForm(false)
        setChoicedDuplicates([])
        setTargetPlayer(null)
        if (state) {
            if (state._id) {
                updateLoading(true);
                // axios.get(`${ENDPOINT}client/player/${state._id}`)
                //     .then(resp => {
                //         updateData(resp.data)
                //         updateLoading(false)
                //     })
                getData().finally(() => updateLoading(false));
            } else {
                updateData(state);
            }
        } else {
            updateData(null);
        }
    }, [state]);

    useEffect(() => {
        getByUrl("positions").then((resp) => {
            if (resp && resp.success) {
                setPositionOptions(resp.data);
            }
        });
        getByUrl("countries").then((resp) => {
            if (resp && resp.success) {
                setCountriesOptions(resp.data);
            }
        });
    }, []);

    const getData = async () => {
        await getByUrl(`players/${state._id}`).then((resp) => {
            if (resp && resp.success) {
                updateData(resp.data);
                if (resp.data.email) {
                    setEmail(resp.data.email);
                }
            }
        });

        await axios.get(`${ENDPOINT}v2/getCandidatesForPlayer/${state._id}?byFederation=true`,
            {
                headers: {
                    authorization: localStorage.getItem('_amateum_auth_tkn')
                }
            })
            .then((response) => {
                if (response && response.data) {
                    setDuplicates(response.data.candidates);
                }
            })
    };

    const handleSave = async () => {
        updateSending(true);

        let body = { ...data };

        if (data.avatarUrl && data.avatarUrl.objectURL) {
            const decoded = await convertBase64(data.avatarUrl);
            const resp = await service.upload({
                target: "players",
                decoded: decoded,
                asRaw: true,
                trim: false,
            });
            if (resp && resp.uploaded) {
                body.avatarUrl = resp.uploaded;
                updateData(resp.uploaded, "avatarUrl");
            } else {
                updateSending(false);
                console.log("not uploaded photo dif", body);
                toast.show({ severity: "error", summary: "Ошибка сервера", detail: "Не удалось загрузить фото" });
                return null;
            }
        }

        if (data._id) {
            putByUrl(`players/${data._id}`, body).then((resp) => {
                if (resp && resp.success) {
                    getUpdatedData();
                    onClose();
                } else {
                    toast.show({ severity: "error", detail: resp?.message });
                    updateSending(false);
                }
            });
            return;
        }
        postByUrl("players", body).then((resp) => {
            if (resp && resp.success) {
                getUpdatedData();
                onClose();
            } else {
                updateSending(false);
            }
        });
    };

    const uploadAttachment = (event) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("sampleId", state._id);
        formData.append("sampleType", "players");
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                toast.show({ severity: "success", life: 4000, summary: `Файл успешно загружен` });
                getData();
            } else {
                toast.show({ severity: "warn", life: 4000, summary: `Ошибка загрузки файла` });
            }
        });

        fileUploadRef.current.clear();
    };

    const removeAttachment = (_id) => {
        deleteByUrl(`remove_attach/${_id}`).then((resp) => {
            if (resp && resp.success) {
                toast.show({ severity: "success", life: 4000, summary: `Файл успешно удален` });
                getData();
            } else {
                toast.show({ severity: "warn", life: 4000, summary: `Ошибка удаления файла` });
            }
        });
    };

    const sendCofirmToPlayer = () => {
        getByUrl(`sendConfirmToPlayer/${data._id}`).then((resp) => {
            if (resp && resp.success) {
                toast.show({ severity: "success", life: 4000, summary: `Письмо с подтверждением отправлено` });
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }

            updateSending(false);
        });
    };

    const disabledSave = data ? !data.avatarUrl || !data.name || !data.surname || !data.birthday : true;

    const switchShareLink = (value) => {
        updateData(value ? generateRandom36String() : null, 'shareLinkString')
    }

    const duplicateHandle = (id) => {
        if (choicedDuplicates.includes(id)) {
            setChoicedDuplicates(prev => prev.filter(p => p !== id))
            return
        }
        setChoicedDuplicates(prev => ([...prev, id]))
    }

    const handleNotDuplicates = async () => {
        for (let i = 0; i < choicedDuplicates.length; i++) {
            const filtered = duplicates.filter(item => item._id !== choicedDuplicates[i]);
            const mapd = filtered.map(item => item);
            const resp = await service.simpleUpdate(choicedDuplicates[i], {isNotDoubleOf: mapd}, toast)
            if (resp && resp.success) {
                setChoicedDuplicates([])
                setDuplicatesForm(false)
                setDuplicates([])
                setTargetPlayer(null);
            }
        }
    }

    const handleMergindDuplicates = async () => {
        updateSending(true)
        const resp = await service.mergePlayers({_id: targetPlayer}, choicedDuplicates.map(d => ({_id: d})), toast);
        if (resp && resp.success) {
            setTargetPlayer(null);
            setChoicedDuplicates([])
            setDuplicatesForm(false)
            setDuplicates([])
            onClose()
        }
        updateSending(false)
    }

    const updateTargetPlayer = (id) => {
        if (targetPlayer === id) {
            setTargetPlayer(null)
            return
        }
        setTargetPlayer(id)
    }

    return (
        <div className="side-profile p-component p-sidebar-enter-done notices-modal__side" ref={outsideRef}>
            {!!loading && <ProgressSpinner style={{ width: "40px", height: "40px", marginTop: "50%" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />}

            {!!data && !loading && (
                duplicatesForm ? (
                    <div className="side-profile__header">
                        <div className="side-profile__header-btn-group">
                            <Button className="side-profile__button-save auth2__button" label="Объединить" onClick={handleMergindDuplicates} disabled={!(choicedDuplicates?.length > 0) || !targetPlayer} loading={sending} />
                            <Button className="side-profile__button-save side-profile__button-delete auth2__button auth2__button_outlined" label="Это не дубли, больше не предлагать" onClick={handleNotDuplicates} disabled={!(choicedDuplicates?.length > 0)} loading={sending} />
                        </div>
                        <i className="side-profile__icon-close pi pi-times" onClick={onClose} />
                    </div>
                ) : (
                    <div className="side-profile__header">
                        <div className="side-profile__header-btn-group">
                            <Button className="side-profile__button-save auth2__button" label="Сохранить" onClick={handleSave} disabled={disabledSave} loading={sending}/>
                            {email && <Button className="side-profile__button-save auth2__button auth2__button_outlined" label="Отправить приглашение" onClick={sendCofirmToPlayer}/>}
                        </div>
                        <i className="side-profile__icon-close pi pi-times" onClick={onClose}/>
                    </div>
                )
            )}

            {!!data && !loading && duplicates?.length > 0 && (
                <div
                    className={`players-and-staff-sidebar__duplicates ${duplicatesForm ? 'players-and-staff-sidebar__duplicates_active' : ''}`}>
                    <div className='players-and-staff-sidebar__duplicates-top'>
                        Найдено {duplicates.length} {pluralForm(duplicates.length, ['возможный дубль', 'возможных дубля', 'возможных дублей'])}
                        {!duplicatesForm && <Button label='Проверить' onClick={() => setDuplicatesForm(true)} className='players-and-staff-sidebar__button'/>}
                    </div>
                    {duplicatesForm ? (
                        <Timeline value={events} content={(item) => item}/>
                    ) : (
                        'Объединение дублей помогает упростить управление и контроль над несколькими учетными записями'
                    )}

                    {!!duplicatesForm && (
                        <div className='players-and-staff-sidebar__duplicates-list'>
                            {duplicates.map((d, k) => (
                                <div key={k} className={`players-and-staff-sidebar__duplicate ${choicedDuplicates.includes(d._id) ? 'players-and-staff-sidebar__duplicate_active' : ''}`} onClick={() => duplicateHandle(d._id)}>
                                    <img src={d.avatarUrl || noPhoto} />
                                    <div className='players-and-staff-sidebar__duplicate-body'>
                                        {d.surname || ''} {d.name || ''} {d.middlename || ''}
                                        <span>{d.birthday}</span>
                                    </div>
                                    {choicedDuplicates.includes(d._id) && (
                                        <div className='players-and-staff-sidebar__checkbox' onClick={(e) => e.stopPropagation()}>
                                            Эталонная
                                            <Checkbox checked={targetPlayer === d._id} onChange={() => updateTargetPlayer(d._id)}/>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            {!!data && !loading && !duplicatesForm && (
                <div className="side-profile__body">
                    <Tooltip target=".p-fileupload" />
                    <div className={`side-profile__accordion${openedTabs.includes("info") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                        <div className="side-profile__accordion-header">
                            <div className="side-profile__accordion-title">Основная информация</div>
                            <i className={`${openedTabs.includes("info") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("info")} />
                        </div>
                        <div className="players-and-staff-sidebar__accordion-matches">
                            <div className="clubs-profile__container">
                                <FileUploadUi
                                    label="Фото на однотонном фоне*"
                                    mutedLabel="(jpg, jpeg, png, до 5 Мб)"
                                    image={data.avatarUrl?.objectURL || data.avatarUrl}
                                    onChange={(e) => updateData(e.files[0], "avatarUrl")}
                                    updateData={() => updateData(null, "avatarUrl")}
                                    className="players-and-staff-sidebar__fileupload"
                                />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Фамилия*</label>
                                <InputText placeholder="Укажите фамилию" value={data.surname || ""} onChange={(e) => updateData(e.target.value, "surname")}/>
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Имя*</label>
                                <InputText placeholder="Укажите имя" value={data.name || ""} onChange={(e) => updateData(e.target.value, "name")}/>
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Отчество</label>
                                <InputText placeholder="Укажите отчество" value={data.middlename || ""} onChange={(e) => updateData(e.target.value, "middlename")}/>
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Почта</label>
                                <InputText placeholder="Укажите почту" value={data.email || ""} onChange={(e) => updateData(e.target.value, "email")}/>
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Дата рождения*</label>
                                <Calendar
                                    placeholder="Выберите дату"
                                    value={data.birthday ? moment(data.birthday, "DD.MM.YYYY").toDate() : ""}
                                    onChange={(e) => updateData(moment(e.value).format("DD.MM.YYYY"), "birthday")}
                                    appendTo="self"
                                    dateFormat="dd.mm.yy"
                                    mask="99.99.9999"
                                    locale="ru"
                                    maxDate={moment().toDate()}
                                />
                            </div>

                            <div className='clubs-profile__container'>
                                <label className="clubs-profile__label">Доступ к странице только по ссылке</label>
                                <div className="clubs-profile__shareLink">
                                    <InputSwitch checked={!!data.shareLinkString} onChange={e => switchShareLink(e.value)}/>
                                    {!!data.shareLinkString && <Button label='Копировать ссылку' icon='pi pi-copy' onClick={() => navigator.clipboard.writeText(publicShareLink('profile', data._id, data.shareLinkString))}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`side-profile__accordion${openedTabs.includes("dop") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                        <div className="side-profile__accordion-header">
                            <div className="side-profile__accordion-title">Дополнительная информация</div>
                            <i className={`${openedTabs.includes("dop") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("dop")}/>
                        </div>
                        <div className="players-and-staff-sidebar__accordion-matches">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Позиция</label>
                                <Dropdown
                                    emptyMessage="Ничего не найдено"
                                    placeholder="Выберите позицию"
                                    value={data.position || ""}
                                    onChange={(e) => updateData(e.target.value, "position")}
                                    options={positionOptions}
                                    appendTo="self"
                                    optionValue="shortName"
                                    optionLabel="shortName"
                                />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Пол</label>
                                <Dropdown
                                    emptyMessage="Ничего не найдено"
                                    placeholder="Выберите пол"
                                    value={data.woman}
                                    onChange={(e) => updateData(e.target.value, "woman")}
                                    options={genderOptions}
                                    appendTo="self"
                                />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Гражданство</label>
                                <Dropdown
                                    emptyMessage="Ничего не найдено"
                                    placeholder="Выберите гражданство"
                                    value={data.countryId}
                                    onChange={(e) => updateData(e.target.value, "countryId")}
                                    options={countriesOptions}
                                    appendTo="self"
                                    optionValue="_id"
                                    optionLabel="citizenship"
                                />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Вес</label>
                                <InputText placeholder="Укажите вес в килограмах" value={data.w || ""} onChange={(e) => updateData(e.target.value, "w")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Рост</label>
                                <InputText placeholder="Укажите рост в сантиметрах" value={data.l || ""} onChange={(e) => updateData(e.target.value, "l")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Ссылка на вк</label>
                                <InputText placeholder="Вставьте ссылку" value={data.vk || ""} onChange={(e) => updateData(e.target.value, "vk")} />
                            </div>
                        </div>
                    </div>
                    <div className={`side-profile__accordion${openedTabs.includes("docs") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                        <div className="side-profile__accordion-header">
                            <div className="side-profile__accordion-title">
                                Документы
                                <FileUpload
                                    data-pr-tooltip="К загрузке допускаются файлы форматов pdf, doc, docx, xls, xlsx, odf, ppt, pptx размером не более 10 Мб"
                                    data-pr-position="bottom"
                                    ref={fileUploadRef}
                                    mode="basic"
                                    customUpload
                                    onSelect={uploadAttachment}
                                    // onUpload={removeHandler}
                                    accept=".pdf, .doc, .docx, .xls, .xlsx, .odf, .ppt, .pptx"
                                    chooseOptions={chooseOptions}
                                    className="side-profile__fileupload"
                                    maxFileSize={1048576}
                                />
                            </div>
                            <i className={`${openedTabs.includes("docs") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("docs")} />
                        </div>
                        {data.docs && openedTabs.includes("docs") && data.docs.map((d, k) => <RenderAttachment key={k} d={d} removeAttachment={removeAttachment} />)}
                    </div>

                    {data.disqualifications?.length > 0 && (
                        <div className={`side-profile__accordion${openedTabs.includes("disq") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Дисквалификации</div>
                                <i className={`${openedTabs.includes("disq") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("disq")} />
                            </div>
                            <div className="players-and-staff-sidebar__accordion-matches">
                                {data.disqualifications.map((disq) => (
                                    <div
                                        key={disq._id}
                                        className={`
                                            players-and-staff-sidebar__card
                                            players-and-staff-sidebar__card_squad
                                        `}
                                    >
                                        <div className="players-and-staff-sidebar__card-top">
                                            <div className="players-and-staff-sidebar__card-block">
                                                {disq.globalDisqTill ? "Глобальная" : disq?.count ? "Матчевая" : "Условная"}
                                                <span>
                                                    {!disq?.finishDate
                                                        ? disq.count
                                                            ? `на ${disq.count} ${pluralForm(disq.count, ["матч", "матча", "матчей"])}`
                                                            : "не указано"
                                                        : "до " + moment(disq?.finishDate, "YYYY-MM-DD").format("DD.MM.YYYY")}{" "}
                                                    {!!disq.count &&
                                                        `• остался ${
                                                            (disq.missedMatches ? disq.count - disq.missedMatches.length : disq.count) +
                                                            " матч" +
                                                            pluralForm(disq.missedMatches ? disq.count - disq.missedMatches.length : disq.count, ["", "а", "ей"])
                                                        }`}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="players-and-staff-sidebar__card-subtext">
                                            <span>Команда</span>
                                            {disq.team?.name}
                                        </div>
                                        <div className="players-and-staff-sidebar__card-subtext">
                                            <span>Турнир</span>
                                            {disq.tournament?.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {data.squads?.active?.length > 0 && (
                        <div className={`side-profile__accordion${openedTabs.includes("active") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Активные заявки</div>
                                <i className={`${openedTabs.includes("active") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("active")} />
                            </div>
                            <div className="players-and-staff-sidebar__accordion-matches">
                                {data.squads.active.map((squad) => (
                                    <div
                                        key={squad._id}
                                        className={`
                                            players-and-staff-sidebar__card
                                            players-and-staff-sidebar__card_squad
                                        `}
                                    >
                                        <div className="players-and-staff-sidebar__card-top">
                                            <img src={squad.team?.club?.emblem || noTeam} />
                                            <div className="players-and-staff-sidebar__card-block">
                                                {squad.team?.name}
                                                <span>{squad.tournament?.name}</span>
                                            </div>
                                        </div>
                                        <div className="players-and-staff-sidebar__card-subtext">
                                            <span>Позиция</span>
                                            {squad.squadState?.position}
                                        </div>
                                        <div className="players-and-staff-sidebar__card-subtext">
                                            <span>Номер</span>
                                            {squad.squadState?.number}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {data.squads?.finished?.length > 0 && (
                        <div className={`side-profile__accordion${openedTabs.includes("finished") ? " players-and-staff-sidebar__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Архивные заявки</div>
                                <i className={`${openedTabs.includes("finished") ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("finished")} />
                            </div>
                            <div className="players-and-staff-sidebar__accordion-matches">
                                {data.squads.finished.map((squad) => (
                                    <div
                                        key={squad._id}
                                        className={`
                                            players-and-staff-sidebar__card
                                            players-and-staff-sidebar__card_squad
                                        `}
                                    >
                                        <div className="players-and-staff-sidebar__card-top">
                                            <img src={squad.team?.club?.emblem || noTeam} />
                                            <div className="players-and-staff-sidebar__card-block">
                                                {squad.team?.name}
                                                <span>{squad.tournament?.name}</span>
                                            </div>
                                        </div>
                                        <div className="players-and-staff-sidebar__card-subtext">
                                            <span>Позиция</span>
                                            {squad.squadState?.position}
                                        </div>
                                        <div className="players-and-staff-sidebar__card-subtext">
                                            <span>Номер</span>
                                            {squad.squadState?.number}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const RenderAttachment = ({ d, removeAttachment }) => {
    const op = useRef(null);

    return (
        <div className="side-profile__accordion-doc">
            <OverlayPanel ref={op} className="side-profile__overlay-panel" appendTo={"self"}>
                <div
                    className="side-profile__overlay-panel-item"
                    onClick={(e) => {
                        window.open(d.fullpath);
                        op.current.toggle(e);
                    }}
                >
                    Скачать
                </div>
                <div
                    className="side-profile__overlay-panel-item side-profile__overlay-panel-item_red"
                    onClick={(e) => {
                        removeAttachment(d._id);
                        op.current.toggle(e);
                    }}
                >
                    Удалить
                </div>
            </OverlayPanel>
            <div className="side-profile__accordion-doc-info">
                <i className={`${d.status === "valid" ? "pi pi-check" : "pi pi-question"}`} />
                <div className="side-profile__accordion-doc-text">
                    {d.date}
                    <span>{d.filename}</span>
                </div>
            </div>
            <i className="pi pi-ellipsis-v" onClick={(e) => op.current.toggle(e)} />
        </div>
    );
};

const genderOptions = [
    { label: "Мужской", value: false },
    { label: "Женский", value: true },
    { label: "Инопришиленец", value: "0_0" },
    { label: "Ламинат", value: "0_-" },
];

const chooseOptions = { label: " ", icon: "pi pi-plus-circle" };

const events = [
    'Выберите карточки игроков для объединения',
    'Выберите «Эталонную» карточку, которая останется после объединения. В качестве «Эталонной» рекомендуем выбирать карточку, в которой больше данных',
    'Объедините. Оставшиеся карточки вы можете отметить как «Это не дубли, больше не предлагать»'
];

export default Player;
